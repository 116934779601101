import React, { useState } from 'react'
import { CiEdit, CiTrash } from 'react-icons/ci'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context';
import { useEffect } from 'react';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function ViewOfTaxes() {
    const params = useParams()
    const { getKoibvar, koibVar, updateSigleTaxData, storeSingleTax, getAgencyAccountData, agencyAccountData, company, getCompnayCrud, getSingleTaxData, singleTaxData, deleteSigleTaxData, storeCustomTax, getCustomTaxData, customTaxData, UpdateCustomTaxData, deleteCustomTaxData, getGroupTaxData, groupTaxDataa, storeGroupTax, handleClick, setSnackbarValue } = useData()
    useEffect(() => {
        getSingleTaxData()
        getCompnayCrud()
        getAgencyAccountData()
        getCustomTaxData()
        getGroupTaxData()
        getKoibvar()
    }, [])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            name: '',
            description: '',
            company_id: '',
            agency_id: '',
            tax_type: [
                {
                    name: 'sales',
                    rate: '',
                    tax_amount: '',
                    net_amount: '',
                    account_type_id: ''
                },
                {
                    name: 'purchase',
                    rate: '',
                    tax_amount: '',
                    net_amount: '',
                    account_type_id: ''
                }
            ]
        })
        setOpen(false)
    };
    const [openS, setOpenS] = React.useState(false);
    const handleOpenS = () => setOpenS(true);
    const handleCloseS = () => {
        setCustomData(
            {
                name: '',
                frequency: '',
                reporting_method: '',
                period: '',
                company_id: '',
                buisnessId: '',
                description: '',
                tax_name: '',
                tax_type: [
                    {
                        name: 'sales',
                        rate: '',
                    },
                    {
                        name: 'purchase',
                        rate: '',
                    }
                ]
            }
        )
        setOpenS(false)
    };
    const [openG, setOpenG] = React.useState(false);
    const handleOpenG = () => setOpenG(true);
    const handleCloseG = () => {
        setGroupTaxData(
            {
                name: '',
                description: '',
                company_id: '',
                tax_type: [
                    {
                        amount_type: '',
                        tax_type_id: ''
                    }
                ]
            }
        )
        setOpenG(false)
    };

    const [obj, setObj] = useState({
        name: '',
        description: '',
        company_id: '',
        agency_id: '',
        tax_type: [
            {
                name: 'sales',
                rate: '',
                tax_amount: '',
                net_amount: '',
                account_type_id: ''
            },
            {
                name: 'purchase',
                rate: '',
                tax_amount: '',
                net_amount: '',
                account_type_id: ''
            }
        ]
    })
    const isFormValidSingle = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmitSingle = () => {
        if (isFormValidSingle()) {
            storeSingleTax(obj)
            setObj({
                name: '',
                description: '',
                company_id: '',
                agency_id: '',
                tax_type: [
                    {
                        name: 'sales',
                        rate: '',
                        tax_amount: '',
                        net_amount: '',
                        account_type_id: ''
                    },
                    {
                        name: 'purchase',
                        rate: '',
                        tax_amount: '',
                        net_amount: '',
                        account_type_id: ''
                    }
                ]
            })
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }

    }
    const handleUpdateSingle = () => {
        if (isFormValidSingle()) {
            updateSigleTaxData(obj)
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [customData, setCustomData] = useState(
        {
            name: '',
            frequency: '',
            reporting_method: '',
            period: '',
            company_id: '',
            buisnessId: '',
            description: '',
            tax_name: '',
            tax_type: [
                {
                    name: 'sales',
                    rate: '',
                },
                {
                    name: 'purchase',
                    rate: '',
                }
            ]
        }
    )
    const isFormValidCustom = () => {
        for (const key in customData) {
            if (customData[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmitCustom = () => {
        if (isFormValidCustom()) {
            storeCustomTax(customData)
            handleCloseS()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdateCustom = () => {
        if (isFormValidCustom()) {
            UpdateCustomTaxData(customData)
            handleCloseS()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [groupTaxData, setGroupTaxData] = useState(
        {
            name: '',
            description: '',
            company_id: '',
            tax_type: [
                {
                    amount_type: '',
                    tax_type_id: ''
                }
            ]
        }
    )
    const isFormValid = () => {
        for (const key in groupTaxData) {
            if (groupTaxData[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmitGroup = () => {
        if (isFormValid()) {
            storeGroupTax(groupTaxData)
            handleCloseG()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const addTaxRateRow = () => {
        setGroupTaxData({
            ...groupTaxData,
            tax_type: [
                ...groupTaxData.tax_type,
                {
                    amount_type: '',
                    tax_type_id: '',
                },
            ],
        });
    };
    const removeTaxRateRow = (index) => {
        const updatedTaxType = [...groupTaxData.tax_type];
        updatedTaxType.splice(index, 1);
        setGroupTaxData({ ...groupTaxData, tax_type: updatedTaxType });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const visibleTaxData = params.id
        ? singleTaxData?.data?.filter(item => item?.company?.id == params.id) || []
        : singleTaxData?.data || [];

    const currentTaxData = visibleTaxData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(visibleTaxData?.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: '0px 5px' }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [currentPageS, setCurrentPageS] = useState(1);
    const itemsPerPageS = 10;

    const indexOfLastItemS = currentPageS * itemsPerPageS;
    const indexOfFirstItemS = indexOfLastItemS - itemsPerPageS;
    const visibleCustomTaxData = params.id
        ? customTaxData?.data?.filter(item => item?.company?.id == params.id) || []
        : customTaxData?.data || [];

    const currentCustomTaxData = visibleCustomTaxData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPagesS = Math.ceil(visibleCustomTaxData?.length / itemsPerPage);
    const handlePageChangeS = (page) => {
        setCurrentPageS(page);
    };

    const paginationControlsS = [];
    for (let i = 1; i <= totalPagesS; i++) {
        paginationControlsS.push(
            <button className='btn btn-primary p-2' style={{ margin: '0px 5px' }} key={i} onClick={() => handlePageChangeS(i)}>
                {i}
            </button>
        );
    }
    const [currentPageG, setCurrentPageG] = useState(1);
    const itemsPerPageG = 10;

    const indexOfLastItemG = currentPageG * itemsPerPageG;
    const indexOfFirstItemG = indexOfLastItemG - itemsPerPageG;

    // const visibleGroupTaxData = groupTaxDataa?.data?.slice(indexOfFirstItemG, indexOfLastItemG);

    // const totalPagesG = Math.ceil(groupTaxDataa?.data?.length / itemsPerPageG);

    const visibleGroupTaxData = params.id
        ? groupTaxDataa?.data?.filter(item => item?.company?.id == params.id) || []
        : groupTaxDataa?.data || [];

    const currentGroupTaxData = visibleGroupTaxData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPagesG = Math.ceil(visibleGroupTaxData?.length / itemsPerPage);
    const handlePageChangeG = (page) => {
        setCurrentPageG(page);
    };

    const paginationControlsG = [];
    for (let i = 1; i <= totalPagesG; i++) {
        paginationControlsG.push(
            <button
                className='btn btn-primary p-2'
                style={{ margin: '0px 5px' }}
                key={i}
                onClick={() => handlePageChangeG(i)}
            >
                {i}
            </button>
        );
    }

    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    return (
        <>
            <div className='viewOfTaxesData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"><Link to={"/admin/taxandagency"}>Taxs & Agency</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Taxs</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2'>
                        <div>
                            <button className='btn btn-primary' onClick={handleOpen} style={{ padding: ".5rem 1rem" }}>Add Tax Rate</button>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleOpenS} style={{ padding: ".5rem 1rem" }}>Custom Tax</button>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleOpenG} style={{ padding: ".5rem 1rem" }}>Group Tax</button>
                        </div>
                    </div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Add
                            </Typography>
                            <div id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <label>Company</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.company_id}
                                                onChange={(e) => setObj({ ...obj, company_id: e.target.value })}
                                            >
                                                <option hidden>Select Company</option>
                                                {company?.data?.map((item) => {
                                                    return (
                                                        <>{item?.deleteStatus == 0 &&
                                                            <option value={item.id} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Tax Name</label>
                                        <div className='inputBox'>
                                            <input type="text" value={obj?.name} onChange={(e) => setObj({ ...obj, name: e.target.value })} placeholder='Tax Name' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Description</label>
                                        <div className='inputBox'>
                                            <input type="text" value={obj?.description} onChange={(e) => setObj({ ...obj, description: e.target.value })} placeholder='Description' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Tax Agency</label>
                                        <div className='selectBox'>
                                            <select className='form-select' value={obj?.agency_id} onChange={(e) => setObj({ ...obj, agency_id: parseInt(e.target.value, 10) })}>
                                                <option value="">Select</option>
                                                {agencyAccountData?.agency?.map((item) => (
                                                    <option key={item?.id} value={item?.id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className='row my-3'>
                                    <div><h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Sales Rate</h3></div>

                                    <div className='col-6 mb-3'>
                                        <label>Rate</label>
                                        <div className='inputBox'>
                                            <input
                                                type="number"
                                                value={obj?.tax_type[0]?.rate}
                                                onChange={(e) =>
                                                    setObj({
                                                        ...obj,
                                                        tax_type: [
                                                            {
                                                                ...obj.tax_type[0],
                                                                rate: e.target.value
                                                            },
                                                            ...obj.tax_type.slice(1)
                                                        ]
                                                    })
                                                }
                                                placeholder='Sales Rate in %'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Account</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.tax_type[0]?.account_type_id}
                                                onChange={(e) => setObj({
                                                    ...obj,
                                                    tax_type: [
                                                        {
                                                            ...obj.tax_type[0],
                                                            account_type_id: e.target.value
                                                        },
                                                        ...obj.tax_type.slice(1)
                                                    ]
                                                })}>
                                                <option value="">Select</option>
                                                {agencyAccountData?.account_type?.map((item) => {
                                                    return (
                                                        <option value={item?.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Show Tax Amount on return line</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.tax_type[0]?.tax_amount}
                                                onChange={(e) => setObj({
                                                    ...obj,
                                                    tax_type: [
                                                        {
                                                            ...obj.tax_type[0],
                                                            tax_amount: e.target.value
                                                        },
                                                        ...obj.tax_type.slice(1)
                                                    ]
                                                })}
                                            >
                                                <option value="">Select </option>
                                                <option value="Tax collection on sales">Tax collection on sales</option>
                                                <option value="Adjustment to tax on sales">Adjustment to tax on sales</option>
                                                <option value="Other adjustments">Other adjustments</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Show Net Amount on return line</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.tax_type[0]?.net_amount}
                                                onChange={(e) => setObj({
                                                    ...obj,
                                                    tax_type: [
                                                        {
                                                            ...obj.tax_type[0],
                                                            net_amount: e.target.value
                                                        },
                                                        ...obj.tax_type.slice(1)
                                                    ]
                                                })}
                                            >
                                                <option value="">Select </option>
                                                <option value="Total taxable sales in period, before tax">Total taxable sales in period, before tax</option>
                                                <option value="Not applicable (N/A)">Not applicable (N/A)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row my-3'>
                                    <div><h3 style={{ fontWeight: "bold", fontSize: "18px" }}>Purchase Rate</h3></div>
                                    <div className='col-6 mb-3'>
                                        <label>Rate</label>
                                        <div className='inputBox'>
                                            <input
                                                type="number"
                                                value={obj?.tax_type[1]?.rate}
                                                onChange={(e) =>
                                                    setObj({
                                                        ...obj,
                                                        tax_type: [
                                                            ...obj.tax_type.slice(0, 1),
                                                            {
                                                                ...obj.tax_type[1],
                                                                rate: e.target.value
                                                            }
                                                        ]
                                                    })
                                                }
                                                placeholder='Purchase Rate in %'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Account</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.tax_type[1]?.account_type_id}
                                                onChange={(e) =>
                                                    setObj({
                                                        ...obj,
                                                        tax_type: [
                                                            ...obj.tax_type.slice(0, 1),
                                                            {
                                                                ...obj.tax_type[1],
                                                                account_type_id: e.target.value
                                                            }
                                                        ]
                                                    })
                                                }>
                                                <option value="">Select</option>
                                                {agencyAccountData?.account_type?.map((item) => {
                                                    return (
                                                        <option value={item?.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Show Tax Amount on return line</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.tax_type[1]?.tax_amount}
                                                onChange={(e) =>
                                                    setObj({
                                                        ...obj,
                                                        tax_type: [
                                                            ...obj.tax_type.slice(0, 1),
                                                            {
                                                                ...obj.tax_type[1],
                                                                tax_amount: e.target.value
                                                            }
                                                        ]
                                                    })
                                                }
                                            >
                                                <option value="">Select </option>
                                                <option value="Tax reclaimable on purchase">Tax reclaimable on purchase</option>
                                                <option value="Adjustment Tax reclaimable on purchase">Adjustment Tax reclaimable on purchase</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Show Net Amount on return line</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={obj?.tax_type[1]?.net_amount}
                                                onChange={(e) =>
                                                    setObj({
                                                        ...obj,
                                                        tax_type: [
                                                            ...obj.tax_type.slice(0, 1),
                                                            {
                                                                ...obj.tax_type[1],
                                                                net_amount: e.target.value
                                                            }
                                                        ]
                                                    })
                                                }
                                            >
                                                <option value="">Select </option>
                                                <option value="Total taxable purchase in period, before tax">Total taxable purchase in period, before tax</option>
                                                <option value="Not applicable (N/A)">Not applicable (N/A)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                        <button className='btn btn-primary'
                                            onClick={() => {
                                                {
                                                    obj?.id ? handleUpdateSingle() : handleSubmitSingle()
                                                }
                                            }}>
                                            {obj?.id ? 'Update' : 'Submit'}
                                        </button>
                                        <button className='btn btn-secondary' onClick={handleClose}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <Modal
                        open={openS}
                        onClose={handleCloseS}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Add Custom Tax
                            </Typography>
                            <div id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <label>Company</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={customData?.company_id}
                                                onChange={(e) => setCustomData({ ...customData, company_id: e.target.value })}
                                            >
                                                <option hidden>Select Company</option>
                                                {company?.data?.map((item) => {
                                                    return (
                                                        <>{item?.deleteStatus == 0 &&
                                                            <option value={item.id} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Tax Name</label>
                                        <div className='inputBox'>
                                            <input value={customData?.tax_name} onChange={(e) => setCustomData({ ...customData, tax_name: e.target.value })} type="text" placeholder='Tax Name' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Description</label>
                                        <div className='inputBox'>
                                            <input type="text" value={customData?.description} onChange={(e) => setCustomData({ ...customData, description: e.target.value })} placeholder='Description' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Bussiness ID No.</label>
                                        <div className='inputBox'>
                                            <input type="number" value={customData?.buisnessId} onChange={(e) => setCustomData({ ...customData, buisnessId: e.target.value })} placeholder='Bussiness ID No.' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Tax Agency Name</label>
                                        <div className='inputBox'>
                                            <input type="text" value={customData?.name} onChange={(e) => setCustomData({ ...customData, name: e.target.value })} placeholder='Tax Agency Name' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Tax Start Period</label>
                                        <div className='selectBox'>
                                            <select className='form-select' value={customData?.period} onChange={(e) => setCustomData({ ...customData, period: e.target.value })}>
                                                <option value="">Select </option>
                                                <option value="January">January</option>
                                                <option value="Febuary">Febuary</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="Octuber">Octuber</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Tax Filling Frequncy</label>
                                        <div className='selectBox' >
                                            <select className='form-select' value={customData?.frequency} onChange={(e) => setCustomData({ ...customData, frequency: e.target.value })}>
                                                <option value="">Select </option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Yearly">Yearly</option>
                                                <option value="Half-Yearly">Half-Yearly</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Reporting Method</label>
                                        <div className='selectBox'>
                                            <select className='form-select' value={customData?.reporting_method} onChange={(e) => setCustomData({ ...customData, reporting_method: e.target.value })}>
                                                <option value="">Select </option>
                                                <option value="Accural">Accural</option>
                                                <option value="Cash">Cash</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Sales Rate</label>
                                        <div className='inputBox'>
                                            <input type="number"
                                                value={customData?.tax_type[0]?.rate}
                                                onChange={(e) => setCustomData({
                                                    ...customData,
                                                    tax_type: [
                                                        {
                                                            ...customData.tax_type[0],
                                                            rate: e.target.value
                                                        },
                                                        ...customData.tax_type.slice(1)
                                                    ]
                                                })}
                                                placeholder='Sales rate %' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Purchase Rate</label>
                                        <div className='inputBox'>
                                            <input
                                                type="number"
                                                value={customData?.tax_type[1]?.rate}
                                                onChange={(e) =>
                                                    setCustomData({
                                                        ...customData,
                                                        tax_type: [
                                                            ...customData.tax_type.slice(0, 1),
                                                            {
                                                                ...customData.tax_type[1],
                                                                rate: e.target.value
                                                            }
                                                        ]
                                                    })
                                                }
                                                placeholder='Purchase Rate in %'
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-6 mb-3'>
                                        <label>Purchase tax is reclaimable</label> <br />
                                        <input
                                            value={customData?.tax_type[0]?.purchaseClaim}
                                            onChange={(e) => setCustomData({
                                                ...customData,
                                                tax_type: [
                                                    {
                                                        ...customData.tax_type[0],
                                                        purchaseClaim: e.target.checked
                                                    },
                                                    ...customData.tax_type.slice(1)
                                                ]
                                            })}
                                            type="checkbox" style={{ height: "20px", width: "20px", marginTop: "10px" }} />
                                    </div> */}
                                </div>
                                <div className='row mt-4'>
                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                        <button className='btn btn-primary' onClick={() => {
                                            customData?.id ? handleUpdateCustom() : handleSubmitCustom()
                                        }}>{customData?.id ? 'Update' : 'Submit'}</button>
                                        <button className='btn btn-secondary' onClick={handleCloseS}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <Modal
                        open={openG}
                        onClose={handleCloseG}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Add
                            </Typography>
                            <div id="modal-modal-description" sx={{ mt: 2 }}>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <label>Company</label>
                                        <div className='selectBox'>
                                            <select className='form-select'
                                                value={groupTaxData?.company_id}
                                                onChange={(e) => setGroupTaxData({ ...groupTaxData, company_id: e.target.value })}
                                            >
                                                <option hidden>Select Company</option>
                                                {company?.data?.map((item) => {
                                                    return (
                                                        <>{item?.deleteStatus == 0 &&
                                                            <option value={item.id} key={item.name}>
                                                                {item.name}
                                                            </option>
                                                        }
                                                        </>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Group Name</label>
                                        <div className='inputBox'>
                                            <input value={groupTaxData?.name} onChange={(e) => setGroupTaxData({ ...groupTaxData, name: e.target.value })} type="text" placeholder='Group Name' />
                                        </div>
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <label>Description</label>
                                        <div className='inputBox'>
                                            <input value={groupTaxData?.description} onChange={(e) => setGroupTaxData({ ...groupTaxData, description: e.target.value })} type="text" placeholder='Description' />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {groupTaxData?.tax_type.map((taxRate, index) => (
                                        <div className='row' key={index}>
                                            <div className='col-6 mb-3'>
                                                <label>Tax rate</label>
                                                <div className='selectBox'>
                                                    <select
                                                        className='form-select'
                                                        value={taxRate.tax_type_id}
                                                        onChange={(e) => {
                                                            const updatedTaxType = [...groupTaxData.tax_type];
                                                            updatedTaxType[index].tax_type_id = e.target.value;
                                                            setGroupTaxData({ ...groupTaxData, tax_type: updatedTaxType });
                                                        }}
                                                    >
                                                        <option value="">Select </option>
                                                        {koibVar?.data?.map((item) => (
                                                            <option value={item.id} key={item.name}>
                                                                {item?.account_tax?.name} ({item.name} ({item.rate}%))
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='col-6 mb-3'>
                                                <label>Applicable on </label>
                                                <div className='selectBox'>
                                                    <select
                                                        className='form-select'
                                                        value={taxRate.amount_type}
                                                        onChange={(e) => {
                                                            const updatedTaxType = [...groupTaxData.tax_type];
                                                            updatedTaxType[index].amount_type = e.target.value;
                                                            setGroupTaxData({ ...groupTaxData, tax_type: updatedTaxType });
                                                        }}
                                                    >
                                                        <option value="">Select </option>
                                                        <option value="Net Amount">Net Amount</option>
                                                        <option value="Tax Amount">Tax Amount</option>
                                                        <option value="Net + Tax Amount">Net + Tax Amount</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {
                                                groupTaxData.tax_type.length > 1 && (
                                                    <button className='btn btn-danger p-2 m-2' style={{ width: "fit-content" }} onClick={() => removeTaxRateRow(index)}>
                                                        -
                                                    </button>
                                                )
                                            }
                                        </div>
                                    ))}
                                </div>
                                <button className='btn btn-primary' onClick={addTaxRateRow}>
                                    Add more
                                </button>
                                <div className='row mt-4'>
                                    <div className='modalBtns d-flex justify-content-end gap-2'>
                                        <button className='btn btn-primary' onClick={() => {
                                            handleSubmitGroup()
                                        }}>Submit</button>
                                        <button className='btn btn-secondary' onClick={handleCloseG}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal >
                </div >
                <hr />
                <div className='row'>
                    <ul className="nav nav-tabs nav-fill mb-3" id="ex1" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="nav-link active" id="ex2-tab-1" data-mdb-toggle="tab" href="#ex2-tabs-1" role="tab" aria-controls="ex2-tabs-1" aria-selected="true" style={{ fontSize: "15px", fontWeight: "bold" }}>Tax Rate</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex2-tab-2" data-mdb-toggle="tab" href="#ex2-tabs-2" role="tab" aria-controls="ex2-tabs-2" aria-selected="false" style={{ fontSize: "15px", fontWeight: "bold" }}>Custom Tax</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex2-tab-3" data-mdb-toggle="tab" href="#ex2-tabs-3" role="tab" aria-controls="ex2-tabs-3" aria-selected="false" style={{ fontSize: "15px", fontWeight: "bold" }}>Group Tax</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="ex2-content">
                        <div className="tab-pane fade show active" id="ex2-tabs-1" role="tabpanel" aria-labelledby="ex2-tab-1">
                            <div className='card'>
                                <div className='card-body table-responsive recruitment'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Tax Name</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Agency</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Sales Tax (%)</th>
                                                <th scope="col">Purchase Tax (%)</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {params?.id ? currentTaxData?.map((item, idx) => {
                                                const truncatedDescription = item.description?.slice(0, 20);
                                                const isDescriptionTruncated = item.description?.length > 20;

                                                return (
                                                    <>
                                                        {item?.company?.id == params?.id && <tr key={idx}>
                                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                            <td>{item?.name}</td>
                                                            <td>{item?.company?.name}</td>
                                                            <td>{item?.agency?.name}</td>
                                                            <td>
                                                                {isDescriptionTruncated ? (
                                                                    <div
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setSelectedDescription(item.description);
                                                                            setShowDescriptionOverlay(true);
                                                                        }}
                                                                    >
                                                                        {truncatedDescription}...
                                                                    </div>
                                                                ) : (
                                                                    item.description
                                                                )}
                                                            </td>
                                                            <td>{item?.tax[0]?.rate} %</td>
                                                            <td>{item?.tax[1]?.rate} %</td>
                                                            <td>
                                                                <button
                                                                    className="btn p-1 m-2 btn-primary"
                                                                    onClick={() => {
                                                                        handleOpen();
                                                                        setObj({
                                                                            id: item.id,
                                                                            name: item?.name,
                                                                            description: item?.description,
                                                                            company_id: item?.company?.id,
                                                                            agency_id: item?.agency?.id,
                                                                            tax_type: [
                                                                                {
                                                                                    name: 'sales',
                                                                                    rate: item?.tax[0]?.rate,
                                                                                    tax_amount: item?.tax[0]?.tax_amount,
                                                                                    net_amount: item?.tax[0]?.net_amount,
                                                                                    account_type_id: item?.tax[0]?.account_type_id,
                                                                                },
                                                                                {
                                                                                    name: 'purchase',
                                                                                    rate: item?.tax[1]?.rate,
                                                                                    tax_amount: item?.tax[1]?.tax_amount,
                                                                                    net_amount: item?.tax[1]?.net_amount,
                                                                                    account_type_id: item?.tax[1]?.account_type_id,
                                                                                },
                                                                            ],
                                                                        });
                                                                    }}
                                                                >
                                                                    <CiEdit style={{ fontSize: '20px' }} />
                                                                </button>
                                                                <button className="btn p-1 m-2 btn-danger" onClick={() => deleteSigleTaxData(item.id)}>
                                                                    <CiTrash style={{ fontSize: '20px' }} />
                                                                </button>
                                                            </td>
                                                        </tr>}
                                                    </>
                                                )
                                            }) : currentTaxData?.map((item, idx) => {
                                                const truncatedDescription = item.description?.slice(0, 20);
                                                const isDescriptionTruncated = item.description?.length > 20;

                                                return (
                                                    <tr key={idx}>
                                                        <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.company?.name}</td>
                                                        <td>{item?.agency?.name}</td>
                                                        <td>
                                                            {isDescriptionTruncated ? (
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSelectedDescription(item.description);
                                                                        setShowDescriptionOverlay(true);
                                                                    }}
                                                                >
                                                                    {truncatedDescription}...
                                                                </div>
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </td>
                                                        <td>{item?.tax[0]?.rate} %</td>
                                                        <td>{item?.tax[1]?.rate} %</td>
                                                        <td>
                                                            <button
                                                                className="btn p-1 m-2 btn-primary"
                                                                onClick={() => {
                                                                    handleOpen();
                                                                    setObj({
                                                                        id: item.id,
                                                                        name: item?.name,
                                                                        description: item?.description,
                                                                        company_id: item?.company?.id,
                                                                        agency_id: item?.agency?.id,
                                                                        tax_type: [
                                                                            {
                                                                                name: 'sales',
                                                                                rate: item?.tax[0]?.rate,
                                                                                tax_amount: item?.tax[0]?.tax_amount,
                                                                                net_amount: item?.tax[0]?.net_amount,
                                                                                account_type_id: item?.tax[0]?.account_type_id,
                                                                            },
                                                                            {
                                                                                name: 'purchase',
                                                                                rate: item?.tax[1]?.rate,
                                                                                tax_amount: item?.tax[1]?.tax_amount,
                                                                                net_amount: item?.tax[1]?.net_amount,
                                                                                account_type_id: item?.tax[1]?.account_type_id,
                                                                            },
                                                                        ],
                                                                    });
                                                                }}
                                                            >
                                                                <CiEdit style={{ fontSize: '20px' }} />
                                                            </button>
                                                            <button className="btn p-1 m-2 btn-danger" onClick={() => deleteSigleTaxData(item.id)}>
                                                                <CiTrash style={{ fontSize: '20px' }} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        {currentTaxData?.length === 0 && (
                                            <tr>
                                                <td colSpan="8" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </table>
                                    <div className="pagination-controls">{paginationControls}</div>
                                </div>
                                {showDescriptionOverlay && (
                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
                            <div className='card'>
                                <div className='card-body table-responsive recruitment'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Tax Name</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Agency</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Filling Frequncy</th>
                                                <th scope="col">Start Period</th>
                                                <th scope="col">Reporting Method</th>
                                                <th scope="col">Bussiness ID</th>
                                                <th scope="col">Sales Tax (%)</th>
                                                <th scope="col">Purchase Tax (%)</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {params?.id ? currentCustomTaxData?.map((item, idx) => {
                                                const truncatedDescription = item.description.slice(0, 20);
                                                const isDescriptionTruncated = item.description.length > 20;
                                                return (
                                                    <>
                                                        {item?.company?.id == params?.id &&
                                                            <tr key={idx}>
                                                                <th scope="row">{indexOfFirstItemS + idx + 1}</th>
                                                                <td>{item?.name}</td>
                                                                <td>{item?.company?.name}</td>
                                                                <td>{item?.agency?.name}</td>
                                                                <td>
                                                                    {isDescriptionTruncated ? (
                                                                        <div
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                setSelectedDescription(item.description);
                                                                                setShowDescriptionOverlay(true);
                                                                            }}
                                                                        >
                                                                            {truncatedDescription}...
                                                                        </div>
                                                                    ) : (
                                                                        item.description
                                                                    )}
                                                                </td>
                                                                <td>{item?.agency?.frequency}</td>
                                                                <td>{item?.agency?.period}</td>
                                                                <td>{item?.agency?.reporting_method}</td>
                                                                <td>{item?.agency?.buisnessId ? item?.agency?.buisnessId : '-'}</td>
                                                                <td>{item?.custom_type[0]?.rate} %</td>
                                                                <td>{item?.custom_type[1]?.rate} %</td>
                                                                <td>
                                                                    <button
                                                                        className="btn p-1 m-2 btn-primary"
                                                                        onClick={() => {
                                                                            handleOpenS();
                                                                            setCustomData({
                                                                                id: item.id,
                                                                                name: item?.agency?.name,
                                                                                frequency: item?.agency?.frequency,
                                                                                reporting_method: item?.agency?.reporting_method,
                                                                                period: item?.agency?.period,
                                                                                company_id: item?.company?.id,
                                                                                buisnessId: item?.agency?.buisnessId,
                                                                                description: item?.description,
                                                                                tax_name: item?.name,
                                                                                tax_type: [
                                                                                    {
                                                                                        name: 'sales',
                                                                                        rate: item?.custom_type[0]?.rate,
                                                                                    },
                                                                                    {
                                                                                        name: 'purchase',
                                                                                        rate: item?.custom_type[1]?.rate,
                                                                                    },
                                                                                ],
                                                                            });
                                                                        }}
                                                                    >
                                                                        <CiEdit style={{ fontSize: '20px' }} />
                                                                    </button>
                                                                    <button className="btn p-1 m-2 btn-danger" onClick={() => deleteCustomTaxData(item.id)}>
                                                                        <CiTrash style={{ fontSize: '20px' }} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </>

                                                );
                                            }) : currentCustomTaxData?.map((item, idx) => {
                                                const truncatedDescription = item.description.slice(0, 20);
                                                const isDescriptionTruncated = item.description.length > 20;
                                                return (
                                                    <tr key={idx}>
                                                        <th scope="row">{indexOfFirstItemS + idx + 1}</th>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.company?.name}</td>
                                                        <td>{item?.agency?.name}</td>
                                                        <td>
                                                            {isDescriptionTruncated ? (
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSelectedDescription(item.description);
                                                                        setShowDescriptionOverlay(true);
                                                                    }}
                                                                >
                                                                    {truncatedDescription}...
                                                                </div>
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </td>
                                                        <td>{item?.agency?.frequency}</td>
                                                        <td>{item?.agency?.period}</td>
                                                        <td>{item?.agency?.reporting_method}</td>
                                                        <td>{item?.agency?.buisnessId ? item?.agency?.buisnessId : '-'}</td>
                                                        <td>{item?.custom_type[0]?.rate} %</td>
                                                        <td>{item?.custom_type[1]?.rate} %</td>
                                                        <td>
                                                            <button
                                                                className="btn p-1 m-2 btn-primary"
                                                                onClick={() => {
                                                                    handleOpenS();
                                                                    setCustomData({
                                                                        id: item.id,
                                                                        name: item?.agency?.name,
                                                                        frequency: item?.agency?.frequency,
                                                                        reporting_method: item?.agency?.reporting_method,
                                                                        period: item?.agency?.period,
                                                                        company_id: item?.company?.id,
                                                                        buisnessId: item?.agency?.buisnessId,
                                                                        description: item?.description,
                                                                        tax_name: item?.name,
                                                                        tax_type: [
                                                                            {
                                                                                name: 'sales',
                                                                                rate: item?.custom_type[0]?.rate,
                                                                            },
                                                                            {
                                                                                name: 'purchase',
                                                                                rate: item?.custom_type[1]?.rate,
                                                                            },
                                                                        ],
                                                                    });
                                                                }}
                                                            >
                                                                <CiEdit style={{ fontSize: '20px' }} />
                                                            </button>
                                                            <button className="btn p-1 m-2 btn-danger" onClick={() => deleteCustomTaxData(item.id)}>
                                                                <CiTrash style={{ fontSize: '20px' }} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>

                                        {currentCustomTaxData?.length === 0 && (
                                            <tr>
                                                <td colSpan="12" className="text-center">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}
                                    </table>
                                    <div className="pagination-controls">{paginationControlsS}</div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex2-tabs-3" role="tabpanel" aria-labelledby="ex2-tab-3">
                            <div className='card'>
                                <div className='card-body table-responsive recruitment'>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {params?.id ? currentGroupTaxData?.map((item, i) => {
                                                    const truncatedDescription = item.description.slice(0, 20);
                                                    const isDescriptionTruncated = item.description.length > 20;
                                                    return (
                                                        <React.Fragment key={i}>
                                                            {item?.company?.id == params?.id && (
                                                                <>
                                                                    <tr
                                                                        className="accordion-toggle collapsed"
                                                                        id={`accordion${i}`}
                                                                        data-mdb-toggle="collapse"
                                                                        data-mdb-parent={`#accordion${i}`}
                                                                        href={`#collapse${i}`}
                                                                        aria-controls={`collapse${i}`}
                                                                    >
                                                                        <td className="expand-button">{indexOfFirstItemG + i + 1}</td>
                                                                        <td>{item?.name}</td>
                                                                        <td>{item?.company?.name}</td>
                                                                        <td>
                                                                            {isDescriptionTruncated ? (
                                                                                <div
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        setSelectedDescription(item.description);
                                                                                        setShowDescriptionOverlay(true);
                                                                                    }}
                                                                                >
                                                                                    {truncatedDescription}...
                                                                                </div>
                                                                            ) : (
                                                                                item.description
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr className="hide-table-padding">
                                                                        <td colSpan={4}>
                                                                            <div id={`collapse${i}`} className="collapse in p-3">
                                                                                {item?.group_tax?.map((tax, index) => (
                                                                                    <div className="row" key={index}>
                                                                                        <div className="col-2">{tax?.tax_type?.name}</div>
                                                                                        <div className="col-6">{tax?.tax_type?.rate} %</div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                }) : currentGroupTaxData?.map((item, i) => {
                                                    const truncatedDescription = item.description.slice(0, 20);
                                                    const isDescriptionTruncated = item.description.length > 20;
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <tr
                                                                className="accordion-toggle collapsed"
                                                                id={`accordion${i}`}
                                                                data-mdb-toggle="collapse"
                                                                data-mdb-parent={`#accordion${i}`}
                                                                href={`#collapse${i}`}
                                                                aria-controls={`collapse${i}`}
                                                            >
                                                                <td className="expand-button">{indexOfFirstItemG + i + 1}</td>
                                                                <td>{item?.name}</td>
                                                                <td>{item?.company?.name}</td>
                                                                <td>
                                                                    {isDescriptionTruncated ? (
                                                                        <div
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                setSelectedDescription(item.description);
                                                                                setShowDescriptionOverlay(true);
                                                                            }}
                                                                        >
                                                                            {truncatedDescription}...
                                                                        </div>
                                                                    ) : (
                                                                        item.description
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr className="hide-table-padding">
                                                                <td colSpan={4}>
                                                                    <div id={`collapse${i}`} className="collapse in p-3">
                                                                        {item?.group_tax?.map((tax, index) => (
                                                                            <div className="row" key={index}>
                                                                                <div className="col-2">{tax?.tax_type?.name}</div>
                                                                                <div className="col-6">{tax?.tax_type?.rate} %</div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </tbody>
                                            {currentGroupTaxData?.length === 0 && (
                                                <tr>
                                                    <td colSpan="4" className="text-center">
                                                        No data available
                                                    </td>
                                                </tr>
                                            )}
                                        </table>
                                        <div className="pagination-controls">{paginationControlsG}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ViewOfTaxes
