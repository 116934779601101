import React, { useEffect, useState } from 'react'
import { BiCategory, BiMessageDetail } from 'react-icons/bi'
import { TbCategory2 } from 'react-icons/tb'
import { MdPayment } from 'react-icons/md'
import { RiSecurePaymentLine, RiAccountPinCircleLine } from 'react-icons/ri'
import { BsCashCoin, BsFillImageFill } from 'react-icons/bs'
import { VscReferences } from 'react-icons/vsc'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useData } from '../../../ContextProvider/Context'
import { Link, useParams } from 'react-router-dom'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


function Expance() {
    const params = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const { accountsData, getAccountsData, handleClick, setSnackbarValue, getExpnaceCateSubcate, expnaceCateSubcate, getExpance, expancer, img_url, storeExpanse, acouCategory, getAcouCategory, getExpanceDetails, expandeDetails, getFilterExpanse, base_url } = useData()
    useEffect(() => {
        getExpance()
        getAcouCategory()
        getExpanceDetails()
        getExpnaceCateSubcate()
        getAccountsData()
    }, [])
    const [selectedCompany, setSelectedCompany] = useState(params.id || null)
    const [selectedCate, setSelectedCate] = useState(null);
    const [selectedAssetSubCate, setSelectedAssetSubCate] = useState(null);
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const filteredCate = accountsData?.category?.filter(item => item.company_id === (params.id ? params.id : selectedCompany));
    const filteredSubCate = accountsData?.subcategory?.filter(item => item.category_id === selectedCate);
    const filteredAccounts = accountsData?.account?.filter(item => item.company_id === (params.id ? params.id : selectedCompany));
    const filteredSupplier = accountsData?.supplier?.filter(item => item.company_id === (params.id ? params.id : selectedCompany));


    const [obj, setObj] = useState(
        {
            company_id: params.id ? params.id : selectedCompany,
            category_id: "",
            subcategory_id: "",
            account_id: "",
            supplier_id: "",
            date: new Date().toISOString().split('T')[0],
            amount: "",
            description: "",
            ref_no: "",
            pay_method: "",
            invoice: ""
        }
    )
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        clearFields()
        setOpen(false)
    };
    const [selectedImage, setSelectedImage] = useState(null);
    const [showImageOverlay, setShowImageOverlay] = useState(false);
    const [selectedImageURL, setSelectedImageURL] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null)

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64ImageData = parts[1];
                setObj({
                    ...obj,
                    invoice: base64ImageData,
                });
                const imgBox = document.getElementById('image-box');
                imgBox.src = base64URL;
            };
            reader.readAsDataURL(file);
        }
    };
    const clearFields = () => {
        setObj({
            company_id: params.id ? params.id : selectedCompany,
            category_id: '',
            subcategory_id: '',
            account_id: '',
            supplier_id: '',
            date: new Date().toISOString().split('T')[0],
            amount: '',
            description: '',
            ref_no: '',
            pay_method: '',
            invoice: '',
        });

        setSelectedImage(null);
        const imgBox = document.getElementById('image-box');
        imgBox.src = '';
    };

    const isFormValid = () => {
        for (const key in obj) {
            if (obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeExpanse(obj);
            clearFields();
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [date, setDate] = useState({
        category: '',
        subcategory: '',
        startDate: '',
        endDate: '',
        company: ''
    });

    const handleCategoryChange = (e) => {
        const newCategory = e.target.value;
        setDate({ ...date, category: newCategory });
        filterResults(newCategory, date.subcategory, date.company, date.startDate, date.endDate);
    };

    const handleSubcategoryChange = (e) => {
        const newSubcategory = e.target.value;
        setDate({ ...date, subcategory: newSubcategory });
        filterResults(date.category, newSubcategory, date.company, date.startDate, date.endDate);
    };

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setDate({ ...date, startDate: newStartDate });
        filterResults(date.category, date.subcategory, date.company, newStartDate, date.endDate);
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setDate({ ...date, endDate: newEndDate });
        filterResults(date.category, date.subcategory, date.startDate, date.company, newEndDate);
    };
    const handleCompanyChange = (e) => {
        const newCompanyChange = e.target.value;
        setDate({ ...date, company: newCompanyChange });
        filterResults(date.category, date.subcategory, date.startDate, date.endDate, newCompanyChange);
    };


    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(",")[1];
                csvToJSON(parts)

            };
            reader.readAsDataURL(file);
        }
    };
    const csvToJSON = (csvData) => {
        fetch(`${base_url}importexpense`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',

            },
            // body:jsonOutput,
            body: JSON.stringify({ file: csvData }),
        })
            .then((response) => response.json())
            .then((data) => {
                getExpance()
                handleClick();
                setSnackbarValue({ message: "File Add Succssfully", color: "#2ECC71" });
            })
            .catch((error) => {
                // Handle any errors
                console.log('Error:', error)
            });

    };

    const downloadDemo = () => {
        const dataDemo = [{
            company_id: "MJ technology",
            category_id: 'Expense',
            subcategory_id: 'Other Expenses',
            account_id: '841234567',
            supplier_id: 'Zohaib Hassan',
            date: '2023-11-04',
            amount: 500,
            description: 'Description',
            ref_no: 'must be Unique',
            pay_method: 'Credit Card',
        }]

        const csv = Papa.unparse(dataDemo);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'expense.csv');
        handleClose1()
    }
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleExpense = params.id
        ? expancer?.expenses?.filter(item => item?.company?.id == params.id) || []
        : expancer?.expenses || [];

    const currentExpense = visibleExpense?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleExpense.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const filterResults = (category, subcategory, startDate, endDate, company) => {
        getFilterExpanse(category, subcategory, startDate, endDate, company);
        handlePageChange(1)
    };
    return (
        <>
            <div className='accountExpenses'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <div className='inp-buttons d-flex gap-2 w-100' style={{ alignItems: "end", justifyContent: "space-between" }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Expanse</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontSize: "14px", fontWeight: "bold" }}>Company</label>
                                    <select disabled={params.id ? true : false} className='form-select' style={{ fontSize: "14px" }} value={date.company} onChange={handleCompanyChange}>
                                        <option hidden>Select Company</option>
                                        <option value=''>Company</option>
                                        {filteredCompanies?.map((items) => (
                                            <option key={items.name} value={items.name}>
                                                {items.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label style={{ fontSize: "14px", fontWeight: "bold" }}>Category</label>
                                    <select className='form-select' style={{ fontSize: "14px" }} value={date.category} onChange={handleCategoryChange}>
                                        <option hidden>Select Category</option>
                                        <option value=''>Category</option>
                                        {expandeDetails?.category?.map((items) => (
                                            <option key={items.name} value={items.name}>
                                                {items.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label style={{ fontSize: "14px", fontWeight: "bold" }}>Subcategory</label>
                                    <select className='form-select' style={{ fontSize: "14px" }} value={date.subcategory} onChange={handleSubcategoryChange}>
                                        <option hidden>Select Subcategory</option>
                                        <option value=''>Subcategory</option>
                                        {expandeDetails?.subCategory?.map((items) => (
                                            <option key={items.name} value={items.name}>
                                                {items.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='startdate'>
                                    <label style={{ fontSize: "14px", fontWeight: "bold" }}>Select Start Date:</label> <br />
                                    <input type='date' name='start' value={date.startDate} onChange={handleStartDateChange} className='form-control' />
                                </div>
                                <div className='enddate'>
                                    <label style={{ fontSize: "14px", fontWeight: "bold" }}>Select End Date:</label> <br />
                                    <input type='date' name='end' value={date.endDate} onChange={handleEndDateChange} className='form-control' />
                                </div>
                            </div>
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                aria-controls={open1 ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                                onClick={handleClick1}
                            >
                                Import Expense
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={downloadDemo}>Download Demo</MenuItem>
                                <MenuItem onClick={handleClose1}> <label for="csv_file" >Import File</label></MenuItem>

                            </Menu>
                            <input type='file' accept=".csv" onChange={handleFileChange} style={{ display: "none" }} id='csv_file' />

                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} style={{ height: "600px", overflowY: "scroll" }} className='scrollBar'>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }}>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Payment Method</label>
                                                <div className='selectBox'>
                                                    <label><MdPayment /></label>
                                                    <select className='form-select' style={{ fontSize: "14px" }} onChange={(e) => setObj({ ...obj, pay_method: e.target.value })}>
                                                        <option hidden>Select Payment Method</option>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Bank">Bank</option>
                                                        <option value="Credit Card">Credit Card</option>
                                                        <option value="Direct Debit">Direct Debit</option>
                                                    </select>
                                                </div>
                                                <label>Supplier</label>
                                                <div className='selectBox'>
                                                    <label><RiSecurePaymentLine /></label>
                                                    <select className='form-select' style={{ fontSize: "14px" }} onChange={(e) => setObj({ ...obj, supplier_id: e.target.value })}>
                                                        <option hidden>Select Supplier</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredSupplier?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                                <label>Amount</label>
                                                <div className='inputBox'>
                                                    <label><BsCashCoin /></label>
                                                    <input type='number' placeholder='Amount' onChange={(e) => setObj({ ...obj, amount: e.target.value })}></input>
                                                </div>
                                                <label>Ref No.</label>
                                                <div className='inputBox'>
                                                    <label><VscReferences /></label>
                                                    <input placeholder='Ref No.' type='text' onChange={(e) => setObj({ ...obj, ref_no: e.target.value })}></input>
                                                </div>
                                                <label>Screen Shot</label>
                                                <div className='inputBox'>
                                                    <div className='image-upload' style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                                        <label for='inp'>
                                                            <BsFillImageFill /> <span style={{ fontSize: '14px' }}>Screenshot</span>
                                                        </label>
                                                        <input
                                                            id='inp'
                                                            style={{ display: 'none' }}
                                                            type='file'
                                                            onChange={handleImageChange}
                                                        />
                                                        <img id="image-box" style={{ width: '30px', height: '30px' }} src={selectedImage ? URL.createObjectURL(selectedImage) : ''} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label>Company</label>
                                                <div className='selectBox'>
                                                    <select disabled={params.id ? true : false} value={obj?.company_id} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            company_id: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <label>Account</label>
                                                <div className='selectBox'>
                                                    <label><TbCategory2 /></label>
                                                    <select onChange={(e) => setObj({ ...obj, account_id: e.target.value })}>
                                                        <option hidden>Account</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredAccounts?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.bank_name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                                <label>Category</label>
                                                <div className='selectBox'>
                                                    <label><BiCategory /></label>
                                                    <select
                                                        className='form-select'
                                                        style={{ fontSize: "14px" }}
                                                        onChange={(e) => {
                                                            setSelectedCate(parseInt(e.target.value))
                                                            setObj({ ...obj, category_id: e.target.value })
                                                        }}
                                                    >
                                                        <option hidden>Select Category</option>
                                                        {selectedCompany && (
                                                            <>
                                                                {filteredCate?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                                <label>Subategory</label>
                                                <div className='selectBox'>
                                                    <label><TbCategory2 /></label>
                                                    <select className='form-select' style={{ fontSize: "14px" }} onChange={(e) => {
                                                        setSelectedAssetSubCate(parseInt(e.target.value))
                                                        setObj({ ...obj, subcategory_id: e.target.value })
                                                    }}>
                                                        <option hidden>Select SubCategory</option>
                                                        {selectedCate && (
                                                            <>
                                                                {filteredSubCate?.map((item) => (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                                <label>Payment Date</label>
                                                <div className='inputBox'>
                                                    <input
                                                        type='date'
                                                        placeholder='Payment Date'
                                                        value={obj?.date}
                                                        onChange={(e) => setObj({ ...obj, date: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label>Description</label>
                                            <div className='textarea'>
                                                <label><BiMessageDetail /></label>
                                                <textarea placeholder='Description' onChange={(e) => setObj({ ...obj, description: e.target.value })}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className="btn btn-primary" onClick={() => {
                                                handleSubmit()
                                            }}>
                                                Submit
                                            </button>
                                            <button className="btn btn-secondary" onClick={handleClose}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>

                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">SubCategory</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Screenshot</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentExpense?.map((items, idx) => {
                                    const dateparts = items.date?.split(' ')
                                    const truncatedDescription = items.description.slice(0, 20);
                                    const isDescriptionTruncated = items.description.length > 20;

                                    return (
                                        <>
                                            {items.company?.id == params.id && <tr key={idx}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{items.company?.name}</td>
                                                <td>{dateparts[0]}</td>
                                                <td>{items.category_id?.name ? items.category_id?.name : '-'}</td>
                                                <td>{items.subcategory_id?.name ? items.subcategory_id?.name : '-'}</td>
                                                <td>
                                                    {isDescriptionTruncated ? (
                                                        <div
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedDescription(items.description);
                                                                setShowDescriptionOverlay(true);
                                                            }}
                                                        >
                                                            {truncatedDescription}...
                                                        </div>
                                                    ) : (
                                                        items.description
                                                    )}
                                                </td>
                                                <td style={{ display: "flex", justifyContent: "center" }}>
                                                    <div style={{ width: '30px', height: '30px' }}>
                                                        <img style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                            onClick={() => {
                                                                setSelectedImageURL(img_url + items.invoice);
                                                                setShowImageOverlay(true);
                                                            }}
                                                            src={img_url + items.invoice} alt='' />
                                                    </div>
                                                </td>
                                                {showDescriptionOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                        <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                            <p style={{ color: "white" }}>{selectedDescription}</p>
                                                        </div>
                                                    </div>
                                                )}
                                                {showImageOverlay && (
                                                    <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                                                        <img
                                                            src={selectedImageURL}
                                                            alt="Full-Screen Image"
                                                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                                                        />
                                                    </div>
                                                )}

                                                <td><span>{items.amount}</span></td>
                                            </tr>}
                                        </>

                                    );
                                }) : currentExpense?.map((items, idx) => {
                                    const dateparts = items.date?.split(' ')
                                    const truncatedDescription = items.description.slice(0, 20);
                                    const isDescriptionTruncated = items.description.length > 20;

                                    return (
                                        <tr key={idx}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{items.company?.name}</td>
                                            <td>{dateparts[0]}</td>
                                            <td>{items.category_id?.name ? items.category_id?.name : '-'}</td>
                                            <td>{items.subcategory_id?.name ? items.subcategory_id?.name : '-'}</td>
                                            <td>
                                                {isDescriptionTruncated ? (
                                                    <div
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedDescription(items.description);
                                                            setShowDescriptionOverlay(true);
                                                        }}
                                                    >
                                                        {truncatedDescription}...
                                                    </div>
                                                ) : (
                                                    items.description
                                                )}
                                            </td>
                                            <td style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{ width: '30px', height: '30px' }}>
                                                    <img style={{ width: '100%', height: '100%', cursor: "pointer" }}
                                                        onClick={() => {
                                                            setSelectedImageURL(img_url + items.invoice);
                                                            setShowImageOverlay(true);
                                                        }}
                                                        src={img_url + items.invoice} alt='' />
                                                </div>
                                            </td>
                                            {showDescriptionOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                                    </div>
                                                </div>
                                            )}
                                            {showImageOverlay && (
                                                <div className="overlayy" style={{ background: "rgba(0,0,0,0.38)" }} onClick={() => setShowImageOverlay(false)}>
                                                    <img
                                                        src={selectedImageURL}
                                                        alt="Full-Screen Image"
                                                        style={{ maxHeight: '100%', maxWidth: '100%' }}
                                                    />
                                                </div>
                                            )}

                                            <td><span>{items.amount}</span></td>
                                        </tr>
                                    );
                                })}

                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Expance
