import React, { useEffect, useState } from "react";
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { CiEdit, CiTrash } from "react-icons/ci";
import { useData } from "../../ContextProvider/Context";
import filter from "../../Images/filtericon.png";
import cross from "../../Images/filtercross.png";
import vector from "../../Images/Vector.png";
import { Drawer, IconButton, Card, CardContent } from "@mui/material";
import roleicon from "../../Images/roleicon.png";
import editicon from "../../Images/editicon.png";
import deleteicon from "../../Images/deleteicon.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import editbrnch from "../../Images/edtibranch.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import downarrow from "../../Images/chevron-down.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
};
function CreateRolls() {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => {
  //   setRolls({
  //     name: "",
  //     permission: [],
  //   });
  //   setSelectedOptions([]);
  //   setOpen(false);
  // };

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const options = [
    { label: "Dashboard", value: "Dashboard" },
    { label: "Employee Profile", value: "Employee Profile" },
    { label: "Employee Create", value: "Employee Create" },
    { label: "Employee Edit", value: "Employee Edit" },
    { label: "Employee Delete", value: "Employee Delete" },
    { label: "Projects View", value: "Projects View" },
    { label: "Overview View", value: "Overview View" },
    { label: "Task View", value: "Task View" },
    { label: "Task Create", value: "Task Create" },
    { label: "Files View", value: "Files View" },
    { label: "Files Create", value: "Files Create" },
    { label: "Kanban", value: "Kanban" },
    { label: "Company Create", value: "Company Create" },
    { label: "Company View", value: "Company View" },
    { label: "Company Edit", value: "Company Edit" },
    { label: "Company Delete", value: "Company Delete" },
    { label: "Branch Create", value: "Branch Create" },
    { label: "Branch View", value: "Branch View" },
    { label: "Branch Edit", value: "Branch Edit" },
    { label: "Branch Delete", value: "Branch Delete" },
    { label: "Department Create", value: "Department Create" },
    { label: "Department View", value: "Department View" },
    { label: "Department Edit", value: "Department Edit" },
    { label: "Department Delete", value: "Department Delete" },
    { label: "Designation Create", value: "Designation Create" },
    { label: "Designation View", value: "Designation View" },
    { label: "Designation Edit", value: "Designation Edit" },
    { label: "Designation Delete", value: "Designation Delete" },
    { label: "Accounts", value: "Accounts" },
    { label: "Transfer", value: "Transfer" },
    { label: "Expense", value: "Expense" },
    { label: "Expense Category", value: "Expense Category" },
    { label: "Expense Subcategory", value: "Expense Subcategory" },
    { label: "Assets", value: "Assets" },
    { label: "Asset Category", value: "Asset Category" },
    { label: "Purchase Assets", value: "Purchase Assets" },
    { label: "Services", value: "Services" },
    { label: "Services Category", value: "Services Category" },
    { label: "Invoice", value: "Invoice" },
    { label: "Revenue", value: "Revenue" },
    { label: "General Ledger", value: "General Ledger" },
    { label: "Trail Balance", value: "Trail Balance" },
    { label: "Balance Sheet", value: "Balance Sheet" },
    { label: "Profit & Loss", value: "Profit & Loss" },
    { label: "Equity Liability", value: "Equity Liability" },
    { label: "Clients", value: "Clients" },
    { label: "Suppliers", value: "Suppliers" },
    { label: "Payrolls", value: "Payrolls" },
    { label: "Emails", value: "Emails" },
    { label: "Account All", value: "Account All" },
    { label: "Bonus Create", value: "Bonus Create" },
    { label: "Bonus Edit", value: "Bonus Edit" },
    { label: "Bonus Delete", value: "Bonus Delete" },
    { label: "Leave", value: "Leave" },
    { label: "Balance Invoice", value: "Balance Invoice" },
    { label: "Projects Delete", value: "Projects Delete" },
    { label: "Projects Create", value: "Projects Create" },
    { label: "Projects Edit", value: "Projects Edit" },
    { label: "Payroll", value: "Payroll" },
    { label: "Job Post", value: "Job Post" },
    { label: "Vendors", value: "Vendors" },
    { label: "Company Announcement", value: "Company Announcement" },
    { label: "Company Policy", value: "Company Policy" },
    { label: "Leave Approved", value: "Leave Approved" },
    { label: "Register", value: "Register" },
    // { label: '', value: '' },
    // Add more options as needed
  ];
  useEffect(() => {
    getRoles();
  }, []);
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const { base_url, company, handleClick, setSnackbarValue } = useData();
  const [dataRolls, setRolls] = useState({
    name: "",
    permission: [],
  });
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose01 = () => setShow(false);
  const handleClearFields = () => {
    setShow(false);
  };
  const updateDynamicOptions = (inputValue) => {
    const newOptions = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setDynamicOptions(newOptions);
  };

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);
    var array = [];
    newValue?.map((item) => {
      array.push(item.value);
    });

    setRolls({ ...dataRolls, permission: array });
  };
  const create = () => {
    fetch(`${base_url}roles/${dataRolls.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(dataRolls),
    })
      .then((response) => response.json())
      .then((data) => {
        getRoles();
        handleClick();
        setSnackbarValue({
          message: "Roles Create successfully",
          color: "#2ECC71",
        });
      });
  };
  const [allRoles, setallRoles] = useState([]);
  const getRoles = () => {
    fetch(`${base_url}roles`)
      .then((response) => response.json())
      .then((data) => {
        setallRoles(data);
      });
  };
  const editRoles = () => {
    fetch(`${base_url}roles/${dataRolls.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(dataRolls),
    })
      .then((response) => response.json())
      .then((data) => {
        getRoles();
        handleClick();
        setSnackbarValue({
          message: "Roles Update successfully",
          color: "#2ECC71",
        });
      });
  };
  const deleteRoles = (id) => {
    fetch(`${base_url}roles/${id}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        getRoles();
        handleClick();
        setSnackbarValue({
          message: "Roles Delete successfully",
          color: "#2ECC71",
        });
      });
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentRoles = allRoles?.slice(indexOfFirstItem, indexOfLastItem);

  const paginationControls = [];
  for (let i = 1; i <= Math.ceil(allRoles?.length / itemsPerPage); i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </button>
    );
  }
  return (
    <>
      <div className="employeeData">
        {/* Filter box content */}
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              // position: "absolute",
              // right: "0",
              // top: "0",
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              className="fpchild1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "160px",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img src={cross} />
              </button>
            </div>
            <div className="fpchild2">
            <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label htmlFor="brancName">Roll</label>
            <select
            style={{borderRadius:"10px",width: "100%"}}
              placeholder="Select Branch"
           
              className="form-select"
            >
              <option hidden>Name</option>
             
            </select>
          </div>

              
            </div>
            <div className="fpchild2">
            <div
            className="form-group"
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label htmlFor="brancName">Permissions</label>
            <select
            style={{borderRadius:"10px",width: "100%"}}
              placeholder="Select Branch"
           
              className="form-select"
            >
              <option hidden>Name</option>
             
            </select>
          </div>

              
            </div>
            
          </div>
        </Drawer>
        <div
          classname="row "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div>
            <nav>
              <ul>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              <button
                className="btn btn-primary"
                onClick={handleOpen}
                style={{
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <img src={roleicon} />
                Add Role
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={{ borderRadius: "5px" }} className='row'>
            <div style={{ display: "flex", alignItems: "center" }} className='col-2'>
              <label>Roles:</label>
            </div>
            <div className='col-10'>
              <input value={dataRolls?.name}
                onChange={(e) => setRolls({ ...dataRolls, name: e.target.value })} style={{ background: "none", border: "1px solid ligthgray ", width: "80%", borderRadius: "5px", padding: "1rem" }} />
            </div>
          </div>
          <div style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }} >
            <div className='row'>

              <div style={{ display: "flex", alignItems: "center" }} className='col-2'>
                <label>Permissions:</label>
              </div>
              <div className='col-10'>
                <Autocomplete
                  className='w-75'
                  multiple
                  id="multi-select-chip"
                  options={dynamicOptions}
                  getOptionLabel={(option) => option.label}
                  value={selectedOptions}
                  onInputChange={(event, newInputValue) => {
                    updateDynamicOptions(newInputValue);
                  }}
                  onChange={handleSelectionChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Select Options" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "end", padding: "1rem" }}>
            <button className='btn btn-primary' onClick={() => {
              dataRolls?.id ? editRoles() : create()

              handleClose()

            }} style={{ padding: "8px 16px", marginRight: "1rem" }}>{dataRolls?.id ? "Edit" : "Create"}</button>
            <button className='btn btn-primary' style={{ padding: "8px 16px", marginRight: "1rem" }} onClick={handleClose}>Cancel</button>
          </div>
        </Box>
      </Modal> */}

      <div className="row">
        <div>
          <div className="card">
            <div className="card-body recruitment table-responsive">
              <div
                className="headtable"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div>
                  <h5>All Rolls</h5>
                </div>
                <div>
                  <button
                    onClick={handleDrawerOpen}
                    style={{
                      background: "transparent",
                      borderRadius: "10px",
                      border: "1px solid darkgray",
                      padding: "2px 5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Filter
                    <img
                      style={{ width: "20%", marginLeft: "2px" }}
                      src={filter}
                    />
                  </button>
                </div>
              </div>
              <table className="table">
                <thead style={{ background: "ghostwhite" }}>
                  <tr>
                    <th scope="col">Role ID</th>
                    <th scope="col">Role</th>
                    <th scope="col">Permissions</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentRoles?.map((item, idx) => (
                    <tr key={item.id || idx}>
                      <th scope="row">{indexOfFirstItem + idx + 1}</th>
                      <td scope="col">{item.name}</td>
                      <td scope="col">
                        <span
                          style={{
                            background: "orange",
                            padding: "3px 14px",
                            borderRadius: "5px",
                          }}
                        >
                          {item.permission?.length}
                        </span>
                      </td>
                      <td scope="col">
                        <button
                          onClick={() => {
                            var array = [];
                            item?.permission?.map((permission) => {
                              var obj = {
                                label: permission,
                                value: permission,
                              };
                              array.push(obj);
                            });
                            setSelectedOptions(array);
                            setRolls(item);
                            handleOpen();
                          }}
                          className=""
                          style={{ background: "transparent", border: "none" }}
                        >
                          {/* <CiEdit style={{ fontSize: "20px" }} /> */}
                          <img src={editicon} />
                        </button>
                        <button
                          className=""
                          onClick={() => deleteRoles(item.id)}
                          style={{ background: "transparent", border: "none" }}
                        >
                          {/* <CiTrash style={{ fontSize: "20px" }} /> */}
                          <img src={deleteicon} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-controls">{paginationControls}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal style={{ zIndex: "9999" }} show={show} onHide={handleClose01}>
        <Modal.Header
          closeButton
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <img
            style={{
              width: "70px",
              background: "gainsboro",
              borderRadius: "50%",
            }}
            src={editbrnch}
          />
          <span style={{ fontSize: "24px", color: "#667085" }}>{dataRolls?.id ? "Edit Role" : "Add Role"}</span>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <Form.Group
              className="mb-3"
              controlId="formGroupName"
              style={{ width: "100%", textAlign: "left" }}
            >
              <Form.Label>Role</Form.Label>
              <Form.Control style={{padding:"7px",borderRadius:"10px"}} type="text" placeholder="Role" />
            </Form.Group>
          </InputGroup>

          <div
            className="form-group "
            style={{
              marginBottom: "20px",
              textAlign: "left",
              position: "relative",
            }}
          >
            <label style={{ marginBottom: "5px" }} htmlFor="company">
              Permissions
            </label>
            {/* <img
              style={{ position: "absolute", right: "16px", top: "40px" }}
              src={downarrow}
            /> */}

            <Autocomplete
              className=""
              style={{border:"1px solid darkgray",borderRadius:"10px"}}
              multiple
              id="multi-select-chip"
              options={dynamicOptions}
              getOptionLabel={(option) => option.label}
              value={selectedOptions}
              onInputChange={(event, newInputValue) => {
                updateDynamicOptions(newInputValue);
              }}
              onChange={handleSelectionChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Options"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option.label} {...getTagProps({ index })} />
                ))
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              width: "38%",
              background: "white",
              color: "black",
              boxShadow: "2px 3px 3px black",
            }}
            onClick={()=>{
              setRolls({
                    name: "",
                    permission: [],
                  });
                  handleClearFields();
            }}
            
          >
            Cancel
          </Button>
          <Button style={{ width: "38%" }} variant="primary"
          onClick={() => {
            dataRolls?.id ? editRoles() : create()

            handleClose01()

          }}
          >
              {dataRolls?.id ? "Update Role" : "Create Role"}
        
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateRolls;
