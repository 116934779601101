import React, { useEffect, useState } from "react";
import { useData } from "../ContextProvider/Context";
import logo from "../Images/pngwing.com.png";
import { Link, useParams } from "react-router-dom";
import { Avatar, Box } from "@mui/material";
import AvatarGroup from "@mui/material/AvatarGroup";
import editicon from "../Images/editicon.png";
import deleteicon from "../Images/deleteicon.png";
import pcardimg from "../Images/pcardimg1.png";
import player from "../Images/pcardlyer1.png";
import WizardForm from "./CompanyCreate/WizardForm";
import EditCompany from "./Editcompany/EditCompany";
import { Snackbar, Alert } from "@mui/material";
// import { Modal } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { CiCircleMinus, CiMenuKebab } from "react-icons/ci";
import { CiRead, CiTrash } from "react-icons/ci";
import filter from "../Images/filtericon.png";
import cross from "../Images/filtercross.png";
import vector from "../Images/Vector.png";
import { Drawer, IconButton, Card, CardContent } from "@mui/material";
import companyicon from "../Images/companyicon.png";
import wbackimg from "../Images/wbackimg.png";
import wclogo from "../Images/wclogo.png";
import wcvector from "../Images/wcvector.png";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button, Dropdown } from "react-bootstrap";
import { LuDelete, LuImagePlus } from "react-icons/lu";
import { BiLinkAlt, BiTimeFive } from "react-icons/bi";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { IoOptionsOutline } from "react-icons/io5";
import downarrow from "../Images/chevron-down.png";
import CompanyProfile from "./CompanyProfile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function Company() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const params = useParams();
  const { storeCompnayData, editCompany, company, getCompnayCrud, permission, deleteCompany, img_url, getFilterEmployees, storeRecentCompany, getFilterCompany, companyProfile, getCompanyProfile, company_id, } = useData();
  const [selectedLogo, setSelectedLogo] = useState('');
  const [selectedCoverImage, setSelectedCoverImage] = useState('');

  useEffect(() => {
    getCompnayCrud();
  }, []);
  const [selectedImage, setSelectedImage] = useState('');
  const clearAllFileds = () => {
    setFormData({
      id: "",
      name: "",
      phone: "",
      email: "",
      address: "",
      trading_addr: "",
      logo: null,
      cover_image: null,
      socialLinks: [
        {
          icon: "",
          link: "",
        },
      ],
      finePolicy: [
        {
          time: "",
          type: "fixed",
          fine: 0,
        },
      ],
      timings: [
        { day: "Monday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
        {
          day: "Tuesday",
          startTime: "09:00",
          endTime: "17:00",
          totalTime: "8",
        },
        {
          day: "Wednesday",
          startTime: "09:00",
          endTime: "17:00",
          totalTime: "8",
        },
        {
          day: "Thursday",
          startTime: "09:00",
          endTime: "17:00",
          totalTime: "8",
        },
        { day: "Friday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
        {
          day: "Saturday",
          startTime: "09:00",
          endTime: "17:00",
          totalTime: "8",
        },
        { day: "Sunday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
      ],
      smtp: [
        {
          mail_host: "",
          mail_port: "",
          mail_username: "",
          mail_password: "",
          from_address: "",
          from_name: "",
          mail_mailer: "",
          mail_encryption: "",
        },
      ],
    });
    setSelectedImage('')
    setSelectedLogo('')
    setSelectedCoverImage('')
  };

  const [editValues, setEditValues] = useState();
  useEffect(() => {
    if (companyProfile) {
      const socialLinks = companyProfile.data?.social_links || [];

      // Map over socialLinks and handle the icon property
      const updatedSocialLinks = socialLinks.map((link) => ({
        icon: link.icon && typeof link.icon === 'object' ? link.icon.value : link.icon || "",
        link: link.link || "",
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        id: companyProfile.data?.id || "",
        name: companyProfile.data?.name || "",
        phone: companyProfile.data?.phone || "",
        email: companyProfile.data?.email || "",
        address: companyProfile.data?.address || "",
        trading_addr: companyProfile.data?.trading_addr || "",
        logo: companyProfile.data?.logo || null,
        cover_image: companyProfile.data?.cover_image || null,
        socialLinks: updatedSocialLinks,
        finePolicy: companyProfile.data?.fine_policy?.map(link => ({
          time: link.time || "",
          type: link.type || "",
          fine: link.fine || "",
        })) || [],
        timings: companyProfile.data?.timings?.map(timing => ({
          day: timing.day || "",
          startTime: timing.startTime || "",
          endTime: timing.endTime || "",
          totalTime: timing.totalTime || "",
        })) || [],
        smtp: companyProfile.data?.smtp?.map(smtpItem => ({
          mail_host: smtpItem.mail_host || "",
          mail_port: smtpItem.mail_port || "",
          mail_username: smtpItem.mail_username || "",
          mail_password: smtpItem.mail_password || "",
          from_address: smtpItem.from_address || "",
          from_name: smtpItem.from_name || "",
          mail_mailer: smtpItem.mail_mailer || "",
          mail_encryption: smtpItem.mail_encryption || "",
        })) || [],
      }));
    }
  }, [companyProfile]);

  const [formData, setFormData] = useState({
    id: '',
    name: "",
    phone: "",
    email: "",
    address: "",
    trading_addr: "",
    logo: null,
    cover_image: null,
    socialLinks: [
      {
        icon: "",
        link: "",
      },
    ],
    finePolicy: [
      {
        time: "",
        type: "fixed",
        fine: 0,
      },
    ],
    timings: [
      { day: "Monday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
      { day: "Tuesday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
      {
        day: "Wednesday",
        startTime: "09:00",
        endTime: "17:00",
        totalTime: "8",
      },
      { day: "Thursday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
      { day: "Friday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
      { day: "Saturday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
      { day: "Sunday", startTime: "09:00", endTime: "17:00", totalTime: "8" },
    ],
    smtp: [
      {
        mail_host: "",
        mail_port: "",
        mail_username: "",
        mail_password: "",
        from_address: "",
        from_name: "",
        mail_mailer: "",
        mail_encryption: "",
      },
    ],
  });
  useEffect(() => {
    console.log(formData)
  }, [formData])
  const submit = () => {
    if (formData?.logo?.trim() == null) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Logo is required.');
    } else if (formData?.name?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Name is required.');
    } else if (formData?.email?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Email is required.');
    } else if (!isValidEmail(formData?.email?.trim())) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Enter Valid Email Address');
    } else if (formData?.phone?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Phone is required.');
    } else if (formData?.address?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Trading Address is required.');
    } else if (formData?.address?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company Address is required.');
    } else if (formData?.cover_image?.trim() == null) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Cover Image is required.');
    } else if (formData.finePolicy.some(item => item.time.trim() === '' || item.type.trim() === '' || item.fine.trim() === '')) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Fine Policy fields are required.');
    } else {
      prepareDataForBackend(formData)
        .then((preparedData) => {
          var newData = [...preparedData.socialLinks]
          var array = []
          newData.map((item) => {
            var obj = {
              icon: item.icon,
              link: item.link
            }
            array.push(obj)
          })
          preparedData.socialLinks = array
          console.log(preparedData)
          storeCompnayData(preparedData)
          handleAllClose();
        })
        .catch((error) => {
          console.error("Error preparing data:", error);
        });
    }
  };
  const edit = () => {
    if (formData?.logo?.trim() == null) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Logo is required.');
    } else if (formData?.name?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Name is required.');
    } else if (formData?.email?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Email is required.');
    } else if (!isValidEmail(formData?.email?.trim())) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Enter Valid Email Address');
    } else if (formData?.phone?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Phone is required.');
    } else if (formData?.address?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Trading Address is required.');
    } else if (formData?.address?.trim() === '') {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Company Address is required.');
    } else if (formData?.cover_image?.trim() == null) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Cover Image is required.');
    } else if (formData.finePolicy.some(item => item.time.trim() === '' || item.type.trim() === '' || item.fine.trim() === '')) {
      setAnyFieldEmpty(true);
      setSnackbarMessage('Fine Policy fields are required.');
    } else {
      prepareDataForBackend(formData)
        .then((preparedData) => {
          var newData = [...preparedData.socialLinks]
          var array = []
          newData.map((item) => {
            var obj = {
              icon: item.icon,
              link: item.link
            }
            array.push(obj)
          })
          preparedData.socialLinks = array
          editCompany(formData, formData?.id);
          handleAllClose();
        })
        .catch((error) => {
          console.error("Error preparing data:", error);
        });
    }
  };
  const [step, setStep] = useState(1);

  const setcolor = () => {
    setDivColor("blue");
  };
  const [divColor, setDivColor] = React.useState("blue");
  const [openDrawer1, setOpenDrawer1] = React.useState(false);
  const [openDrawer2, setOpenDrawer2] = React.useState(false);
  const [openDrawer3, setOpenDrawer3] = React.useState(false);
  const [openDrawer4, setOpenDrawer4] = React.useState(false);
  const [openSmtpDrawer, setOpenSmtpDrawer] = useState(false);
  const handleAllClose = () => {
    setOpenDrawer1(false);
    setOpenDrawer2(false);
    setOpenDrawer3(false);
    setOpenDrawer4(false);
    setOpenSmtpDrawer(false);
    clearAllFileds()
  }
  const handleDrawerOpen1 = () => {
    setOpenDrawer1(true);
    setOpenDrawer2(false);
  };
  const handleDrawerClose1 = () => {
    setOpenDrawer1(false);
    clearAllFileds();
  };

  const handleDrawerOpen2 = () => {
    setOpenDrawer2(true);
    setOpenDrawer1(false);
    setOpenDrawer3(false);
  };

  const handleDrawerClose2 = () => {
    setOpenDrawer2(false);
    clearAllFileds();
  };

  const handleDrawerOpen3 = () => {
    setOpenDrawer3(true);
    setOpenDrawer2(false);
    setOpenDrawer4(false);
  };
  const handleDrawerClose3 = () => {
    setOpenDrawer3(false);
    clearAllFileds();
  };
  const handleDrawerClose4 = () => {
    setOpenDrawer4(false);
    clearAllFileds();
  };
  const handleDrawerOpen4 = () => {
    setOpenSmtpDrawer(false);
    setOpenDrawer4(true);
  };
  const handleSmtpDrawerOpen = () => {
    setOpenSmtpDrawer(true);
    setOpenDrawer3(false);
    setOpenDrawer4(false);
  };

  const handleSmtpDrawerClose = () => {
    setOpenSmtpDrawer(false);
    setOpenDrawer3(true);
  };

  const addSmtpConfig = () => {
    setFormData((prevData) => ({
      ...prevData,
      smtp: [
        ...prevData.smtp,
        {
          mail_host: "",
          mail_port: "",
          mail_username: "",
          mail_password: "",
          from_address: "",
          from_name: "",
          mail_mailer: "",
          mail_encryption: "",
        },
      ],
    }));
  };

  const removeSmtpConfig = (index) => {
    setFormData((prevData) => {
      const newSmtpConfigs = [...prevData.smtp];
      newSmtpConfigs.splice(index, 1);
      return {
        ...prevData,
        smtp: newSmtpConfigs,
      };
    });
  };

  const handleSmtpConfigChange = (index, field, value) => {
    setFormData((prevData) => {
      const newSmtpConfigs = [...prevData.smtp];
      newSmtpConfigs[index][field] = value;
      return {
        ...prevData,
        smtp: newSmtpConfigs,
      };
    });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (field, value) => {
    if (field === "logo") {
      if (value) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          setFormData({ ...formData, [field]: base64String });

          // Set the selected image URL for the company logo
          const imageUrl = URL.createObjectURL(value);
          setSelectedLogo(imageUrl);
        };
        reader.readAsDataURL(value);
      }
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };
  const handleChangeForCover = (field, value) => {
    if (field === "cover_image") {
      if (value) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target.result;
          setFormData({ ...formData, [field]: base64String });

          // Set the selected image URL for the cover image
          const imageUrl = URL.createObjectURL(value);
          setSelectedCoverImage(imageUrl);
        };
        reader.readAsDataURL(value);
      }
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };


  async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }


  const [openmodal, setopenmodal] = React.useState(false);
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const [totalTime, setTotalTime] = React.useState("");
  const backgroundStyle = {
    backgroundImage: 'url("../Images/wbackimg.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh", // Adjust the height as needed
  };
  const handleClosep = () => {
    setopenmodal(false);
  };
  const handleopen = () => {
    setopenmodal(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [dropdownStates, setDropdownStates] = useState({});
  let count = 1;

  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const visibleCompanies =
    company?.data?.filter((item) => item?.deleteStatus === 0) || [];

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const currentCompanies = visibleCompanies.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const toggleList = (companyId) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const paginationControls = [];
  for (let i = 1; i <= Math.ceil(visibleCompanies.length / itemsPerPage); i++) {
    paginationControls.push(
      <button
        className="btn btn-primary p-2"
        style={{ margin: "0px 5px" }}
        key={i}
        onClick={() => setCurrentPage(i)}
      >
        {i}
      </button>
    );
  }

  const addSocialChannel = () => {
    const newData = [...formData.socialLinks];
    newData.push({ icon: "", link: "" });
    setFormData({ ...formData, socialLinks: newData });
  };

  const removeSocialChannel = (index) => {
    const updatedChannels = [...formData.socialLinks];
    updatedChannels.splice(index, 1);
    setFormData({ ...formData, socialLinks: updatedChannels });
  };
  const addFinePolicy = () => {
    const newData = [...formData.finePolicy];
    newData.push({ time: "", type: "fixed", fine: "" });

    setFormData({ ...formData, finePolicy: newData });
  };

  const removeFinePolicy = (index) => {
    const updatedPolicies = [...formData.finePolicy];
    updatedPolicies.splice(index, 1);
    setFormData({ ...formData, finePolicy: updatedPolicies });
  };
  async function prepareDataForBackend(data) {
    const preparedData = { ...data };

    if (preparedData.socialLinks) {
      // Create an array of promises for icon conversions
      const iconConversionPromises = preparedData?.socialLinks?.map(async (channel) => {
        const iconFile = channel.icon;
        if (iconFile instanceof File) {
          const base64String = await fileToBase64(iconFile);
          return { ...channel, icon: base64String };
        }
        return channel;
      });

      // Wait for all icon conversions to complete
      preparedData.socialLinks = await Promise.all(iconConversionPromises);
    }

    return preparedData;
  }

  const calculateTotalTime = (day, idx) => {
    const dayData = formData.timings.find((item) => item.day === day);

    if (dayData && dayData.startTime && dayData.endTime) {
      const startMinutes =
        parseInt(dayData.startTime.split(":")[0]) * 60 +
        parseInt(dayData.startTime.split(":")[1]);
      const endMinutes =
        parseInt(dayData.endTime.split(":")[0]) * 60 +
        parseInt(dayData.endTime.split(":")[1]);

      const totalMinutes = endMinutes - startMinutes;
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const newdata = [...formData.timings];
      newdata[idx].totalTime = `${parseInt(hours)}`;
      setFormData({ ...formData, timings: newdata });
      return `${hours} hours and ${minutes} minutes`;
    }

    return "Not specified";
  };

  const isFormValid = () => {
    for (const key in formData) {
      if (formData[key] === "") {
        return false;
      }
    }
    return true;
  };
  const state = {
    open: false,
    vertical: "bottom",
    horizontal: "right",
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const { vertical, horizontal } = state;
  const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setAnyFieldEmpty(false);
  };
  const [show1, setshow1] = useState(false);

  const handlesocialblock = () => {
    setshow1(true);
  }
  const handlesocialblockclose = () => {
    setshow1(false);
  }

  return (
    <>
      <div className="departmentData" style={{ position: "relative" }}>
        {/* Filter box content */}
        <Drawer anchor="right" open={openDrawer} onClose={handleDrawerClose}>
          <div
            className="filterparent"
            style={{
              // position: "absolute",
              // right: "0",
              // top: "0",
              background: "white",
              padding: "18px",
              zIndex: "99",
              display: "felx",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              className="fpchild1"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "22px", fontSize: "bold" }}>Filter</span>
              <button
                onClick={handleDrawerClose}
                style={{ background: "none", border: "none" }}
              >
                <img src={cross} />
              </button>
            </div>
            <div className="fpchild2">
              <div
                className="form-group"
                style={{
                  marginBottom: "20px",
                  textAlign: "left",
                  position: "relative",
                }}
              >
                <label style={{ marginBottom: "5px" }} htmlFor="company">
                  Company
                </label>
                <img
                  style={{ position: "absolute", right: "16px", top: "40px" }}
                  src={downarrow}
                />
                <select
                  id="company"
                  style={{
                    width: "100%",
                    padding: "7px",
                  }}
                  className="form-control"
                  disabled={params.id ? true : false}
                  onChange={(e) => {
                    getFilterCompany(e.target.value);
                    // storeRecentCompany({
                    //   name: e.target.value,
                    // });
                  }}
                >
                  <option hidden> Company</option>
                  <option value={""}>All Company</option>
                  {company?.data?.map((item) => {
                    return (
                      <>
                        {item?.deleteStatus == 0 && (
                          <option value={item.name} key={item.id}>
                            {item.name}
                          </option>
                        )}
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Drawer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <nav>
              <ul style={{ paddingLeft: "3px" }}>
                <li
                  style={{
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "500",
                  }}
                >
                  Company Assets
                </li>
                <li>{formattedDate}</li>
              </ul>
            </nav>
          </div>
          <div className="d-flex justify-content-end mb-2 gap-2">
            <div>
              {permission.includes("Company Create") && (

                <button
                  onClick={() => {
                    handleDrawerOpen1();
                    handlesocialblock();
                  }}
                  className="btn btn-primary"
                  style={{
                    padding: "9px 22px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <img src={companyicon} alt="" />
                  Add Company
                </button>

              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div>
            <div className="card">
              <div className="card-body">
                <div
                  className="headtable"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "100px",
                    }}
                  >
                    <h5>All Companies</h5>
                    {/* <span>
                      Company:
                      {company?.latestSearchCompany?.map((item) => {
                        return <p>{item?.name}</p>;
                      })}
                    </span>
                    <span>Location:</span> */}
                  </div>
                  <div>
                    <button onClick={handleDrawerOpen} style={{ background: "transparent", borderRadius: "10px", border: "1px solid darkgray", padding: "2px 5px", display: "flex", alignItems: "center", justifyContent: "space-between", }}>Filter<img style={{ width: "20%", marginLeft: "2px" }} src={filter} />
                    </button>
                  </div>
                </div>
                <table className="table">
                  <thead style={{ background: "ghostwhite" }}>
                    <tr>
                      <th scope="col">CmpID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      {/* <th scope="col">Location</th> */}
                      {/* <th scope="col">Link</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentCompanies?.map((item, idx) => {
                      return (
                        <>
                          {item?.deleteStatus == 0 && (
                            <tr>
                              <th scope="row">{indexOfFirstItem + idx + 1}</th>
                              <td>
                                <Link
                                  className="link linkk link_hover"
                                  to={`/admin/company_setting/company/detail/${item.id}`}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                      }}
                                    >
                                      <img
                                        style={{ borderRadius: "50%" }}
                                        src={
                                          img_url + item.logo
                                            ? img_url + item.logo
                                            : logo
                                        }
                                        width={"100%"}
                                        height={"100%"}
                                      />
                                    </div>
                                    <div>{item.name}</div>
                                  </div>
                                </Link>
                              </td>
                              <td>{item.email}</td>
                              <td>{item.phone}</td>

                              <td style={{ position: "relative" }}>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    className="mydrop"
                                    variant="success"
                                    id="dropdown-basic"
                                    style={{
                                      background: "unset",
                                      border: "0",
                                      padding: "0",
                                      boxShadow: "unset",
                                    }}
                                  >
                                    <CiMenuKebab
                                      style={{
                                        fontSize: "24px",
                                        color: "black",
                                      }}
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        getCompanyProfile(item?.id);
                                        handleDrawerOpen1();
                                      }}
                                    >
                                      Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteCompany(item?.id)}>Delete</Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        handleopen();
                                        getCompanyProfile(item?.id);
                                      }}
                                    >
                                      View Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <Link
                                        to={
                                          company_id
                                            ? `/admin/company-setting/${company_id}`
                                            : "/admin/company-setting"
                                        }
                                        style={{
                                          color: "black",
                                          textDecoration: "none",
                                        }}
                                      >

                                        Company Settings

                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination-controls">{paginationControls}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="companymodal"
        style={{ zIndex: "9999" }}
        show={openmodal}
        onHide={handleClosep}
      >
        <Modal.Body className="companym">
          <div>
            <div
              className="pcardbody"
              style={{
                background: "white",
                padding: "8px",
                borderRadius: "10px",
              }}
            >
              <div>
                <img alt="" src={pcardimg} style={{ width: "100%", maxHeight: "235px" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                {/* <Link to={`/admin/projects/detail/${items?.id}`} className="link_hover"> */}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#667085",
                    marginTop: "10px",
                    maxWidth: "135px",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  <Link
                    to={`/admin/company_setting/company/detail/${companyProfile?.data?.id}`}
                  >
                    {companyProfile?.data?.name}
                  </Link>
                </span>

                <div
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    position: "absolute",
                    right: "67px",
                    zIndex: "2",
                  }}
                >
                  <img
                    alt=""
                    src={img_url + companyProfile?.data?.logo}
                    height={"100%"}
                    width={"100%"}
                    style={{ borderRadius: "50%" }}
                  />
                </div>
                <img
                  style={{
                    position: "absolute",
                    zIndex: "1",
                    right: "30px",
                    top: "-27px",
                  }}
                  src={player}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  position: "relative",
                  alignItems: "center",
                }}
              >
                <div className="avartarGroupBox">
                  {/* <img src={collabs}/> */}

                  <AvatarGroup max={4}>
                    {companyProfile?.data?.employees?.map((item) => {
                      return (
                        <Avatar
                          style={{
                            height: "30px",
                            width: "30px",
                            border: "1px solid lightgray",
                          }}
                          alt=""
                          src={img_url + item?.profile}
                        />
                      );
                    })}
                  </AvatarGroup>
                </div>
                <div>
                  <span>{companyProfile?.data?.created_at?.split("T")[0]}</span>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer1}
        onClose={handleDrawerClose1}
        style={{ width: "800px" }}
      >

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3" style={{ height: "84vh", padding: "18px", borderRadius: "20px", background: "white", display: "flex", justifyContent: "left", }}>
              <div className="r1c1cld1 circle1" style={{ marginLeft: "30px", marginTop: "11px" }}>
                <div className="" style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", zIndex: "2", background: divColor, }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: 'white', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
              </div>
              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic info</li>
                  <li>Social Channels</li>
                  <li>Fine & TC</li>
                  <li>SMTP</li>
                  <li>Working Time</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                padding: "0px",
              }}
            >
              <div
                className="headerimg"
                style={{
                  backgroundImage: `url(${wbackimg})`,
                  height: "146px",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  borderRadius: "10px",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "94%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                  src={wcvector}
                />

                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    padding: "16px",
                    borderRadius: "50%",
                    background: "darkgray",
                  }}
                >
                  <div
                    style={{
                      border: "2px solid",
                      height: "70px",
                      borderRadius: "50%",
                      width: "70px",
                      position: "relative",
                    }}
                  >
                    <label
                      style={{
                        border: "1px solid",
                        borderRadius: "50%",
                        width: "25px",
                        height: "25px",
                        background: "lightgray",
                        color: "gray",
                        position: "absolute",
                        right: "0%",
                        top: "54px",
                        paddingLeft: ".2rem",
                        paddingBottom: ".2rem",
                      }}
                      htmlFor="cp1"
                    >
                      <LuImagePlus />
                    </label>
                    <img
                      style={{
                        width: "70%",
                        height: "70%",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      src={selectedLogo || (img_url + formData?.logo)}
                    />
                  </div>
                  <input
                    type="file"
                    id="cp1"
                    name="companyLogo"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleChange("logo", e.target.files[0])}
                  />
                </div>
              </div>
              <div className="inputsection  scrollBar" style={{ marginTop: "60px", maxHeight: "295px" }}>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} >
                      <Form.Label>Company Name<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        id="cp2"
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Company E-mail<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        id="id2"
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} >
                      <Form.Label>Company Contact Number<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        id="cp3"
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="number"
                        placeholder="123456789"
                        value={formData.phone}
                        onChange={(e) => handleChange("phone", e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group as={Col} >
                      <Form.Label>Trading Address<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        id="cp4"
                        style={{ padding: "10px", borderRadius: "10px" }}
                        type="text"
                        placeholder="Trading Address"
                        value={formData.trading_addr}
                        onChange={(e) =>
                          handleChange("trading_addr", e.target.value)
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} >
                      <Form.Label>Company Address<span style={{ color: "red" }}>*</span></Form.Label>
                      <Form.Control
                        id="cp5"
                        style={{
                          width: "49%",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                        type="text"
                        placeholder="Company Address:"
                        value={formData.address}
                        onChange={(e) =>
                          handleChange("address", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} >
                      <Form.Label>Cover Image<span style={{ color: "red" }}>*</span></Form.Label>
                      <div className="border" style={{ height: "40px", borderRadius: "10px", display: "flex", justifyContent: "space-between" }}>
                        <div>
                          <label htmlFor='cp1Cover'>Select</label>
                        </div>
                        <div style={{ height: "100%", width: "10%" }}>
                          <img style={{ height: "100%", width: "100%" }} src={selectedCoverImage || (img_url + formData?.cover_image)} alt='' />
                        </div>
                      </div>
                      <Form.Control
                        type="file"
                        id="cp1Cover"
                        name="companyCoverImage"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => handleChangeForCover("cover_image", e.target.files[0])}
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={() => {
                  handleDrawerClose1();
                  handlesocialblockclose();
                }}
              >
                {" "}
                Discard
              </Button>
              <Button type="submit" variant="danger" onClick={() => { formData?.id ? edit() : submit() }}>
                {" "}
                {formData?.id ? "Update$Exit" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen2();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>

      </Drawer>
      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer2}
        onClose={handleDrawerClose2}
      >
        {/* Drawer 2 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3" style={{ height: "84vh", padding: "18px", borderRadius: "20px", background: "white", display: "flex", justifyContent: "left", }}>
              <div className="r1c1cld1 circle1" style={{ marginLeft: "30px", marginTop: "11px" }}>
                <div className="" style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", background: divColor, }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: 'white', position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
              </div>
              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic info</li>
                  <li>Social Channels</li>
                  <li>Fine & TC</li>
                  <li>SMTP</li>
                  <li>Working Time</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                padding: "0px"
              }}
            >

              <div className="inputsection  scrollBar " style={{ marginTop: "25px", height: "475px" }}>
                <h3>Social Channels</h3>
                <div
                  className="mb-2"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <button
                    style={{
                      padding: ".5rem 1rem",
                      border: "none",
                      color: "white",
                      background: "#007bff",
                      borderRadius: "5px",
                    }}
                    onClick={addSocialChannel}
                  >
                    Add More
                  </button>
                </div>

                <div style={{ display: show1 ? "none" : "flex", alignItems: "center", width: "100%" }} className="row mb-3">
                  {formData?.socialLinks?.map((channel, index) => (
                    <>
                      <div className="col-md-2 mb-3">
                        <div style={{ height: "80px", width: "80px", borderRadius: "50%", border: "1px solid", position: "relative", }}>
                          <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem", }} htmlFor={`channelLogo${index}`}>
                            <LuImagePlus />
                          </label>
                          <img
                            src={selectedImage[index] || (img_url + channel.icon)}
                            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                          />
                          <input
                            type="file"
                            id={`channelLogo${index}`}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              const newData = [...formData.socialLinks];
                              newData[index].icon = e.target.files[0];
                              setFormData({ ...formData, socialLinks: newData });

                              // Set the selected image URL for the specific channel
                              const imageUrl = URL.createObjectURL(e.target.files[0]);
                              setSelectedImage((prevSelectedImages) => {
                                const newSelectedImages = [...prevSelectedImages];
                                newSelectedImages[index] = imageUrl;
                                return newSelectedImages;
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div style={{ border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem", display: "flex", }}>
                          <label htmlFor={`socialLinks${index}`}>
                            <BiLinkAlt />
                          </label>
                          <input
                            style={{ border: "none" }}
                            type="text"
                            id={`socialLinks${index}`}
                            placeholder="Channel Link"
                            value={channel.link}
                            onChange={(e) => {
                              const newData = [...formData.socialLinks];
                              newData[index].link = e.target.value;
                              setFormData({ ...formData, socialLinks: newData });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        {index > 0 && (
                          <button
                            style={{
                              padding: "0rem 1rem",
                              border: "none",
                              color: "white",
                              background: "#007bff",
                              borderRadius: "5px",
                            }}
                            onClick={() => removeSocialChannel(index)}
                          >
                            <LuDelete />
                          </button>
                        )}
                      </div>
                    </>
                  ))}
                </div>
                <div className="displaynoneblock row mb-3" style={{ display: show1 ? "flex" : "none", alignItems: "center", width: "100%" }}>
                  {formData?.socialLinks?.map((channel, index) => (
                    <>
                      <div className="col-2 mb-3">
                        <div
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "50%",
                            border: "1px solid",
                            position: "relative",
                          }}
                        >
                          <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem", }} htmlFor={`channelLogo${index}`}>
                            <LuImagePlus />
                          </label>
                          <img
                            src={selectedImage[index] || (img_url + channel.icon)}
                            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                          />
                          <input
                            type="file"
                            id={`channelLogo${index}`}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              const newData = [...formData.socialLinks];
                              newData[index].icon = e.target.files[0];
                              setFormData({ ...formData, socialLinks: newData });
                              const imageUrl = URL.createObjectURL(e.target.files[0]);
                              setSelectedImage((prevSelectedImages) => {
                                const newSelectedImages = [...prevSelectedImages];
                                newSelectedImages[index] = imageUrl;
                                return newSelectedImages;
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-8">
                        <div
                          style={{
                            border: "1px solid lightgray",
                            borderRadius: "5px",
                            padding: ".5rem",
                            display: "flex",
                          }}
                        >
                          <label htmlFor={`socialLinks${index}`}>
                            <BiLinkAlt />
                          </label>
                          <input
                            style={{ border: "none" }}
                            type="text"
                            id={`socialLinks${index}`}
                            placeholder="Channel Link"
                            value={channel.link}
                            onChange={(e) => {
                              const newData = [...formData.socialLinks];
                              newData[index].link = e.target.value;
                              setFormData({ ...formData, socialLinks: newData });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        {index > 0 && (
                          <button
                            style={{
                              padding: "0rem 1rem",
                              border: "none",
                              color: "white",
                              background: "#007bff",
                              borderRadius: "5px",
                            }}
                            onClick={() => removeSocialChannel(index)}
                          >
                            <LuDelete />
                          </button>
                        )}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={() => {
                  handleDrawerClose2();
                  handlesocialblockclose();

                }}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen1}
              >
                Back
              </Button>
              <Button type="submit" variant="danger" onClick={() => { formData?.id ? edit() : submit() }}>
                {" "}
                {formData?.id ? "Update$Exit" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen3();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer3}
        onClose={handleDrawerClose3}
      >
        {/* Drawer 3 content */}

        <div className="wizradparent1" style={{ padding: "40px", background: "ghostwhite" }}>
          <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3" style={{ height: "84vh", padding: "18px", borderRadius: "20px", background: "white", display: "flex", justifyContent: "left", }}>
              <div className="r1c1cld1 circle1" style={{ marginLeft: "30px", marginTop: "11px" }}>
                <div className="" style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", background: divColor, }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2" }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
              </div>
              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic info</li>
                  <li>Social Channels</li>
                  <li>Fine & TC</li>
                  <li>SMTP</li>
                  <li>Working Time</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                padding: "0px"
              }}
            >
              <div className="inputsection  scrollBar" style={{ marginTop: "25px", height: "475px" }}>
                <h3>Fine and TC</h3>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      padding: ".5rem 1rem",
                      border: "none",
                      color: "white",
                      background: "#007bff",
                      borderRadius: "5px",
                    }}
                    onClick={addFinePolicy}
                  >
                    Add More
                  </button>
                </div>
                <div className="displaynoneblock" style={{ display: show1 ? "block" : "none", alignItems: "center", width: "100%" }}>
                  {formData?.finePolicy?.map((policy, index) => (
                    <div className="mb-3" key={index}>
                      {index > 0 && (
                        <div className="mb-2" style={{ display: "flex", justifyContent: "end", marginTop: "1rem", }}>
                          <button style={{ padding: "0rem 1rem", border: "none", color: "white", background: "#007bff", borderRadius: "5px", }}
                            onClick={() => removeFinePolicy(index)}
                          >
                            <CiCircleMinus />
                          </button>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-md-4">
                          <div
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                              padding: ".5rem",
                              display: "flex",
                            }}
                          >
                            <label htmlFor={`minutes${index}`}>
                              <BiTimeFive />
                            </label>
                            <input
                              style={{ border: "none" }}
                              type="number"
                              id={`minutes${index}`}
                              placeholder="Minutes"
                              value={policy.time}
                              onChange={(e) => {
                                const newData = [...formData.finePolicy];
                                newData[index].time = e.target.value;
                                setFormData({ ...formData, finePolicy: newData });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                              padding: ".5rem",
                            }}
                          >
                            <label htmlFor={`option${index}`}>
                              <IoOptionsOutline />
                            </label>
                            <select
                              id={`option${index}`}
                              style={{
                                border: "none",
                                outline: "none",
                                width: "90%",
                                fontSize: "13px",
                              }}
                              value={policy.type}
                              onChange={(e) => {
                                const newData = [...formData.finePolicy];
                                newData[index].type = e.target.value;
                                setFormData({ ...formData, finePolicy: newData });
                              }}
                            >
                              <option hidden>Select</option>
                              <option value="fixed">Fixed Amount</option>
                              <option value="percentage">
                                Percentage of Salary
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                              padding: ".5rem",
                              display: "flex",
                            }}
                          >
                            <label htmlFor={`amount${index}`}>
                              <AiOutlineDollarCircle />
                            </label>
                            <input
                              style={{ border: "none" }}
                              type="text"
                              id={`amount${index}`}
                              value={policy.fine}
                              placeholder="Amount"
                              onChange={(e) => {
                                const newData = [...formData.finePolicy];
                                newData[index].fine = e.target.value;
                                setFormData({ ...formData, finePolicy: newData });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="displaynoneblock" style={{ display: show1 ? "none" : "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ alignItems: "center" }} className="row">
                    {formData?.finePolicy?.map((policy, index) => (
                      <div className="row">
                        {index > 0 && (
                          <div
                            className="mb-2"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginTop: "1rem",
                            }}
                          >
                            <button
                              style={{
                                padding: "0rem 1rem",
                                border: "none",
                                color: "white",
                                background: "#007bff",
                                borderRadius: "5px",

                              }}
                              onClick={() => removeFinePolicy(index)}
                            >
                              <CiCircleMinus />
                            </button>
                          </div>
                        )}

                        <div className="col-md-4">
                          <div
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                              padding: ".5rem",
                              display: "flex",
                            }}
                          >
                            <label htmlFor={`minutes${index}`}>
                              <BiTimeFive />
                            </label>
                            <input
                              style={{ border: "none" }}
                              type="number"
                              id={`minutes${index}`}
                              placeholder="Minutes"
                              value={policy.time}
                              onChange={(e) => {
                                const newData = [...formData.finePolicy];
                                newData[index].time = e.target.value;
                                setFormData({ ...formData, finePolicy: newData });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                              padding: ".5rem",
                            }}
                          >
                            <label htmlFor={`option${index}`}>
                              <IoOptionsOutline />
                            </label>
                            <select
                              id={`option${index}`}
                              style={{
                                border: "none",
                                outline: "none",
                                width: "90%",
                                fontSize: "13px",
                              }}
                              value={policy.type}
                              onChange={(e) => {
                                const newData = [...formData.finePolicy];
                                newData[index].type = e.target.value;
                                setFormData({ ...formData, finePolicy: newData });
                              }}
                            >
                              <option hidden>Select</option>
                              <option value="fixed">Fixed Amount</option>
                              <option value="percentage">
                                Percentage of Salary
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div
                            style={{
                              border: "1px solid lightgray",
                              borderRadius: "5px",
                              padding: ".5rem",
                              display: "flex",
                            }}
                          >
                            <label htmlFor={`amount${index}`}>
                              <AiOutlineDollarCircle />
                            </label>
                            <input
                              style={{ border: "none" }}
                              type="text"
                              id={`amount${index}`}
                              value={policy.fine}
                              placeholder="Amount"
                              onChange={(e) => {
                                const newData = [...formData.finePolicy];
                                newData[index].fine = e.target.value;
                                setFormData({ ...formData, finePolicy: newData });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={() => {
                  handleDrawerClose3();
                  handlesocialblockclose();
                }}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleDrawerOpen2}
              >
                Back
              </Button>
              <Button type="submit" variant="danger" onClick={() => { formData?.id ? edit() : submit() }}>
                {" "}
                {formData?.id ? "Update$Exit" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleSmtpDrawerOpen();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openSmtpDrawer}
        onClose={handleSmtpDrawerClose}
      >
        <div className="wizradparent1" style={{ padding: "40px", background: "ghostwhite" }}>
          <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3" style={{ height: "84vh", padding: "18px", borderRadius: "20px", background: "white", display: "flex", justifyContent: "left", }}>
              <div className="r1c1cld1 circle1" style={{ marginLeft: "30px", marginTop: "11px" }}>
                <div className="" style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", background: divColor, }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
              </div>
              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic info</li>
                  <li>Social Channels</li>
                  <li>Fine & TC</li>
                  <li>SMTP</li>
                  <li>Working Time</li>
                </ul>
              </div>
            </div>
            <div className="col-md-9 p-3 scrollBar" style={{ borderRadius: "12px", width: "70%", background: "white", padding: "0px", height: "84vh", overflowY: "scroll" }}>
              <div className="your-smtp-content">
                <div className="row mb-3 d-flex justify-content-end">
                  <button onClick={addSmtpConfig} className='btn btn-primary' style={{ width: "max-content" }}>Add More</button>
                </div>

                <div>
                  {formData?.smtp?.map((item, i) => (
                    <div className='row mb-3' style={{ borderBottom: "1px solid lightgray" }} key={i}>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail Host"
                          value={item.mail_host}
                          onChange={(e) => handleSmtpConfigChange(i, 'mail_host', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail Port"
                          value={item.mail_port}
                          onChange={(e) => handleSmtpConfigChange(i, 'mail_port', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail User Name"
                          value={item.mail_username}
                          onChange={(e) => handleSmtpConfigChange(i, 'mail_username', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail Password"
                          value={item.mail_password}
                          onChange={(e) => handleSmtpConfigChange(i, 'mail_password', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail from address"
                          value={item.from_address}
                          onChange={(e) => handleSmtpConfigChange(i, 'from_address', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail from name"
                          value={item.from_name}
                          onChange={(e) => handleSmtpConfigChange(i, 'from_name', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail Mailer"
                          value={item.mail_mailer}
                          onChange={(e) => handleSmtpConfigChange(i, 'mail_mailer', e.target.value)}
                        />
                      </div>
                      <div className="col-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Mail Encription"
                          value={item.mail_encryption}
                          onChange={(e) => handleSmtpConfigChange(i, 'mail_encryption', e.target.value)}
                        />
                      </div>
                      {i > 0 && <button onClick={() => removeSmtpConfig(i)} className='btn btn-primary mb-1' style={{ width: "max-content" }}>-</button>}
                    </div>
                  ))}
                </div>

              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={() => {
                  handleSmtpDrawerClose();
                  handlesocialblockclose();
                }}
              >
                {" "}
                Discard
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={handleSmtpDrawerClose}
              >
                Back
              </Button>
              <Button type="submit" variant="danger" onClick={() => { formData?.id ? edit() : submit() }}>
                {" "}
                {formData?.id ? "Update$Exit" : "Save&Exit"}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={() => {
                  handleDrawerOpen4();
                  setcolor();
                }}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        className="mycmpny-drawer"
        anchor="right"
        open={openDrawer4}
        onClose={handleDrawerClose4}
      >
        {/* Drawer 4 content */}

        <div
          className="wizradparent1"
          style={{ padding: "40px", background: "ghostwhite" }}
        >
          <div className="row" style={{ gap: "40px" }}>
            <div className="col-md-3" style={{ height: "84vh", padding: "18px", borderRadius: "20px", background: "white", display: "flex", justifyContent: "left", }}>
              <div className="r1c1cld1 circle1" style={{ marginLeft: "30px", marginTop: "11px" }}>
                <div className="" style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2" }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
                <div style={{ width: "30px", height: "30px", borderRadius: "50%", background: "ghostwhite", position: "relative", marginTop: "25px", zIndex: "2", background: divColor, }}>
                  <div style={{ width: "10px", height: "10px", borderRadius: "50%", background: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", }}></div>
                </div>
              </div>
              <div>
                <ul style={{ lineHeight: "54px" }}>
                  <li>Basic info</li>
                  <li>Social Channels</li>
                  <li>Fine & TC</li>
                  <li>SMTP</li>
                  <li>Working Time</li>
                </ul>
              </div>
            </div>
            <div
              className="col-md-9"
              style={{
                borderRadius: "12px",
                width: "70%",
                background: "white",
                padding: "0px"
              }}
            >

              <div className="inputsection  scrollBar" style={{ marginTop: "25px", maxHeight: "475px" }}>
                <h3>Working Time</h3>
                <div className="timing-form create_compant_timing">
                  {formData?.timings?.map((day, index) => (
                    <div className="row align-items-center mb-1">
                      <div className="col-2">
                        <p>{day.day}</p>
                      </div>
                      <div className="col-3">
                        <label>Start Time</label>
                        <input
                          type="time"
                          id="cp6"
                          value={day.startTime}
                          onChange={(e) => {
                            const newData = [...formData.timings];
                            newData[index].startTime = e.target.value;
                            setFormData({ ...formData, timings: newData });
                            calculateTotalTime(day.day, index);
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <label>End Time</label>
                        <input
                          type="time"
                          id="cp7"
                          value={day.endTime}
                          onChange={(e) => {
                            const newData = [...formData.timings];
                            newData[index].endTime = e.target.value;

                            setFormData({ ...formData, timings: newData });
                            calculateTotalTime(day.day, index);
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <label>Total Time:</label>

                        <span>{day.totalTime}hr</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="light"
                onClick={() => {
                  handleDrawerClose4();
                  handlesocialblockclose();
                }}
              >
                {" "}
                Discard
              </Button>

              <Button
                type="submit"
                variant="primary"
                onClick={handleSmtpDrawerOpen}
              >
                Back
              </Button>
              <Button type="submit" variant="danger" onClick={() => { formData?.id ? edit() : submit() }}>
                {" "}
                {formData?.id ? "Update$Exit" : "Save&Exit"}
              </Button>

            </div>
          </div>
        </div>
      </Drawer>
      <Snackbar
        open={anyFieldEmpty}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          severity="error"
          style={{
            backgroundColor: "#8f2018",
            color: "white",
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Company;
