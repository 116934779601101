import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { useParams } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { FaPlus } from "react-icons/fa6";
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'

function CustomerSalesReciptEdit() {
    const params = useParams()
    const { getDat, settingDat, updateCustomerSalesRcpt, getAccountsDataC, getSalesDetailIndiData, sale_receiptDetailIndiData, accountsDataC, storeCustomerSalesRecipt, storeCustomerInvoice, salesPurchase, getsalesPurchase, getGerviceData, serviceData, getInvoiceNumberData, invoiceNumberData, getClientIndiData, clientIndiData, } = useData()
    useEffect(() => {
        getInvoiceNumberData()
        getClientIndiData(params?.id)
        getGerviceData()
        getsalesPurchase()
        getAccountsDataC()
        getSalesDetailIndiData(params.id)
        getDat()
    }, [])
    useEffect(() => {
        setObj((prevObj) => ({
            ...prevObj,
            account_id: sale_receiptDetailIndiData?.sale_receipt?.account_id,
            client_id: sale_receiptDetailIndiData?.sale_receipt?.client_id,
            company_id: sale_receiptDetailIndiData?.sale_receipt?.company_id,
            payment_method: sale_receiptDetailIndiData?.sale_receipt?.payment_method,
            total_amount: sale_receiptDetailIndiData?.sale_receipt?.total_amount,
            sub_total_amount: sale_receiptDetailIndiData?.sale_receipt?.sub_total_amount,
            total_discount: sale_receiptDetailIndiData?.sale_receipt?.total_discount,
            discount: sale_receiptDetailIndiData?.sale_receipt?.discount,
            whichtax: sale_receiptDetailIndiData?.sale_receipt?.whichtax,
            reference_no: sale_receiptDetailIndiData?.sale_receipt?.reference_no,
            invoice_date: sale_receiptDetailIndiData?.sale_receipt?.invoice_date
                ? sale_receiptDetailIndiData?.sale_receipt?.invoice_date
                : new Date().toISOString().split('T')[0],
            sale_receipt_no: sale_receiptDetailIndiData?.sale_receipt?.sale_receipt_no ? sale_receiptDetailIndiData?.sale_receipt?.sale_receipt_no : invoiceNumberData?.sale_receipt_no,
            description: sale_receiptDetailIndiData?.sale_receipt?.description,
            message: sale_receiptDetailIndiData?.sale_receipt?.message,
            image: '',
            sale_receipt_service: sale_receiptDetailIndiData?.sale_receipt?.service?.map((item) => ({
                service_date: item.service_date,
                service_id: item.service_id,
                quantity: item.quantity,
                total_price: item.total_price,
                tax_id: item.tax_id,
                extax: item.extax,
            })) || [],
        }));
    }, [sale_receiptDetailIndiData, invoiceNumberData]);

    const [taxType, setTaxType] = useState(settingDat?.data?.tax_form || '');
        const [obj, setObj] = useState({
        client_id: clientIndiData?.data?.id,
        company_id: clientIndiData?.data?.company?.id,
        payment_method: '',
        total_amount: 0,
        sub_total_amount: 0,
        total_discount: 0,
        discount: 0,
        account_id: '',
        whichtax: taxType,
        invoice_date: new Date().toISOString().split('T')[0],
        reference_no: '',
        sale_receipt_no: invoiceNumberData?.sale_receipt_no,
        description: '',
        message: '',
        image: '',
        sale_receipt_service: [
            {
                service_id: '',
                service_date: new Date().toISOString().split('T')[0],
                quantity: '',
                total_price: '',
                tax_id: '',
                extax: '',
            },
        ],
    });
    useEffect(() => {
        const totalAmountSum = obj?.sale_receipt_service?.reduce((sum, item) => {
            return sum + parseFloat(item?.total_price || 0);
        }, 0);

        setObj(prevObj => ({
            ...prevObj,
            sub_total_amount: Math.round(totalAmountSum),
        }));
    }, [obj.sale_receipt_service]);


    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Data = reader.result;

                setObj((prevObj) => ({
                    ...prevObj,
                    image: base64Data,
                }));
            };

            reader.readAsDataURL(file);
        }
    };
    const handleAddRow = () => {
        const newRow = {
            service_id: '',
            service_date: new Date().toISOString().split('T')[0],
            quantity: '',
            total_price: '',
            tax_id: taxType === 'Inclusive Tax' ? null : '',
            extax: taxType === 'Exclusive Tax' ? '' : null,
        };

        setObj((prevObj) => ({
            ...prevObj,
            sale_receipt_service: [...prevObj.sale_receipt_service, newRow],
        }));
    };


    const handleDeleteRow = (index) => {
        setObj((prevObj) => {
            const updatedInvoiceService = [...prevObj.sale_receipt_service];
            updatedInvoiceService.splice(index, 1);
            return { ...prevObj, sale_receipt_service: updatedInvoiceService };
        });
    };

    const handleChangeService = (value, index, key) => {
        setObj((prevObj) => {
            const updatedInvoice = [...prevObj.sale_receipt_service];
            console.log(updatedInvoice);
            updatedInvoice[index][key] = value;

            const selectedService = serviceData?.services?.find((i) => i.id === Number(updatedInvoice[index].service_id));
            const subTotal = updatedInvoice[index].quantity * (selectedService?.sale_price || 0);

            let taxRate = 0;
            if (key === 'tax_id') {
                taxRate = salesPurchase?.sale_tax?.find((i) => i.id === Number(value))?.rate || 0;
            } else if (key === 'extax') {
                taxRate = parseFloat(value) || 0;
            }
            const selectedTaxType = updatedInvoice[index].extax === null ? 'Inclusive Tax' : 'Exclusive Tax';
            const totalAmount = selectedTaxType === 'Inclusive Tax'
                ? subTotal + (subTotal * (taxRate / 100))
                : subTotal + (subTotal * (taxRate / 100));
            updatedInvoice[index].total_price = Math.round(totalAmount);

            return { ...prevObj, sale_receipt_service: updatedInvoice, amountsare: selectedTaxType };
        });
    };
    const billingAddress = sale_receiptDetailIndiData?.sale_receipt?.client?.bill_address + ',' + sale_receiptDetailIndiData?.sale_receipt?.client?.bill_city + ',' + sale_receiptDetailIndiData?.sale_receipt?.client?.bill_province + ',' + sale_receiptDetailIndiData?.sale_receipt?.client?.bill_country + ',' + sale_receiptDetailIndiData?.sale_receipt?.client?.bill_postal
    const handleSubmit = () => {
        updateCustomerSalesRcpt(obj, params.id)
    }
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'salesRecipt.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'salesRecipt.pdf';
                    a.style.display = 'none';
                    document?.body?.appendChild(a);
                    a.click();
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    useEffect(() => {
        const discountPercentage = parseFloat(obj?.discount || 0);
        const discountOnSubTotal = (discountPercentage / 100) * obj?.sub_total_amount;
        const totalAmountFinal = obj.sub_total_amount - discountOnSubTotal;
        setObj((prevObj) => ({
            ...prevObj,
            total_amount: totalAmountFinal,
            total_discount: discountOnSubTotal,
        }));
    }, [obj?.discount, obj?.sub_total_amount]);

    return (
        <div>
            <div className="card" ref={contentRef}>
                <div className="card-body">
                    <div className="container mb-5 mt-3">
                        <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: '#7e8d9f', fontSize: '20px' }}>Sales Recipt#:  {obj?.sale_receipt_no}</p>
                            </div>
                            <div>
                                <div className='card p-3 text-center'>
                                    <h4 style={{ fontSize: "25px", fontwe: "bold" }}>PRs, {Math.round(obj.total_amount)}</h4>
                                    <p className='p-0 m-0' style={{ fontSize: "13px" }}>Balance Due</p>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary text-capitalize mx-3"
                                    style={{ backgroundColor: '#60bdf3' }}
                                    onClick={handlePrint}
                                >
                                    Download PDF
                                </button>
                                <button className='btn btn-success' onClick={handleSubmit}>Update Sales Recipt</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="row">
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "start" }}>
                                <div>
                                    <div style={{ height: "65px", }}>
                                        <label>Customer</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{sale_receiptDetailIndiData?.sale_receipt?.client?.name ? sale_receiptDetailIndiData?.sale_receipt?.client?.name : '-'}</p>
                                    </div>
                                    <div style={{ height: "65px", }}>
                                        <label>Email</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{sale_receiptDetailIndiData?.sale_receipt?.client?.email ? sale_receiptDetailIndiData?.sale_receipt?.client?.email : '-'}</p>
                                    </div>
                                    <div>
                                        <label>Billing address</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{billingAddress ? billingAddress : '-'}</p>
                                    </div>
                                    <div style={{ height: "65px" }}>
                                        <label>Amounts are:</label>
                                        <select
                                            className='form-select'
                                            style={{ width: '300px', fontSize: "12px" }}
                                            onChange={(e) => {
                                                setObj({ ...obj, whichtax: e.target.value })
                                                setTaxType(e.target.value)
                                            }}
                                            value={obj?.whichtax}
                                        >
                                            <option hidden>Select</option>
                                            <option value={'Inclusive Tax'}>Inclusive Tax</option>
                                            <option value={'Exclusive Tax'}>Exclusive Tax</option>
                                            <option value={'Out of Scope of Tax'}>Out of Scope of Tax</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                                <div style={{ width: "50%" }}>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Reference No</label>
                                        <input type="text" style={{ fontSize: "13px" }} value={obj?.reference_no} onChange={(e) => setObj({ ...obj, reference_no: e.target.value })} placeholder='Referance No.' className='form-control' />
                                    </div>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Invoice Date</label>
                                        <input type="date" style={{ fontSize: "13px" }} value={obj?.invoice_date} onChange={(e) => setObj({ ...obj, invoice_date: e.target.value })} placeholder='Email' className='form-control' />
                                    </div>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Payment Method</label>
                                        <select
                                            className='form-select'
                                            style={{ fontSize: "13px" }}
                                            value={obj?.payment_method} onChange={(e) => setObj({ ...obj, payment_method: e.target.value })}
                                        >
                                            <option hidden>Select</option>
                                            <option value='Cash'>Cash</option>
                                            <option value='Check'>Check</option>
                                            <option value='Credit Card'>Credit Card</option>
                                            <option value='Debit Card'>Debit Card</option>
                                            <option value='Direct Debit'>Direct Debit</option>
                                        </select>
                                    </div>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Deposit to</label>
                                        <select
                                            value={obj?.account_id} onChange={(e) => setObj({ ...obj, account_id: e.target.value })}
                                            style={{ fontSize: "13px" }}
                                            className='form-select'
                                        >
                                            <option hidden>Select</option>
                                            {accountsDataC?.data?.map((items) => {
                                                return (
                                                    <option key={items.id} value={items.id}>{items.name}</option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2 mx-1 justify-content-center">
                            <table className="table table-striped table-borderless">
                                <thead style={{ backgroundColor: '#84B0CA' }} className="text-white">
                                    <tr>
                                        <th scope="col" style={{ fontSize: "12px" }}>#</th>
                                        {settingDat?.data?.service_date === 1 && <th scope="col" style={{ fontSize: "12px" }}>Service Date</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Service Name</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Description</th>
                                       {obj?.whichtax == 'Out of Scope of Tax' ? "" :  <th scope="col" style={{ fontSize: "12px" }}>Tax</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Quantity</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Sales Price</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Total</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {obj.sale_receipt_service.map((item, index) => {
                                        const selectedService = serviceData?.services?.find((i) => i.id === Number(item.service_id));
                                        const subTotal = item.quantity * (selectedService?.sale_price || 0);
                                        const taxRate = salesPurchase?.sale_tax?.find((i) => i.id === Number(item.tax_id))?.rate || 0;
                                        const TotalAmount = subTotal + (subTotal * (taxRate / 100));

                                        return (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                {settingDat?.data?.service_date === 1 && <td style={{ fontSize: "13px" }}>
                                                    <input className='form-control'
                                                        value={item.service_date}
                                                        onChange={(e) =>
                                                            handleChangeService(e.target.value, index, 'service_date')
                                                        }
                                                        type='date' />
                                                </td>}
                                                <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        value={item.service_id}
                                                        onChange={(e) =>
                                                            handleChangeService(e.target.value, index, 'service_id')
                                                        }
                                                        className='form-select'
                                                    >
                                                        <option hidden>Select</option>
                                                        {serviceData?.services?.map((items) => {
                                                            return (
                                                                <option key={items.id} value={items.id}>{items.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <p className='bbClass' style={{ fontSize: "15px", background: "white", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{selectedService?.description ? selectedService?.description : '-'}</p>
                                                </td>
                                               {obj?.whichtax == 'Out of Scope of Tax' ? "" :  <td style={{ fontSize: "13px" }}>
                                                    {obj.sale_receipt_service[index].extax === null ? (
                                                        <select
                                                            value={item.tax_id}
                                                            onChange={(e) => handleChangeService(e.target.value, index, 'tax_id')}
                                                            disabled={obj?.sale_receipt_service?.length && obj?.sale_receipt_service[index].quantity ? false : true}
                                                            className='form-select'
                                                        >
                                                            <option hidden>Select</option>
                                                            {salesPurchase?.sale_tax?.map((i) => (
                                                                <option key={i.id} value={i.id}>{i.name} ({i.rate}%)</option>
                                                            ))}
                                                        </select>
                                                    ) : (
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            value={item.extax}
                                                            disabled={obj?.sale_receipt_service?.length && obj?.sale_receipt_service[index].quantity ? false : true}
                                                            onChange={(e) => handleChangeService(e.target.value, index, 'extax')}
                                                            placeholder="Exclusive Tax"
                                                        />
                                                    )}
                                                </td>}
                                                <td style={{ fontSize: "13px" }}>
                                                    <input
                                                        type="number"
                                                        className="form-control w-75"
                                                        placeholder="Qty"
                                                        value={item.quantity}
                                                        style={{ fontSize: "13px" }}
                                                        onChange={(e) =>
                                                            handleChangeService(e.target.value, index, 'quantity')
                                                        }
                                                    />
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <p className='bbClass' style={{ fontSize: "13px", background: "white", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{selectedService?.sale_price ? selectedService?.sale_price : '-'}</p>
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <p className='bbClass' style={{ fontSize: "13px", background: "white", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{Math.round(item.total_price)}</p>
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <button className='p-1 m-2 btn btn-danger' onClick={() => handleDeleteRow(index)}><RxCross2 /></button>
                                                </td>
                                            </tr>
                                        );
                                    })}


                                </tbody>
                            </table>
                            <div className="text-end">
                                <button className="btn btn-primary" onClick={handleAddRow}>
                                    <FaPlus />
                                </button>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "20px", justifyContent: "end" }}>
                            <div>
                                <div>
                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Sub Total Amount</p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: "bold", fontSize: "15px", display: "flex", gap: "10px" }}> Discount Amount(%) <input type='number' placeholder='%' min={0} className='w-50 form-control' style={{ height: "25px" }} value={obj?.discount} onChange={(e) => setObj({ ...obj, discount: e.target.value })} /></p>
                                </div>
                                <div>
                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Total Amount</p>
                                </div>
                            </div>
                            <div>
                                <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                    <p className="text-black float-start"><span className="text-black me-3">{Math.round(obj.sub_total_amount)}</span></p>
                                </div>
                                <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                    <p className="text-black float-start"><span className="text-black me-3">{Math.round(obj.total_discount)}</span></p>
                                </div>
                                <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                    <p className="text-black float-start"><span className="text-black me-3">{Math.round(obj.total_amount)}</span></p>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Message:</h4>
                                <textarea placeholder='Message' className='form-control' value={obj?.message} onChange={(e) => setObj({ ...obj, message: e.target.value })}></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Description:</h4>
                                <textarea placeholder='Description' className='form-control' value={obj?.description} onChange={(e) => setObj({ ...obj, description: e.target.value })}></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Attachment:</h4>
                                <input className='form-control' type='file' onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CustomerSalesReciptEdit
