import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
function CreateQuartlyBudget() {
    const navigate = useNavigate()
    const { getFinencialYears, finencialYears, getClientData, clientData, company, getCompnayCrud, storeBudgetQuarterly, getBudgetAcData, budgetAcData } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
        getFinencialYears()
    }, [])
    const [showNestedTables, setShowNestedTables] = useState({
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalIncome1, setTotalIncome1] = useState(0);
    const [totalIncome2, setTotalIncome2] = useState(0);
    const [totalIncome3, setTotalIncome3] = useState(0);
    const [totalIncome4, setTotalIncome4] = useState(0);

    const [quartlyBudget, setQuartylyBudget] = useState({
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Quarterly',
        pre_fill: '',
        subdivided: '',
        total_amount: '',
        client_id: '',
        quartery_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.id,
                jan_to_mar: 0,
                apr_to_jun: 0,
                july_to_sep: 0,
                oct_to_dec: 0,
                total_price: 0,
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                jan_to_mar: 0,
                apr_to_jun: 0,
                july_to_sep: 0,
                oct_to_dec: 0,
                total_price: 0,
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                jan_to_mar: 0,
                apr_to_jun: 0,
                july_to_sep: 0,
                oct_to_dec: 0,
                total_price: 0,
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                jan_to_mar: 0,
                apr_to_jun: 0,
                july_to_sep: 0,
                oct_to_dec: 0,
                total_price: 0,
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                jan_to_mar: 0,
                apr_to_jun: 0,
                july_to_sep: 0,
                oct_to_dec: 0,
                total_price: 0,
            })),
        }
    });
    const calculateTotalIncome = (month) => {
        const total = quartlyBudget?.quartery_budget?.income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalIncome1 = (month) => {
        const total = quartlyBudget?.quartery_budget?.other_income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum = () => {
        const totalSum = quartlyBudget?.quartery_budget?.income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalSum1 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.other_income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome2 = (month) => {
        const total = quartlyBudget?.quartery_budget?.expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum2 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome3 = (month) => {
        const total = quartlyBudget?.quartery_budget?.cost_of_sale?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum3 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.cost_of_sale?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome4 = (month) => {
        const total = quartlyBudget?.quartery_budget?.other_expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum4 = () => {
        const totalSum = quartlyBudget?.quartery_budget?.other_expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };

    useEffect(() => {
        const calculatedTotalIncome = quartlyBudget?.quartery_budget?.income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome(() => calculatedTotalIncome);

        const calculatedTotalIncome1 = quartlyBudget?.quartery_budget?.other_income?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome1(() => calculatedTotalIncome1);

        const calculatedTotalIncome2 = quartlyBudget?.quartery_budget?.expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome2(() => calculatedTotalIncome2);

        const calculatedTotalIncome3 = quartlyBudget?.quartery_budget?.other_expenses?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome3(() => calculatedTotalIncome3);

        const calculatedTotalIncome4 = quartlyBudget?.quartery_budget?.cost_of_sale?.reduce(
            (sum, income) => sum + parseFloat(income?.jan_to_mar || 0) + parseFloat(income?.apr_to_jun || 0) + parseFloat(income?.july_to_sep || 0) + parseFloat(income?.oct_to_dec || 0),
            0
        );
        setTotalIncome4(() => calculatedTotalIncome4);

    }, [quartlyBudget?.quartery_budget?.income, quartlyBudget?.quartery_budget?.other_income, quartlyBudget?.quartery_budget?.expenses, quartlyBudget?.quartery_budget?.other_expenses, quartlyBudget?.quartery_budget?.cost_of_sale]);

    const updateTotalAmount = () => {
        const totalSum =
            parseFloat(calculateTotalSum() || 0) +
            parseFloat(calculateTotalSum1() || 0) +
            parseFloat(calculateTotalSum2() || 0) +
            parseFloat(calculateTotalSum3() || 0) +
            parseFloat(calculateTotalSum4() || 0);

        setQuartylyBudget(prevState => ({
            ...prevState,
            total_amount: totalSum?.toFixed(0),
        }));
    };
    useEffect(() => {
        updateTotalAmount();
    }, [
        quartlyBudget?.quartery_budget?.income,
        quartlyBudget?.quartery_budget?.other_income,
        quartlyBudget?.quartery_budget?.expenses,
        quartlyBudget?.quartery_budget?.cost_of_sale,
        quartlyBudget?.quartery_budget?.other_expenses,
    ]);
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleSubmit = async () => {
        if (quartlyBudget?.name?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Name is required.');
        } else if (quartlyBudget?.company_id?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (quartlyBudget?.finencial_year?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Finencial Year is required.');
        } else if (quartlyBudget?.pre_fill?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Pre-fill data is required.');
        } else if (quartlyBudget?.subdivided?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Sub division is required.');
        } else {
            try {
                await storeBudgetQuarterly(quartlyBudget)
                navigate('/admin/budget');
            } catch (error) {
                console.error('Error submitting budget:', error);
            }
        }
    };
    const handleSave = () => {
        if (quartlyBudget?.name?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Name is required.');
        } else if (quartlyBudget?.company_id?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (quartlyBudget?.finencial_year?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Finencial Year is required.');
        } else if (quartlyBudget?.pre_fill?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Pre-fill data is required.');
        } else if (quartlyBudget?.subdivided?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Sub division is required.');
        } else {
            storeBudgetQuarterly(quartlyBudget)
        }
    };
    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const handleClose = () => {
        navigate('/admin/budget');
    }
    return (
        <>
            <div className='createBudgetData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Name<span style={{ color: "red" }}>*</span></label>
                        <input type="text" className='form-control' value={quartlyBudget?.name} onChange={(e) => setQuartylyBudget({ ...quartlyBudget, name: e.target.value })} placeholder='Name' />
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Company<span style={{ color: "red" }}>*</span></label>
                        <select className='form-control' value={quartlyBudget?.company_id} onChange={(e) => setQuartylyBudget({ ...quartlyBudget, company_id: e.target.value })}>
                            <option hidden>Select</option>
                            {company?.data?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Financial Year<span style={{ color: "red" }}>*</span></label>
                        <select
                            className='form-control'
                            value={quartlyBudget.finencial_year}
                            onChange={(e) =>
                                setQuartylyBudget({ ...quartlyBudget, finencial_year: e.target.value })
                            }
                        >
                            <option hidden>Select</option>
                            {finencialYears?.previousFinancialYears?.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Pre-fill data?<span style={{ color: "red" }}>*</span></label>
                        <select className='form-control' value={quartlyBudget?.pre_fill} onChange={(e) => setQuartylyBudget({ ...quartlyBudget, pre_fill: e.target.value })}>
                            <option hidden>Select</option>
                            <option value='No'>No</option>
                            <option value='Actual Data 2022'>Actual Data 2022</option>
                            <option value='Actual Data 2023'>Actual Data 2023</option>
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Subdivide by<span style={{ color: "red" }}>*</span></label>
                        <select className='form-control' value={quartlyBudget?.subdivided} onChange={(e) => setQuartylyBudget({ ...quartlyBudget, subdivided: e.target.value })}>
                            <option hidden>Select</option>
                            <option value="Don't subdivide">Don't subdivide</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    {quartlyBudget?.subdivided === 'Customer' && <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Customer</label>
                        <select className='form-control' value={quartlyBudget?.client_id} onChange={(e) => setQuartylyBudget({ ...quartlyBudget, client_id: e.target.value })}>
                            <option hidden>Select</option>
                            {clientData?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>}
                </div>
                <hr />
                <div className='row'>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <table className='table table-hover table-bordered' style={{ width: "90rem" }}>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jan to Mar</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>April to Jun</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jul to Sep</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Oct to Dec</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.income?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.jan_to_mar || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_mar: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.apr_to_jun || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        apr_to_jun: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.july_to_sep || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july_to_sep: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.oct_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        oct_to_dec: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.other_income?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.jan_to_mar || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_mar: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.apr_to_jun || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        apr_to_jun: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.july_to_sep || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july_to_sep: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.oct_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        oct_to_dec: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome1(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome1}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Expance</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.expenses?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.jan_to_mar || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_mar: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.apr_to_jun || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        apr_to_jun: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.july_to_sep || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july_to_sep: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.oct_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        oct_to_dec: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expenses</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome2(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome2}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Expense</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[4] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.jan_to_mar || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_mar: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.apr_to_jun || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        apr_to_jun: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.july_to_sep || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july_to_sep: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.oct_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        oct_to_dec: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expance</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome4(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome3}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={5} />
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = quartlyBudget?.quartery_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.jan_to_mar || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        jan_to_mar: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.apr_to_jun || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        apr_to_jun: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.july_to_sep || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july_to_sep: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                min={0}
                                                                value={monthlyIncome?.oct_to_dec || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...quartlyBudget?.quartery_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        oct_to_dec: e.target.value,
                                                                    };
                                                                    setQuartylyBudget({
                                                                        ...quartlyBudget,
                                                                        quartery_budget: {
                                                                            ...quartlyBudget?.quartery_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total COS</th>
                                        {['jan_to_mar', 'apr_to_jun', 'july_to_sep', 'oct_to_dec'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome3(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{totalIncome4}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handleSubmit} className='btn btn-primary'>Save and Close</button>
                        <button className='btn btn-warning  mx-3' onClick={handleSave}>Save</button>
                        <button className='btn btn-mute' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}

export default CreateQuartlyBudget
