import React, { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Form } from 'antd';
import { CiEdit, CiTrash } from 'react-icons/ci';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { CiImport } from 'react-icons/ci'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useData } from '../../../ContextProvider/Context';
import { useEffect } from 'react';
import { RestaurantMenu } from '@material-ui/icons';
import { useReactToPrint } from 'react-to-print';
import { IoPrintOutline } from 'react-icons/io5'
import { MdAirplanemodeActive, MdAirplanemodeInactive } from 'react-icons/md';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '1rem',
    paddingTop: '2rem'
};
function ChartsOfAccounts() {
    const params = useParams()
    const { makeActiveAccount, makeInActiveAccount, getDat, settingDat, base_url, updateAccountsC, storeaccountsDataC, deleteAccountsC, setSnackbarValue, handleClick, getDydy, dydy, getAccountsData, accountsData, getAccountsTypes, accountsTypes, getAccountsTypesDetails, accountsTypesDetails, getAccountsDataActive, accountDataActive } = useData()
    useEffect(() => {
        getAccountsTypes()
        getAccountsData()
        getAccountsTypesDetails()
        getAccountsDataActive()
        getDydy()
        getDat()
    }, [])
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const [isSubAccount, setIsSubAccount] = useState(false);
    const handleCheckboxChange = () => {
        setIsSubAccount((prevIsSubAccount) => !prevIsSubAccount);
    };
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            name: '',
            description: '',
            company_id: '',
            account_no: '',
            type_id: '',
            subtype_id: '',
            tax_id: '',
            opening_balance: '',
            date: new Date().toISOString().split('T')[0],
            sub_account_id: ''
        })
        setOpen(false)
        setSelectedSubTypeDescription('')
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const handleDownloadCsv = () => {
        const visibleAccountsForExport = visibleAccountsData.map((item, idx) => ({
            index: indexOfFirstItem + idx + 1,
            name: item?.name,
            companyName: item?.company?.name,
            description: item.description,
            subtypeName: item?.account_subtype?.name,
            taxRate: `${item?.tax?.rate} %`,
            openingBalance: item?.opening_balance,
            date: item?.date,
        }));
        const csv = Papa.unparse(visibleAccountsForExport);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'chartsofaccounts.csv');
    };
    const [selectedSubType, setSelectedSubType] = useState('');
    const [selectedSubTypeDescription, setSelectedSubTypeDescription] = useState('');

    const handleSubTypeChange = (e) => {
        const selectedValue = e.target.value;
        setObj({ ...obj, subtype_id: parseInt(selectedValue, 10) })
        setSelectedSubType(selectedValue);
        const selectedSubTypeData = accountsTypesDetails?.sub_type.find(item => item.id === parseInt(selectedValue, 10));
        setSelectedSubTypeDescription(selectedSubTypeData?.description || '');
    };
    const [obj, setObj] = useState({
        name: '',
        description: '',
        account_no: '',
        company_id: '',
        type_id: '',
        subtype_id: '',
        tax_id: '',
        opening_balance: '',
        date: new Date().toISOString().split('T')[0],
        sub_account_id: ''
    })
    console.log(obj)
    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "sub_account_id" && key !== "account_no" && key !== "date" && key !== "tax_id" && key !== "opening_balance" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeaccountsDataC(obj);
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdate = () => {
        if (isFormValid()) {
            updateAccountsC(obj);
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const visibleAccountsData = params.id
        ? accountDataActive?.data?.filter(item => item?.company?.id == params.id) || []
        : accountDataActive?.data || [];
    const currentAccounts = visibleAccountsData?.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(visibleAccountsData?.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const [selectedDescription, setSelectedDescription] = useState('');
    const [showDescriptionOverlay, setShowDescriptionOverlay] = useState(false);
    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
    });
    const [selectedImage, setSelectedImage] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(",")[1];
                csvToJSON(parts)

            };
            reader.readAsDataURL(file);
        }
    };
    const csvToJSON = (csvData) => {
        fetch(`${base_url}importaccount`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',

            },
            body: JSON.stringify({ file: csvData }),
        })
            .then((response) => response.json())
            .then((data) => {
                getAccountsDataActive()
                handleClick();
                setSnackbarValue({ message: "File Add Succssfully", color: "#2ECC71" });
            })
            .catch((error) => {
                console.log('Error:', error)
            });

    };

    const downloadDemo = () => {
        const dataDemo = [{
            type: 'Any',
            detail_type: ' type',
            name: 'Account name',
            description: 'Description test',
            tax_rate: 9,
            company: "WSO",
            sub_account: "account name",
            opening_balance: 90,
            date: 2023 - 11 - 11,
        }]

        const csv = Papa.unparse(dataDemo);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'accounts.csv');
        handleClose1()
    }
    return (
        <>
            <div className='ChartsOfAccountsData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Charts of Accounts</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='d-flex justify-content-end mb-2 gap-2 align-items-end'>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div>
                                <label style={{ fontSize: "10px", fontWeight: "bold" }}>Company</label>
                                <select disabled={params.id ? true : false} style={{ height: "36px" }} className='form-select' >
                                    <option value="">Select a company</option>
                                    {filteredCompanies?.map((item) => (
                                        <option key={item.id} value={item.name}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <button className='btn btn-primary' onClick={handleOpen}>Add</button>
                        </div>
                        <div>
                            <button className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><Link to='/admin/runreport' style={{ textDecoration: "none", color: "inherit" }} >Run Report</Link></button>
                        </div>
                        {/* <div>
                            <button onClick={handlePrint} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Print</button>
                        </div> */}
                        <div>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                aria-controls={open1 ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                                onClick={handleClick1}
                            >
                                Import Charts
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={downloadDemo}>Download Demo</MenuItem>
                                <MenuItem onClick={handleClose1}> <label for="csv_file" >Import File</label></MenuItem>
                            </Menu>
                            <input type='file' accept=".csv" onChange={handleFileChange} style={{ display: "none" }} id='csv_file' />
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className='scrollBar' style={{ height: "500px", overflowY: "scroll" }}>
                                <Form name="add_designation">
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="company">Account Type</label>
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                    onChange={(e) => {
                                                        getAccountsTypesDetails(e.target.value)
                                                        setObj({ ...obj, type_id: e.target.value })
                                                    }}
                                                    value={obj?.type_id}
                                                >
                                                    <option hidden>Select Account Type</option>
                                                    {accountsTypes?.map((item) => {
                                                        return (
                                                            <option value={item.id}>{item?.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="companyName"> Name</label>
                                                <input
                                                    type='text'
                                                    id="branch"
                                                    label="Branch"
                                                    variant="standard"
                                                    placeholder='Name'
                                                    className='form-control'
                                                    value={obj?.name}
                                                    onChange={(e) => setObj({ ...obj, name: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="company">Detail Type</label>
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                    onChange={handleSubTypeChange}
                                                    value={obj?.subtype_id}
                                                >
                                                    <option hidden>Select Detail Type</option>
                                                    {accountsTypesDetails?.sub_type?.map((item, index) => (
                                                        <option key={index} value={item?.id}>
                                                            {item?.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="company">Company</label>
                                                <select disabled={params.id ? true : false} value={obj?.company_id} style={{ height: "36px" }} className='form-select' onChange={(e) => setObj({ ...obj, company_id: e.target.value })} >
                                                    <option value="">Select a company</option>
                                                    {filteredCompanies?.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div style={{ cursor: "not-allowed", border: "1px solid lightgray", height: "200px", width: "100%", overflowY: "scroll", background: "#eee" }} className='scrollBar descrUl'>
                                                <p style={{ padding: "0" }} dangerouslySetInnerHTML={{ __html: selectedSubTypeDescription }} />
                                            </div>
                                        </div>
                                        {settingDat?.data?.account_number_show === 1 && <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label>Account Number</label>
                                                <input
                                                    type='number'
                                                    id="acountNumber"
                                                    className='form-control'
                                                    placeholder='112233-445566-77'
                                                    value={obj?.account_no}
                                                    onChange={(e) => setObj({ ...obj, account_no: e.target.value })}
                                                />
                                            </div>
                                        </div>}
                                        <div className="col-md-6 mb-2">
                                            <div className='mb-2'>
                                                <input
                                                    type='checkbox'
                                                    checked={isSubAccount}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label>is sub-account</label>
                                                <br />
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                    disabled={!isSubAccount}
                                                    onChange={(e) => setObj({ ...obj, sub_account_id: e.target.value })}
                                                    value={obj?.sub_account_id}
                                                >
                                                    <option hidden>Enter Parent Account</option>
                                                    {dydy?.account?.map((item) => {
                                                        return (
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            <div className='mb-2'>
                                                <label>Tax</label>
                                                <select
                                                    id="company"
                                                    style={{ width: '100%' }}
                                                    className='form-select'
                                                    value={obj?.tax_id}
                                                    onChange={(e) => setObj({ ...obj, tax_id: e.target.value })}
                                                >
                                                    <option hidden>Select</option>
                                                    {dydy?.tax?.map((item) => {
                                                        return (
                                                            <option value={item.id}> {item?.account_tax?.name} ({item.name} ({item.rate}%))</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                            {
                                                !(obj?.type_id === '13' || obj?.type_id === '14' || obj?.type_id === '3' || obj?.type_id === '8' || obj?.type_id === '15' || obj?.type_id === '16' || obj?.type_id === '17') && (
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label htmlFor="companyName">
                                                                    {obj?.type_id === '10' || obj?.type_id === '11' || obj?.type_id === '12' ? (
                                                                        'Unpaid balance'
                                                                    ) : (
                                                                        'Balance'
                                                                    )}
                                                                </label>
                                                                <input
                                                                    type='number'
                                                                    id="branch"
                                                                    placeholder='Openinig Balance'
                                                                    label="Branch"
                                                                    variant="standard"
                                                                    className='form-control'
                                                                    value={obj?.opening_balance}
                                                                    onChange={(e) => setObj({ ...obj, opening_balance: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className="form-group">
                                                                <label htmlFor="companyName">as of</label>
                                                                <input
                                                                    type='date'
                                                                    id="branch"
                                                                    label="Branch"
                                                                    variant="standard"
                                                                    className='form-control'
                                                                    value={obj?.date}
                                                                    onChange={(e) => setObj({ ...obj, date: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="companyName">Description</label>
                                                <input
                                                    type='text'
                                                    id="branch"
                                                    label="Branch"
                                                    variant="standard"
                                                    className='form-control'
                                                    placeholder='e.g 100 letters only'
                                                    value={obj?.description}
                                                    onChange={(e) => {
                                                        const inputDescription = e.target.value;
                                                        if (inputDescription.length <= 100) {
                                                            setObj({ ...obj, description: inputDescription });
                                                        } else {
                                                            setObj({ ...obj, description: inputDescription.substring(0, 100) });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Form.Item>
                                        <button className='btn btn-primary mt-3' type="primary" htmlType="submit" onClick={() => {
                                            obj?.id ? handleUpdate() : handleSubmit()
                                        }}>
                                            {obj?.id ? 'Update' : "Submit"}
                                        </button>
                                        <button className='btn btn-mute' onClick={handleClose}>Cancel</button>
                                    </Form.Item>
                                </Form>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div>
                        <div className='card'>
                            <div className="card-body table-responsive recruitment">
                                <table className="table" ref={contentRef}>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            {settingDat?.data?.account_number_show === 1 && <th scope="col">Account No</th>}
                                            <th scope="col">Company</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Detail Type</th>
                                            <th scope="col">Tax (%)</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {params?.id ? currentAccounts?.map((item, idx) => {
                                            const truncatedDescription = item.description?.slice(0, 20);
                                            const isDescriptionTruncated = item.description?.length > 20;

                                            return (
                                                <>
                                                    {item?.company?.id == params?.id && <tr key={idx}>
                                                        <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                        <td>{item?.name}</td>
                                                        {settingDat?.data?.account_number_show === 1 && <td>{item?.account_no}</td>}
                                                        <td>{item?.company?.name}</td>
                                                        <td>
                                                            {isDescriptionTruncated ? (
                                                                <div
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setSelectedDescription(item.description);
                                                                        setShowDescriptionOverlay(true);
                                                                    }}
                                                                >
                                                                    {truncatedDescription}...
                                                                </div>
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </td>
                                                        <td>{item?.account_subtype?.name}</td>
                                                        <td>{item?.tax?.rate} %</td>
                                                        <td>{item?.opening_balance}</td>
                                                        <td>{item?.date}</td>
                                                        <td>
                                                            {item.deleteStatus === 1 ? (
                                                                ''
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        className="btn p-1 m-2 btn-primary"
                                                                        onClick={() => {
                                                                            handleOpen();
                                                                            setObj({
                                                                                name: item?.name,
                                                                                description: item?.description,
                                                                                company_id: item?.company_id,
                                                                                type_id: item?.type_id,
                                                                                subtype_id: item?.account_subtype?.id,
                                                                                tax_id: item?.tax_id,
                                                                                opening_balance: item?.opening_balance,
                                                                                date: item?.date,
                                                                                sub_account_id: item?.sub_account_id
                                                                            });
                                                                        }}
                                                                    >
                                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                                    </button>
                                                                    <button
                                                                        className="btn p-1 m-2 btn-danger"
                                                                        onClick={() => deleteAccountsC(item.id)}
                                                                    >
                                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                                    </button>
                                                                </>
                                                            )}
                                                            {item.deleteStatus === 1 ? (
                                                                <button
                                                                    className="btn p-1 m-2 btn-success"
                                                                    onClick={() => makeActiveAccount(item.id)}
                                                                >
                                                                    <MdAirplanemodeActive style={{ fontSize: "20px" }} />
                                                                </button>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.deleteStatus === 0 ? (
                                                                <button
                                                                    className="btn p-1 m-2 btn-warning"
                                                                    onClick={() => makeInActiveAccount(item.id)}
                                                                >
                                                                    <MdAirplanemodeInactive style={{ fontSize: "20px" }} />
                                                                </button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </td>
                                                    </tr>}
                                                </>
                                            );
                                        }) : currentAccounts?.map((item, idx) => {
                                            const truncatedDescription = item.description?.slice(0, 20);
                                            const isDescriptionTruncated = item.description?.length > 20;

                                            return (
                                                <tr key={idx}>
                                                    <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                    <td>{item?.name}</td>
                                                    {settingDat?.data?.account_number_show === 1 && <td>{item?.account_no}</td>}
                                                    <td>{item?.company?.name}</td>
                                                    <td>
                                                        {isDescriptionTruncated ? (
                                                            <div
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedDescription(item.description);
                                                                    setShowDescriptionOverlay(true);
                                                                }}
                                                            >
                                                                {truncatedDescription}...
                                                            </div>
                                                        ) : (
                                                            item.description
                                                        )}
                                                    </td>
                                                    <td>{item?.account_subtype?.name}</td>
                                                    <td>{item?.tax?.rate} %</td>
                                                    <td>{item?.opening_balance}</td>
                                                    <td>{item?.date}</td>
                                                    <td>
                                                        {item.deleteStatus === 1 ? (
                                                            ''
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn p-1 m-2 btn-primary"
                                                                    onClick={() => {
                                                                        handleOpen();
                                                                        setObj({
                                                                            name: item?.name,
                                                                            description: item?.description,
                                                                            company_id: item?.company_id,
                                                                            type_id: item?.type_id,
                                                                            subtype_id: item?.account_subtype?.id,
                                                                            tax_id: item?.tax_id,
                                                                            opening_balance: item?.opening_balance,
                                                                            date: item?.date,
                                                                            sub_account_id: item?.sub_account_id
                                                                        });
                                                                    }}
                                                                >
                                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                                </button>
                                                                <button
                                                                    className="btn p-1 m-2 btn-danger"
                                                                    onClick={() => deleteAccountsC(item.id)}
                                                                >
                                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                                </button>
                                                            </>
                                                        )}
                                                        {item.deleteStatus === 1 ? (
                                                            <button
                                                                className="btn p-1 m-2 btn-success"
                                                                onClick={() => makeActiveAccount(item.id)}
                                                            >
                                                                <MdAirplanemodeActive style={{ fontSize: "20px" }} />
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {item.deleteStatus === 0 ? (
                                                            <button
                                                                className="btn p-1 m-2 btn-warning"
                                                                onClick={() => makeInActiveAccount(item.id)}
                                                            >
                                                                <MdAirplanemodeInactive style={{ fontSize: "20px" }} />
                                                            </button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                    {currentAccounts?.length === 0 && (
                                        <tr>
                                            <td colSpan="9" className="text-center">
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </table>
                                <div>
                                    {paginationControls.map((button, index) => (
                                        <React.Fragment key={index}>{button}</React.Fragment>
                                    ))}
                                </div>
                            </div>
                            {showDescriptionOverlay && (
                                <div className="overlayy" style={{ background: "rgba(0,0,0,1)", display: "flex", justifyContent: "center", alignItems: "center", overflowY: "scroll" }} onClick={() => setShowDescriptionOverlay(false)}>
                                    <div className="full-description-modal" style={{ height: "600px", width: "80%" }}>
                                        <p style={{ color: "white" }}>{selectedDescription}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ChartsOfAccounts
