import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
function CreateBudget() {
    const navigate = useNavigate()
    const { getFinencialYears, finencialYears, getClientData, clientData, company, getCompnayCrud, storeBudgetMontly, getBudgetAcData, budgetAcData, storeBudgetYearly } = useData()
    useEffect(() => {
        getBudgetAcData()
        getCompnayCrud()
        getClientData()
        getFinencialYears()
    }, [])
    const [showNestedTables, setShowNestedTables] = useState({
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
    });

    const toggleNestedTable = (rowNumber) => {
        setShowNestedTables((prev) => ({
            ...prev,
            [rowNumber]: !prev[rowNumber],
        }));
    };

    const [monthlyBudget, setMonthlyBudget] = useState({
        company_id: '',
        name: '',
        finencial_year: '',
        interval: 'Monthly',
        pre_fill: '',
        subdivided: '',
        total_amount: '',
        client_id: '',
        monthly_budget: {
            income: budgetAcData?.income?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            other_income: budgetAcData?.other_income?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            cost_of_sale: budgetAcData?.cost_of_sale?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            expenses: budgetAcData?.expenses?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
            other_expenses: budgetAcData?.other_expenses?.map((item) => ({
                account_id: item.id,
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
                total_price: 0,
            })),
        }
    });
    const calculateTotalIncome = (month) => {
        const total = monthlyBudget?.monthly_budget?.income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalIncome1 = (month) => {
        const total = monthlyBudget?.monthly_budget?.other_income?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum = () => {
        const totalSum = monthlyBudget?.monthly_budget?.income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalSum1 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.other_income?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome2 = (month) => {
        const total = monthlyBudget?.monthly_budget?.expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum2 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome3 = (month) => {
        const total = monthlyBudget?.monthly_budget?.cost_of_sale?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum3 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.cost_of_sale?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const calculateTotalIncome4 = (month) => {
        const total = monthlyBudget?.monthly_budget?.other_expenses?.reduce((sum, item) => sum + parseFloat(item[month] || 0), 0);
        return total?.toFixed(0);
    };
    const calculateTotalSum4 = () => {
        const totalSum = monthlyBudget?.monthly_budget?.other_expenses?.reduce(
            (sum, item) => sum + parseFloat(item.total_price || 0),
            0
        );
        return totalSum?.toFixed(0);
    };
    const updateTotalAmount = () => {
        const totalSum =
            parseFloat(calculateTotalSum() || 0) +
            parseFloat(calculateTotalSum1() || 0) +
            parseFloat(calculateTotalSum2() || 0) +
            parseFloat(calculateTotalSum3() || 0) +
            parseFloat(calculateTotalSum4() || 0);

        setMonthlyBudget(prevState => ({
            ...prevState,
            total_amount: totalSum?.toFixed(0),
        }));
    };
    useEffect(() => {
        updateTotalAmount();
    }, [
        monthlyBudget?.monthly_budget?.income,
        monthlyBudget?.monthly_budget?.other_income,
        monthlyBudget?.monthly_budget?.expenses,
        monthlyBudget?.monthly_budget?.cost_of_sale,
        monthlyBudget?.monthly_budget?.other_expenses,
    ]);
    const state = {
        open: false,
        vertical: 'bottom',
        horizontal: 'right',
    }
    const { vertical, horizontal } = state;
    const [anyFieldEmpty, setAnyFieldEmpty] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const handleSubmit = async () => {
        if (monthlyBudget?.name?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Name is required.');
        } else if (monthlyBudget?.company_id?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (monthlyBudget?.finencial_year?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Finencial Year is required.');
        } else if (monthlyBudget?.pre_fill?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Pre-fill data is required.');
        } else if (monthlyBudget?.subdivided?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Sub division is required.');
        } else {
            try {
                await storeBudgetMontly(monthlyBudget)
                navigate('/admin/budget');
            } catch (error) {
                console.error('Error submitting budget:', error);
            }
        }
    };
    const handleSave = () => {
        if (monthlyBudget?.name?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Name is required.');
        } else if (monthlyBudget?.company_id?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Company is required.');
        } else if (monthlyBudget?.finencial_year?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Finencial Year is required.');
        } else if (monthlyBudget?.pre_fill?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Pre-fill data is required.');
        } else if (monthlyBudget?.subdivided?.trim() === '') {
            setAnyFieldEmpty(true);
            setSnackbarMessage('Sub division is required.');
        } else {
            storeBudgetMontly(monthlyBudget)
        }
    };
    const handleCloseSnackbar = () => {
        setAnyFieldEmpty(false);
    };
    const handleClose = () => {
        navigate('/admin/budget');
    }
    console.log(monthlyBudget)
    return (
        <>
            <div className='createBudgetData'>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Name<span style={{ color: "red" }}>*</span></label>
                        <input type="text" className='form-control' value={monthlyBudget?.name} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, name: e.target.value })} placeholder='Name' />
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Company<span style={{ color: "red" }}>*</span></label>
                        <select className='form-control' value={monthlyBudget?.company_id} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, company_id: e.target.value })}>
                            <option hidden>Select</option>
                            {company?.data?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Financial Year<span style={{ color: "red" }}>*</span></label>
                        <select
                            className='form-control'
                            value={monthlyBudget.finencial_year}
                            onChange={(e) =>
                                setMonthlyBudget({ ...monthlyBudget, finencial_year: e.target.value })
                            }
                        >
                            <option hidden>Select</option>
                            {finencialYears?.previousFinancialYears?.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Pre-fill data?<span style={{ color: "red" }}>*</span></label>
                        <select className='form-control' value={monthlyBudget?.pre_fill} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, pre_fill: e.target.value })}>
                            <option hidden>Select</option>
                            <option value='No'>No</option>
                            <option value='Actual Data 2022'>Actual Data 2022</option>
                            <option value='Actual Data 2023'>Actual Data 2023</option>
                        </select>
                    </div>
                    <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Subdivide by<span style={{ color: "red" }}>*</span></label>
                        <select className='form-control' value={monthlyBudget?.subdivided} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, subdivided: e.target.value })}>
                            <option hidden>Select</option>
                            <option value="Don't subdivide">Don't subdivide</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </div>
                    {monthlyBudget?.subdivided === 'Customer' && <div>
                        <label style={{ fontSize: "'14'px", fontWeight: "bold" }}>Customer</label>
                        <select className='form-control' value={monthlyBudget?.client_id} onChange={(e) => setMonthlyBudget({ ...monthlyBudget, client_id: e.target.value })}>
                            <option hidden>Select</option>
                            {clientData?.map((item) => {
                                return (
                                    <option value={item.id}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>}
                </div>
                <hr />
                <div className='row mb-5'>
                    <div className='card'>
                        <div className='card-body table-responsive recruitment'>
                            <table className='table table-hover table-bordered' style={{ width: "100rem" }}>
                                <thead style={{ background: "#f3f5f8", color: "black", fontWeight: "bold" }}>
                                    <tr>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Account</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jan</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Feb</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Mar</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Apr</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>May</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jun</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Jul</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Aug</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Sep</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Oct</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Nov</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Dec</th>
                                        <th style={{ padding: "1rem .5rem", whiteSpace: "nowrap" }}>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td>
                                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                <p style={{ marginBottom: "0" }}>Income</p>
                                                <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(1)}>
                                                    {showNestedTables[1] ? <FaChevronUp /> : <FaChevronDown />}
                                                </button>
                                            </div>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[1] && (
                                        <>
                                            {budgetAcData?.income?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.income?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Income</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(2)}>
                                                {showNestedTables[2] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[2] && (
                                        <>
                                            {budgetAcData?.other_income?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.other_income?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_income || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_income: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Income</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome1(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum1()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Expenses</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(3)}>
                                                {showNestedTables[3] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[3] && (
                                        <>
                                            {budgetAcData?.expenses?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.expenses?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Expenses</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome2(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum2()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Other Expenses</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(4)}>
                                                {showNestedTables[4] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[4] && (
                                        <>
                                            {budgetAcData?.other_expenses?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.other_expenses?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.other_expenses || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            other_expenses: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total Other Expenses</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome4(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum4()}</p>
                                        </td>
                                    </tr>
                                    <tr style={{ background: "#f0f2f6", color: "black", fontWeight: "bold" }}>
                                        <td colSpan={'14'} style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                            <p style={{ marginBottom: "0" }}>Cost of Sales</p>
                                            <button style={{ background: "transparent", border: "0" }} onClick={() => toggleNestedTable(5)}>
                                                {showNestedTables[5] ? <FaChevronUp /> : <FaChevronDown />}
                                            </button>
                                        </td>
                                        <td colSpan={13} />
                                    </tr>
                                    {showNestedTables[5] && (
                                        <>
                                            {budgetAcData?.cost_of_sale?.map((item, index) => {
                                                const monthlyIncome = monthlyBudget?.monthly_budget?.cost_of_sale?.[index] || {};
                                                const totalIncome = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december']
                                                    .reduce((sum, month) => sum + parseFloat(monthlyIncome[month] || 0), 0);
                                                monthlyIncome.total_price = totalIncome;
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item?.name}</td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.january || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        january: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.february || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        february: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.march || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        march: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.april || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        april: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.may || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        may: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.june || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        june: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.july || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        july: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.august || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        august: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.september || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        september: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.october || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        october: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.november || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        november: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type='number'
                                                                value={monthlyIncome?.december || ''}
                                                                onChange={(e) => {
                                                                    const updatedIncome = [...monthlyBudget?.monthly_budget?.cost_of_sale || []];
                                                                    updatedIncome[index] = {
                                                                        ...monthlyIncome,
                                                                        december: e.target.value,
                                                                    };
                                                                    setMonthlyBudget({
                                                                        ...monthlyBudget,
                                                                        monthly_budget: {
                                                                            ...monthlyBudget?.monthly_budget,
                                                                            cost_of_sale: updatedIncome,
                                                                        },
                                                                    });
                                                                }}
                                                                className='form-control tdOutlineN'
                                                                style={{ border: "none" }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <p style={{ textAlign: "right", fontWeight: "bold" }}>{totalIncome}</p>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                    <tr>
                                        <th>Total COS</th>
                                        {['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'].map((month) => (
                                            <td key={month}>
                                                <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalIncome3(month)}</p>
                                            </td>
                                        ))}
                                        <td>
                                            <p style={{ textAlign: "center", fontWeight: "bold" }}>{calculateTotalSum3()}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{ background: "white", padding: "1rem", position: "fixed", width: "100%", bottom: "0", left: "0", display: "flex", justifyContent: "end", alignItems: "center" }}>
                    <div>
                        <button onClick={handleSubmit} className='btn btn-primary'>Save and Close</button>
                        <button className='btn btn-warning mx-3' onClick={handleSave}>Save</button>
                        <button className='btn btn-mute' onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
            <Snackbar
                open={anyFieldEmpty}
                autoHideDuration={2000}
                anchorOrigin={{ vertical, horizontal }}
                key={vertical + horizontal}
            >
                <Alert
                    severity="error"
                    style={{
                        backgroundColor: '#8f2018',
                        color: "white",
                    }}
                    onClose={handleCloseSnackbar}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    )
}

export default CreateBudget
