import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context'
import { Box, Button, Menu, MenuItem, Modal, Typography } from '@mui/material'
import { CiEdit, CiTrash, CiImport } from 'react-icons/ci'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { Link, useParams } from 'react-router-dom'
import { LuImagePlus } from 'react-icons/lu'
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

function Vendor1() {
    const params = useParams()
    const { img_url, base_url, getVendorFilterCom, accountsData, getAccountsData, handleClick, setSnackbarValue, vendorData, getVendorData, storeVendorData, updateVendorData, deleteVendorData, getFilterVendor } = useData()
    useEffect(() => {
        getVendorData()
        getAccountsData()
    }, [])
    const [selectedImage, setSelectedImage] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open1 = Boolean(anchorEl);
    const handleClick1 = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl(null);
    };
    const [selectedCompany, setSelectedCompany] = useState(null);
    const filteredCompanies = accountsData?.company?.filter(item => item.deleteStatus === 0);
    const [name, setName] = useState({
        name: ''
    })
    const [obj, setObj] = useState(
        {
            companyId: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            ship_address: '',
            ship_city: '',
            ship_province: '',
            ship_country: '',
            ship_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: new Date().toISOString().split('T')[0],
            website: '',
            fax: '',
            phone: '',
            profile: '',
            sameAddress: true,
            opening_balance: ''
        }
    )
    const handleCheckboxChange = () => {
        setObj((prevObj) => {
            const updatedObj = {
                ...prevObj,
                sameAddress: !prevObj.sameAddress,
            };
            if (updatedObj.sameAddress) {
                updatedObj.ship_address = prevObj.bill_address;
                updatedObj.ship_city = prevObj.bill_city;
                updatedObj.ship_province = prevObj.bill_province;
                updatedObj.ship_country = prevObj.bill_country;
                updatedObj.ship_postal = prevObj.bill_postal;
            }

            return updatedObj;
        });
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setObj({
            companyId: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            ship_address: '',
            ship_city: '',
            ship_province: '',
            ship_country: '',
            ship_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: new Date().toISOString().split('T')[0],
            website: '',
            fax: '',
            phone: '',
            profile: '',
            sameAddress: false,
            opening_balance: ''
        });
        setOpen(false)
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(',');
                const base64Data = parts[1];

                setObj({
                    ...obj,
                    attachment: base64Data,
                });
            };

            reader.readAsDataURL(file);
            if (file.type.startsWith('image/')) {
            } else {
            }
        }
    };
    const clearFields = () => {
        setObj({
            companyId: params.id ? params.id : selectedCompany,
            name: '',
            email: '',
            mobile: '',
            other: '',
            bill_address: '',
            bill_city: '',
            bill_province: '',
            bill_country: '',
            bill_postal: '',
            ship_address: '',
            ship_city: '',
            ship_province: '',
            ship_country: '',
            ship_postal: '',
            note: '',
            attachment: '',
            payment_method: '',
            sales: '',
            language: '',
            term: '',
            tax: '',
            date: '',
        });
    };
    const isFormValid = () => {
        for (const key in obj) {
            if (key !== "ship_address" && key !== "ship_city" && key !== "ship_province" && key !== "ship_country" && key !== "ship_postal" && key !== "note" && key !== "attachment" && key !== "payment_method" && key !== "sales" && key !== "language" && key !== "term" && key !== "tax" && key !== "date" && key !== "fax" && key !== "website" && key !== "phone" && obj[key] === '') {
                return false;
            }
        }
        return true;
    };
    const handleSubmit = () => {
        if (isFormValid()) {
            storeVendorData(obj);
            clearFields();
            handleClose()
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }
    const handleUpdateSubmit = () => {
        if (isFormValid()) {
            handleClose()
            updateVendorData(obj.id, obj)
        } else {
            handleClick()
            setSnackbarValue({ message: "Some fields are missing. Please fill out all the required fields.", color: "#DC4C64" });
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const visibleVendor = params.id
        ? vendorData?.filter(item => item?.company?.id == params.id) || []
        : vendorData || [];

    const currentVendor = visibleVendor?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(visibleVendor.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    const handleProfileImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {

                setObj({
                    ...obj,
                    profile: reader.result,
                });
            };
            reader.readAsDataURL(file);
        }
    };
    const openMap = () => {
        const { bill_address, bill_city, bill_province, bill_postal, bill_country } = obj;
        const address = `${bill_address}, ${bill_city}, ${bill_province}, ${bill_postal}, ${bill_country}`;
        const mapLink = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
        window.open(mapLink, '_blank'); // Open the link in a new tab/window
    };
    const openMapForShipping = (addressObj) => {
        const { ship_address, ship_city, ship_province, ship_postal, ship_country } = addressObj;
        const address = `${ship_address}, ${ship_city}, ${ship_province}, ${ship_postal}, ${ship_country}`;
        const mapLink = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
        window.open(mapLink, '_blank'); // Open the link in a new tab or window
    };
    const downloadDemo = () => {
        const dataDemo = [{
            company: 'WSO',
            name: 'Happy',
            email: 'happy@gmail.com',
            mobile: 393333333,
            other: 393333333,
            bill_address: 'street 50',
            bill_city: 'Karachi',
            bill_province: 'Sindh',
            bill_country: 'Pakistan',
            bill_postal: 65120,
            ship_address: 'street 50',
            ship_city: 'Karachi',
            ship_province: 'Sindh',
            ship_country: 'Pakistan',
            ship_postal: '65120',
            note: 'This is note',
            payment_method: 'Cash',
            sales: '20',
            language: 'Englsih',
            term: '15 Net',
            tax: '20',
            date: '2023-11-04',
            website: 'www.website.com',
            fax: 393333333,
            phone: 393333333,
            opening_balance: 40000
        }]

        const csv = Papa.unparse(dataDemo);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'customer.csv');
        handleClose()
    }
    const handleFileChange1 = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64URL = event.target.result;
                const parts = base64URL.split(",")[1];
                csvToJSON(parts)

            };
            reader.readAsDataURL(file);
        }
    };
    const csvToJSON = (csvData) => {
        fetch(`${base_url}importexpense`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',

            },
            // body:jsonOutput,
            body: JSON.stringify({ file: csvData }),
        })
            .then((response) => response.json())
            .then((data) => {
                getVendorData()
                handleClick();
                setSnackbarValue({ message: "File Add Succssfully", color: "#2ECC71" });
            })
            .catch((error) => {
                // Handle any errors
                console.log('Error:', error)
            });

    };
    const handleDownloadCsv = () => {
        const csv = Papa.unparse(currentVendor);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'Vendors.csv');
    };
    console.log(obj);
    return (
        <div>
            <div className='services'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>

                    <div className='inp-buttons d-flex gap-2 w-100 ' style={{ alignItems: "end", justifyContent: "space-between " }}>
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Vendors</li>
                                </ol>
                            </nav>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                            <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                <div>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Company:</label> <br />
                                    <select disabled={params.id ? true : false} className='form-select' onChange={(e) => {
                                        handlePageChange(1)
                                        getVendorFilterCom(e.target.value)
                                    }}>
                                        <option value="">Select Company</option>
                                        {filteredCompanies?.map((item) => (
                                            <option key={item.id} value={item.name}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='clientName'>
                                    <label style={{ fontWeight: "bold", fontSize: "10px" }}>Search Name:</label> <br />
                                    <input
                                        type='text'
                                        name='name'
                                        value={name.name}
                                        onChange={(e) => {
                                            setName({ name: e.target.value });
                                            getFilterVendor(e.target.value);
                                            handlePageChange(1)
                                        }}
                                        className='form-control'
                                        placeholder='Search By Name'
                                    />
                                </div>
                            </div>
                            <div>
                                <button onClick={handleDownloadCsv} className='btn btn-primary' style={{ display: "flex", alignItems: "end", gap: "10px" }}><CiImport style={{ fontSize: "20px" }} />Export</button>
                            </div>
                            <button
                                id="basic-button"
                                className="btn btn-primary"
                                aria-controls={open1 ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                                onClick={handleClick1}
                            >
                                Import Vendor
                            </button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={downloadDemo}>Download Demo</MenuItem>
                                <MenuItem onClick={handleClose1}> <label for="csv_file" >Import File</label></MenuItem>

                            </Menu>
                            <input type='file' accept=".csv" onChange={handleFileChange1} style={{ display: "none" }} id='csv_file' />
                            <button type="button" className="btn btn-primary" onClick={handleOpen}>Add</button>
                        </div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Add
                                </Typography>
                                <div id="modal-modal-description" sx={{ mt: 2 }} style={{ height: "600px", overflowY: "scroll", padding: "1rem" }} className='scrollBar'>
                                    <div className='row'>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Basic Info</h3>
                                        <div style={{ border: "none" }} className='row mb-3'>
                                            <div className='col-12'>
                                                <div style={{ border: '2px solid', height: "100px", borderRadius: "50%", width: "100px", position: "relative" }}>
                                                    <label style={{ border: "1px solid", borderRadius: "50%", width: "25px", height: "25px", background: "lightgray", color: "gray", position: "absolute", right: "0%", paddingLeft: ".2rem", paddingBottom: ".2rem" }} htmlFor="clientProfile">
                                                        <LuImagePlus />
                                                    </label>
                                                    <img
                                                        src={obj.profile || 'default-image-url.jpg'}
                                                        alt="Profile"
                                                        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                                    />
                                                </div>
                                                <input
                                                    type="file"
                                                    id="clientProfile"
                                                    name="clientProfile"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={handleProfileImageChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Company</label>
                                                <div className='selectBox'>
                                                    <label><RiAccountPinCircleLine /></label>
                                                    <select disabled={params.id ? true : false} value={obj?.companyId} onChange={(e) => {
                                                        setSelectedCompany(parseInt(e.target.value));
                                                        setObj({
                                                            ...obj,
                                                            companyId: parseInt(e.target.value)
                                                        });
                                                    }}>
                                                        <option value="">Select a company</option>
                                                        {filteredCompanies?.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <label className='mt-2'>Name</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter Name'
                                                        value={obj?.name}
                                                        onChange={(e) => setObj({ ...obj, name: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Email</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='email'
                                                        placeholder='Enter Email'
                                                        value={obj?.email}
                                                        onChange={(e) => setObj({ ...obj, email: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Website</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='email'
                                                        placeholder='Enter Website'
                                                        value={obj?.website}
                                                        onChange={(e) => setObj({ ...obj, website: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Mobile</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='number'
                                                        placeholder='Enter Mobile Number'
                                                        value={obj?.mobile}
                                                        onChange={(e) => setObj({ ...obj, mobile: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Other</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='number'
                                                        placeholder='Enter Other Number'
                                                        value={obj?.other}
                                                        onChange={(e) => setObj({ ...obj, other: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Phone</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='number'
                                                        placeholder='Enter Phone Number'
                                                        value={obj?.phone}
                                                        onChange={(e) => setObj({ ...obj, phone: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Fax</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='number'
                                                        placeholder='Enter Fax Number'
                                                        value={obj?.fax}
                                                        onChange={(e) => setObj({ ...obj, fax: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Billing Address</h3>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Street</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='text'
                                                        placeholder='Street Address'
                                                        value={obj?.bill_address}
                                                        onChange={(e) => setObj({ ...obj, bill_address: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>City</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter City'
                                                        value={obj?.bill_city}
                                                        onChange={(e) => setObj({ ...obj, bill_city: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Postal</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='number'
                                                        placeholder='Enter Postal Code'
                                                        value={obj?.bill_postal}
                                                        onChange={(e) => setObj({ ...obj, bill_postal: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <h6 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>
                                                    <span onClick={openMap} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Preview</span>
                                                </h6>
                                            </div>

                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Province</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter Province'
                                                        value={obj?.bill_province}
                                                        onChange={(e) => setObj({ ...obj, bill_province: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Country</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter Country'
                                                        value={obj?.bill_country}
                                                        onChange={(e) => setObj({ ...obj, bill_country: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div style={{ display: "flex", alignItems: "baseline", gap: "10px" }}>
                                            <div>
                                                <h3 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Shipping Address</h3>
                                                <p>same as billing address</p>
                                            </div>
                                            <div style={{ paddingTop: "38px", marginLeft: "10px" }}>
                                                <input type='checkbox' checked={obj.sameAddress} onChange={handleCheckboxChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {obj.sameAddress ? null : (
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='inputFields'>
                                                    <label className='mt-2'>Street</label>
                                                    <div className='inputBox '>
                                                        <input
                                                            type='text'
                                                            placeholder='Street Address'
                                                            value={obj?.ship_address}
                                                            onChange={(e) => setObj({ ...obj, ship_address: e.target.value })}
                                                        />
                                                    </div>
                                                    <label className='mt-2'>City</label>
                                                    <div className='inputBox '>
                                                        <input
                                                            type='text'
                                                            placeholder='Enter City'
                                                            value={obj?.ship_city}
                                                            onChange={(e) => setObj({ ...obj, ship_city: e.target.value })}
                                                        />
                                                    </div>
                                                    <label className='mt-2'>Postal</label>
                                                    <div className='inputBox '>
                                                        <input
                                                            type='number'
                                                            placeholder='Enter Postal Code'
                                                            value={obj?.ship_postal}
                                                            onChange={(e) => setObj({ ...obj, ship_postal: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='inputFields'>
                                                    <label className='mt-2'>Province</label>
                                                    <div className='inputBox '>
                                                        <input
                                                            type='text'
                                                            placeholder='Enter Province'
                                                            value={obj?.ship_province}
                                                            onChange={(e) => setObj({ ...obj, ship_province: e.target.value })}
                                                        />
                                                    </div>
                                                    <label className='mt-2'>Company</label>
                                                    <div className='inputBox '>
                                                        <input
                                                            type='text'
                                                            placeholder='Enter Country'
                                                            value={obj?.ship_country}
                                                            onChange={(e) => setObj({ ...obj, ship_country: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <h6 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>
                                                    <span onClick={openMapForShipping} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>Preview</span>
                                                </h6>
                                            </div>
                                        </div>
                                    )}
                                    <div className='row'>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Note & Attachment</h3>
                                        <div className='col-12'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Note</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type='text'
                                                        placeholder='Enter Note'
                                                        value={obj?.note}
                                                        onChange={(e) => setObj({ ...obj, note: e.target.value })}
                                                    />
                                                </div>
                                                <label className='mt-2'>Attachment</label>
                                                <div className='inputBox '>
                                                    <input
                                                        type="file"
                                                        accept="*/*"
                                                        onChange={handleFileChange}
                                                        className="form-control"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Payment</h3>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Method</label>
                                                <div className='inputBox '>
                                                    <select value={obj?.sales} onChange={(e) => setObj({ ...obj, sales: e.target.value })}>
                                                        <option hidden>Select Payment Method</option>
                                                        <option value='Cash'>Cash</option>
                                                        <option value='Check'>Check</option>
                                                        <option value='Credit Card'>Credit Card</option>
                                                        <option value='Debit Card'>Debit Card</option>
                                                        <option value='Direct Debit'>Direct Debit</option>
                                                    </select>
                                                </div>
                                                <label className='mt-2'>Delivery Method</label>
                                                <div className='inputBox '>
                                                    <select value={obj?.payment_method} onChange={(e) => setObj({ ...obj, payment_method: e.target.value })}>
                                                        <option hidden>Select Delivery Method</option>
                                                        <option defaultValue={"Use Company Default"}>Use Company Default</option>
                                                        <option value='Print Later'>Print Later</option>
                                                        <option value='Send Later'>Send Later</option>
                                                        <option value='None'>None</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Terms</label>
                                                <div className='inputBox '>
                                                    <select value={obj?.term} onChange={(e) => setObj({ ...obj, term: e.target.value })}>
                                                        <option hidden>Terms</option>
                                                        <option value='Due on Recipt'>Due on Recipt</option>
                                                        <option value='Net 15'>Net 15</option>
                                                        <option value='Net 30'>Net 30</option>
                                                        <option value='Net 60'>Net 60</option>
                                                    </select>
                                                </div>
                                                <label className='mt-2'>Language</label>
                                                <div className='inputBox '>
                                                    <select value={obj?.language} onChange={(e) => setObj({ ...obj, language: e.target.value })}>
                                                        <option hidden>Select Language</option>
                                                        <option defaultValue={"English"}>English</option>
                                                        <option value='Spanish'>Spanish</option>
                                                        <option value='Italian'>Italian</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }} className='my-3'>Additional Info</h3>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>As of</label>
                                                <div className='inputBox '>
                                                    <input value={obj?.date} type='date' onChange={(e) => setObj({ ...obj, date: e.target.value })} className='form-control' />
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Tax</label>
                                                <div className='inputBox '>
                                                    <input value={obj?.tax} type='number' onChange={(e) => setObj({ ...obj, tax: e.target.value })} placeholder='Sales Tax registration' className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='inputFields'>
                                                <label className='mt-2'>Opening Balance</label>
                                                <div className='inputBox '>
                                                    <input value={obj?.opening_balance} type='number' onChange={(e) => setObj({ ...obj, opening_balance: e.target.value })} placeholder='Opening Balance' className='form-control' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='modalBtns d-flex justify-content-end gap-2'>
                                            <button className="btn btn-primary" onClick={() => {
                                                obj?.id ? updateVendorData(obj.id, obj) : handleSubmit()
                                                handleClose()
                                            }}>
                                                {obj?.id ? 'Update' : 'Submit'}
                                            </button>
                                            <button className="btn btn-secondary" onClick={handleClose}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table">
                            <thead style={{ textAlign: 'center' }}>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Bill Address</th>
                                    <th scope="col">Ship Address</th>
                                    <th scope="col">Phone Number</th>
                                    <th scope="col">Alternate Number</th>
                                    <th scope="col">Opening  Balance</th>
                                    <th scope="col">Tax</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: 'center' }}>
                                {params?.id ? currentVendor?.map((item, idx) => {
                                    const address = item.bill_address + ", " + item.bill_city + ", " + item.bill_province + ", " + item.bill_country + ", " + item.bill_postal ? item.bill_address + ", " + item.bill_city + ", " + item.bill_province + ", " + item.bill_country + ", " + item.bill_postal : "-"
                                    const addressParts = [
                                        item.ship_address,
                                        item.ship_city,
                                        item.ship_province,
                                        item.ship_country,
                                        item.ship_postal
                                    ];
                                    const ship_address = addressParts.filter(part => part !== null).join(' ');
                                    const date = item.date?.split(", ")
                                    return (
                                        <>
                                            {item?.company?.id == params?.id && <tr>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>{item.company?.name}</td>
                                                <td>
                                                    <Link to={`/admin/employees/profile/${item.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                                                <img style={{ borderRadius: "50%" }} src={item.profile !== null ? img_url + item.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} width={"100%"} height={"100%"} />
                                                            </div>
                                                            <div>
                                                                {item.name}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td>{item.email}</td>
                                                <td>{address.slice(0, 20)}</td>
                                                <td>{ship_address.slice(0, 20) ? ship_address.slice(0, 20) : "-"}</td>
                                                <td>{item.mobile}</td>
                                                <td>{item.other}</td>
                                                <td>{item.opening_balance ? item.opening_balance : "-"}</td>
                                                <td>{item.tax ? item.tax : "-"}</td>
                                                <td>{date[0] ? date[0] : "-"}</td>
                                                <td>
                                                    <button className="btn btn-primary p-1 m-2" onClick={() => {
                                                        setObj(item)
                                                        handleOpen()
                                                    }}>
                                                        <CiEdit style={{ fontSize: "20px" }} />
                                                    </button>
                                                    <button className='btn btn-danger p-1 m-2' onClick={() => deleteVendorData(item.id)}>
                                                        <CiTrash style={{ fontSize: "20px" }} />
                                                    </button>
                                                </td>
                                            </tr>}
                                        </>

                                    )
                                }) : currentVendor?.map((item, idx) => {
                                    const address = item.bill_address + " " + item.bill_city + " " + item.bill_province + " " + item.bill_country + " " + item.bill_postal ? item.bill_address + " " + item.bill_city + " " + item.bill_province + " " + item.bill_country + " " + item.bill_postal : "-"
                                    const addressParts = [
                                        item.ship_address,
                                        item.ship_city,
                                        item.ship_province,
                                        item.ship_country,
                                        item.ship_postal
                                    ];
                                    const ship_address = addressParts.filter(part => part !== null).join(' ');
                                    const date = item.date?.split(" ")
                                    return (
                                        <tr>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>{item.company?.name}</td>
                                            <td>
                                                <Link to={`/admin/employees/profile/${item.id}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <div style={{ height: "30px", width: "30px", borderRadius: "50%" }}>
                                                            <img style={{ borderRadius: "50%" }} src={item.profile !== null ? img_url + item.profile : "https://newvintagewi.org/wp-content/uploads/2021/07/final-resized-male-image.jpg"} width={"100%"} height={"100%"} />
                                                        </div>
                                                        <div>
                                                            {item.name}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td>{item.email}</td>
                                            <td>{address.slice(0, 20)}</td>
                                            <td>{ship_address.slice(0, 20) ? ship_address.slice(0, 20) : "-"}</td>
                                            <td>{item.mobile}</td>
                                            <td>{item.other}</td>
                                            <td>{item.opening_balance ? item.opening_balance : "-"}</td>
                                            <td>{item.tax ? item.tax : "-"}</td>
                                            <td>{date[0] ? date[0] : "-"}</td>
                                            <td>
                                                <button className="btn btn-primary p-1 m-2" onClick={() => {
                                                    setObj(item)
                                                    handleOpen()
                                                }}>
                                                    <CiEdit style={{ fontSize: "20px" }} />
                                                </button>
                                                <button className='btn btn-danger p-1 m-2' onClick={() => deleteVendorData(item.id)}>
                                                    <CiTrash style={{ fontSize: "20px" }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className="pagination-controls">{paginationControls}</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Vendor1
