import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { useParams } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { FaPlus } from "react-icons/fa6";
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'

function SupplierTimeActivity() {
    const params = useParams()
    const { creditCardData, getCreditCardData, locationData, getLocationData, classesData, getClassesData, settingDat, getDat, getClientDataActive, getAccountsDataC, clientDataActive, supplierDataActive, getSupplierDataActive, accountsDataC, storeSupplierTimeActivity, salesPurchase, getsalesPurchase, getServicesData, servicesData, getInvoiceNumberData, invoiceNumberData, getSupplierIndiData, supplierIndiData, } = useData()
    useEffect(() => {
        getInvoiceNumberData()
        getSupplierIndiData(params?.id)
        getServicesData()
        getAccountsDataC()
        getsalesPurchase()
        getClientDataActive()
        getDat()
        getSupplierDataActive()
        getClassesData()
        getLocationData()
        getCreditCardData()
    }, [])
    const [obj, setObj] = useState({
        supplier_id: supplierIndiData?.id,
        company_id: supplierIndiData?.company?.id,
        invoice_date: new Date().toISOString().split('T')[0],
        client_id: '',
        service_id: '',
        location_id: '',
        class_id: '',
        billable: false,
        total_time: '',
        isTime: false,
        startTime: '',
        endTime: '',
        break: '',
        description: '',
        amount: 0,
    });
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';
                    document?.body?.appendChild(a);
                    a.click();
                    document?.body?.removeChild(a);
                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const handleSubmit = () => {
        storeSupplierTimeActivity(obj)
    }
    const calculateTotalTime = () => {
        if (obj.isTime) {
            if (obj.startTime && obj.endTime) {
                const startTime = new Date(`2022-01-01 ${obj.startTime}`);
                const endTime = new Date(`2022-01-01 ${obj.endTime}`);

                // Calculate time difference in minutes
                let timeDiffInMinutes = (endTime - startTime) / (1000 * 60);

                // Ensure the total time is not negative
                timeDiffInMinutes = Math.max(0, timeDiffInMinutes);

                // Round off the total time to the nearest minute
                const roundedTotalMinutes = Math.round(timeDiffInMinutes);

                // Calculate total hours and minutes
                const totalHours = Math.floor(roundedTotalMinutes / 60);
                const totalMinutes = roundedTotalMinutes % 60;

                // Set the total_time in the obj
                setObj((prevObj) => ({
                    ...prevObj,
                    total_time: `${totalHours} hours ${totalMinutes} minutes`,
                }));
            }
        } else {
            setObj((prevObj) => ({
                ...prevObj,
                startTime: '',
                endTime: '',
                total_time: '',
            }));
        }
    };



    const calculateTotalAmount = () => {
        const hourlyRate = getSelectedServiceAmount();
        const totalHours = parseInt(obj.total_time) || 0;
        const breakHours = calculateBreakHours();
        const totalAmount = totalHours * hourlyRate - breakHours;
        return totalAmount.toFixed(2);
    };

    const handleServiceChange = (e) => {
        const selectedServiceId = parseInt(e.target.value);
        const selectedService = servicesData?.services?.find((service) => service.id === selectedServiceId);

        setObj((prevObj) => ({
            ...prevObj,
            service_id: selectedServiceId,
            amount: obj?.billable ? calculateTotalAmount() : selectedService?.sale_price || 0,
        }));
    };

    const getSelectedServiceAmount = () => {
        const selectedService = servicesData?.services.find((service) => service.id === obj.service_id);
        return selectedService?.sale_price || 0;
    };
    const calculateRoundedBreakTime = (breakTime) => {
        const [hours, minutes] = breakTime.split(':').map(Number);

        // Round the minutes to the nearest 15 minutes
        const roundedMinutes = Math.round(minutes / 15) * 15;

        // Adjust the hours if rounding results in 60 minutes
        const roundedHours = hours + Math.floor(roundedMinutes / 60);
        const finalMinutes = roundedMinutes % 60;

        return `${String(roundedHours).padStart(2, '0')}:${String(finalMinutes).padStart(2, '0')}`;
    };
    const calculateBreakHours = () => {
        const breakTime = calculateRoundedBreakTime(obj.break);
        const breakHours =
            parseFloat(breakTime.split(':')[0]) + parseFloat(breakTime.split(':')[1]) / 60 || 0;
        return breakHours;
    };
    useEffect(() => {
        calculateTotalTime();
    }, [obj.startTime, obj.endTime, obj.isTime]);

    console.log(obj);
    return (
        <div>
            <div className="card" ref={contentRef}>
                <div className="card-body">
                    <div className="container mb-5 mt-3">
                        <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: '#7e8d9f', fontSize: '20px' }}>Time Activity</p>
                            </div>
                            <div>
                                <div className='card p-3 text-center'>
                                    {/* <h4 style={{ fontSize: "25px", fontwe: "bold" }}>PRs, {Math.round(obj.amount)}</h4>
                                    <p className='p-0 m-0' style={{ fontSize: "13px" }}>Balance Paid</p> */}
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary text-capitalize mx-3"
                                    style={{ backgroundColor: '#60bdf3' }}
                                    onClick={handlePrint}
                                >
                                    Download PDF
                                </button>
                                <button className='btn btn-success' onClick={handleSubmit}>Save Time Activity</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="row my-2 mx-1 justify-content-center">
                            <div className='col-md-6 mb-3'>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Supplier</h4>
                                    <input type="text" className='form-control' value={supplierIndiData?.name} />
                                </div>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Date</h4>
                                    <input type="date" className='form-control' value={obj?.invoice_date} onChange={(e) => setObj({ ...obj, invoice_date: e.target.value })} />
                                </div>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Customer</h4>
                                    <select className='form-control' value={obj?.client_id} onChange={(e) => setObj({ ...obj, client_id: e.target.value })}>
                                        <option hidden>Select</option>
                                        {clientDataActive?.map((item, i) => {
                                            return (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Service</h4>
                                    <select className='form-control' value={obj?.service_id} onChange={(e) => handleServiceChange(e)}>
                                        <option hidden>Select</option>
                                        {servicesData?.services?.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>

                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }} className='mt-3'>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                                            <input type='checkbox' style={{ height: "30px", width: "23px" }} checked={obj?.billable} onChange={(e) => setObj({ ...obj, billable: e.target.checked })} />
                                            <label htmlFor="billable" style={{ fontWeight: "bold", fontSize: "12px" }}>Billable/hr</label>
                                        </div>
                                        {obj?.billable && (
                                            <div>
                                                <p style={{ border: "1px solid lightgray", padding: "3px 10px", margin: "0" }}>Rs: {getSelectedServiceAmount()}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {settingDat?.data?.track_location === 1 && <div>
                                    <label style={{ fontWeight: "bold", fontSize: "15px" }}>Locations</label>
                                    <select
                                        className='form-select mb-3'
                                        onChange={(e) => {
                                            setObj({ ...obj, location_id: e.target.value })
                                        }}
                                        value={obj?.location_id}

                                    >
                                        <option hidden>Select</option>
                                        {locationData?.data?.map((items) => (
                                            <option key={items.id} value={items.id}>
                                                {items.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                                {settingDat?.data?.track_classes === 1 && <div>
                                    <label style={{ fontWeight: "bold", fontSize: "15px" }}>Classes</label>
                                    <select
                                        className='form-select mb-3'
                                        value={obj.class_id}
                                        onChange={(e) => setObj({ ...obj, class_id: e.target.value })}

                                    >
                                        <option hidden>Select</option>
                                        {classesData?.data?.map((items) => (
                                            <option key={items.id} value={items.id}>
                                                {items.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                            </div>
                            <div className='col-md-6 mb-3'>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <input type='checkbox' style={{ height: "30px", width: "23px" }} checked={obj?.isTime} onChange={(e) => setObj({ ...obj, isTime: e.target.checked })} />
                                        <label for="isTime" style={{ fontWeight: "bold", fontSize: "12px" }}>Enter Start Time and End Time</label>
                                    </div>
                                    {obj?.isTime === true && <div>
                                        <div>
                                            <h3 style={{ fontWeight: "bold", fontSize: "15px" }}>Start Time</h3>
                                            <select className='form-control mb-3' value={obj?.startTime} onChange={(e) => setObj({ ...obj, startTime: e.target.value })}>
                                                <option hidden>Select</option>
                                                <option hidden>Select</option>
                                                <option value="12:00 AM">12:00 AM</option>
                                                <option value="12:15 AM">12:15 AM</option>
                                                <option value="12:30 AM">12:30 AM</option>
                                                <option value="12:45 AM">12:45 AM</option>
                                                <option value="1:00 AM">1:00 AM</option>
                                                <option value="1:15 AM">1:15 AM</option>
                                                <option value="1:30 AM">1:30 AM</option>
                                                <option value="1:45 AM">1:45 AM</option>
                                                <option value="2:00 AM">2:00 AM</option>
                                                <option value="2:15 AM">2:15 AM</option>
                                                <option value="2:30 AM">2:30 AM</option>
                                                <option value="2:45 AM">2:45 AM</option>
                                                <option value="3:00 AM">3:00 AM</option>
                                                <option value="3:15 AM">3:15 AM</option>
                                                <option value="3:30 AM">3:30 AM</option>
                                                <option value="3:45 AM">3:45 AM</option>
                                                <option value="4:00 AM">4:00 AM</option>
                                                <option value="4:15 AM">4:15 AM</option>
                                                <option value="4:30 AM">4:30 AM</option>
                                                <option value="4:45 AM">4:45 AM</option>
                                                <option value="5:00 AM">5:00 AM</option>
                                                <option value="5:15 AM">5:15 AM</option>
                                                <option value="5:30 AM">5:30 AM</option>
                                                <option value="5:45 AM">5:45 AM</option>
                                                <option value="6:00 AM">6:00 AM</option>
                                                <option value="6:15 AM">6:15 AM</option>
                                                <option value="6:30 AM">6:30 AM</option>
                                                <option value="6:45 AM">6:45 AM</option>
                                                <option value="7:00 AM">7:00 AM</option>
                                                <option value="7:15 AM">7:15 AM</option>
                                                <option value="7:30 AM">7:30 AM</option>
                                                <option value="7:45 AM">7:45 AM</option>
                                                <option value="8:00 AM">8:00 AM</option>
                                                <option value="8:15 AM">8:15 AM</option>
                                                <option value="8:30 AM">8:30 AM</option>
                                                <option value="8:45 AM">8:45 AM</option>
                                                <option value="9:00 AM">9:00 AM</option>
                                                <option value="9:15 AM">9:15 AM</option>
                                                <option value="9:30 AM">9:30 AM</option>
                                                <option value="9:45 AM">9:45 AM</option>
                                                <option value="10:00 AM">10:00 AM</option>
                                                <option value="10:15 AM">10:15 AM</option>
                                                <option value="10:30 AM">10:30 AM</option>
                                                <option value="10:45 AM">10:45 AM</option>
                                                <option value="11:00 AM">11:00 AM</option>
                                                <option value="11:15 AM">11:15 AM</option>
                                                <option value="11:30 AM">11:30 AM</option>
                                                <option value="11:45 AM">11:45 AM</option>
                                                <option value="12:00 PM">12:00 PM</option>
                                                <option value="12:15 PM">12:15 PM</option>
                                                <option value="12:30 PM">12:30 PM</option>
                                                <option value="12:45 PM">12:45 PM</option>
                                                <option value="1:00 PM">1:00 PM</option>
                                                <option value="1:15 PM">1:15 PM</option>
                                                <option value="1:30 PM">1:30 PM</option>
                                                <option value="1:45 PM">1:45 PM</option>
                                                <option value="2:00 PM">2:00 PM</option>
                                                <option value="2:15 PM">2:15 PM</option>
                                                <option value="2:30 PM">2:30 PM</option>
                                                <option value="2:45 PM">2:45 PM</option>
                                                <option value="3:00 PM">3:00 PM</option>
                                                <option value="3:15 PM">3:15 PM</option>
                                                <option value="3:30 PM">3:30 PM</option>
                                                <option value="3:45 PM">3:45 PM</option>
                                                <option value="4:00 PM">4:00 PM</option>
                                                <option value="4:15 PM">4:15 PM</option>
                                                <option value="4:30 PM">4:30 PM</option>
                                                <option value="4:45 PM">4:45 PM</option>
                                                <option value="5:00 PM">5:00 PM</option>
                                                <option value="5:15 PM">5:15 PM</option>
                                                <option value="5:30 PM">5:30 PM</option>
                                                <option value="5:45 PM">5:45 PM</option>
                                                <option value="6:00 PM">6:00 PM</option>
                                                <option value="6:15 PM">6:15 PM</option>
                                                <option value="6:30 PM">6:30 PM</option>
                                                <option value="6:45 PM">6:45 PM</option>
                                                <option value="7:00 PM">7:00 PM</option>
                                                <option value="7:15 PM">7:15 PM</option>
                                                <option value="7:30 PM">7:30 PM</option>
                                                <option value="7:45 PM">7:45 PM</option>
                                                <option value="8:00 PM">8:00 PM</option>
                                                <option value="8:15 PM">8:15 PM</option>
                                                <option value="8:30 PM">8:30 PM</option>
                                                <option value="8:45 PM">8:45 PM</option>
                                                <option value="9:00 PM">9:00 PM</option>
                                                <option value="9:15 PM">9:15 PM</option>
                                                <option value="9:30 PM">9:30 PM</option>
                                                <option value="9:45 PM">9:45 PM</option>
                                                <option value="10:00 PM">10:00 PM</option>
                                                <option value="10:15 PM">10:15 PM</option>
                                                <option value="10:30 PM">10:30 PM</option>
                                                <option value="10:45 PM">10:45 PM</option>
                                                <option value="11:00 PM">11:00 PM</option>
                                                <option value="11:15 PM">11:15 PM</option>
                                                <option value="11:30 PM">11:30 PM</option>
                                                <option value="11:45 PM">11:45 PM</option>


                                            </select>
                                        </div>
                                        <div>
                                            <h3 style={{ fontWeight: "bold", fontSize: "15px" }}>End Time</h3>
                                            <select className='form-control mb-3' value={obj?.endTime} onChange={(e) => setObj({ ...obj, endTime: e.target.value })}>
                                                <option hidden>Select</option>
                                                <option hidden>Select</option>
                                                <option value="12:00 AM">12:00 AM</option>
                                                <option value="12:15 AM">12:15 AM</option>
                                                <option value="12:30 AM">12:30 AM</option>
                                                <option value="12:45 AM">12:45 AM</option>
                                                <option value="1:00 AM">1:00 AM</option>
                                                <option value="1:15 AM">1:15 AM</option>
                                                <option value="1:30 AM">1:30 AM</option>
                                                <option value="1:45 AM">1:45 AM</option>
                                                <option value="2:00 AM">2:00 AM</option>
                                                <option value="2:15 AM">2:15 AM</option>
                                                <option value="2:30 AM">2:30 AM</option>
                                                <option value="2:45 AM">2:45 AM</option>
                                                <option value="3:00 AM">3:00 AM</option>
                                                <option value="3:15 AM">3:15 AM</option>
                                                <option value="3:30 AM">3:30 AM</option>
                                                <option value="3:45 AM">3:45 AM</option>
                                                <option value="4:00 AM">4:00 AM</option>
                                                <option value="4:15 AM">4:15 AM</option>
                                                <option value="4:30 AM">4:30 AM</option>
                                                <option value="4:45 AM">4:45 AM</option>
                                                <option value="5:00 AM">5:00 AM</option>
                                                <option value="5:15 AM">5:15 AM</option>
                                                <option value="5:30 AM">5:30 AM</option>
                                                <option value="5:45 AM">5:45 AM</option>
                                                <option value="6:00 AM">6:00 AM</option>
                                                <option value="6:15 AM">6:15 AM</option>
                                                <option value="6:30 AM">6:30 AM</option>
                                                <option value="6:45 AM">6:45 AM</option>
                                                <option value="7:00 AM">7:00 AM</option>
                                                <option value="7:15 AM">7:15 AM</option>
                                                <option value="7:30 AM">7:30 AM</option>
                                                <option value="7:45 AM">7:45 AM</option>
                                                <option value="8:00 AM">8:00 AM</option>
                                                <option value="8:15 AM">8:15 AM</option>
                                                <option value="8:30 AM">8:30 AM</option>
                                                <option value="8:45 AM">8:45 AM</option>
                                                <option value="9:00 AM">9:00 AM</option>
                                                <option value="9:15 AM">9:15 AM</option>
                                                <option value="9:30 AM">9:30 AM</option>
                                                <option value="9:45 AM">9:45 AM</option>
                                                <option value="10:00 AM">10:00 AM</option>
                                                <option value="10:15 AM">10:15 AM</option>
                                                <option value="10:30 AM">10:30 AM</option>
                                                <option value="10:45 AM">10:45 AM</option>
                                                <option value="11:00 AM">11:00 AM</option>
                                                <option value="11:15 AM">11:15 AM</option>
                                                <option value="11:30 AM">11:30 AM</option>
                                                <option value="11:45 AM">11:45 AM</option>
                                                <option value="12:00 PM">12:00 PM</option>
                                                <option value="12:15 PM">12:15 PM</option>
                                                <option value="12:30 PM">12:30 PM</option>
                                                <option value="12:45 PM">12:45 PM</option>
                                                <option value="1:00 PM">1:00 PM</option>
                                                <option value="1:15 PM">1:15 PM</option>
                                                <option value="1:30 PM">1:30 PM</option>
                                                <option value="1:45 PM">1:45 PM</option>
                                                <option value="2:00 PM">2:00 PM</option>
                                                <option value="2:15 PM">2:15 PM</option>
                                                <option value="2:30 PM">2:30 PM</option>
                                                <option value="2:45 PM">2:45 PM</option>
                                                <option value="3:00 PM">3:00 PM</option>
                                                <option value="3:15 PM">3:15 PM</option>
                                                <option value="3:30 PM">3:30 PM</option>
                                                <option value="3:45 PM">3:45 PM</option>
                                                <option value="4:00 PM">4:00 PM</option>
                                                <option value="4:15 PM">4:15 PM</option>
                                                <option value="4:30 PM">4:30 PM</option>
                                                <option value="4:45 PM">4:45 PM</option>
                                                <option value="5:00 PM">5:00 PM</option>
                                                <option value="5:15 PM">5:15 PM</option>
                                                <option value="5:30 PM">5:30 PM</option>
                                                <option value="5:45 PM">5:45 PM</option>
                                                <option value="6:00 PM">6:00 PM</option>
                                                <option value="6:15 PM">6:15 PM</option>
                                                <option value="6:30 PM">6:30 PM</option>
                                                <option value="6:45 PM">6:45 PM</option>
                                                <option value="7:00 PM">7:00 PM</option>
                                                <option value="7:15 PM">7:15 PM</option>
                                                <option value="7:30 PM">7:30 PM</option>
                                                <option value="7:45 PM">7:45 PM</option>
                                                <option value="8:00 PM">8:00 PM</option>
                                                <option value="8:15 PM">8:15 PM</option>
                                                <option value="8:30 PM">8:30 PM</option>
                                                <option value="8:45 PM">8:45 PM</option>
                                                <option value="9:00 PM">9:00 PM</option>
                                                <option value="9:15 PM">9:15 PM</option>
                                                <option value="9:30 PM">9:30 PM</option>
                                                <option value="9:45 PM">9:45 PM</option>
                                                <option value="10:00 PM">10:00 PM</option>
                                                <option value="10:15 PM">10:15 PM</option>
                                                <option value="10:30 PM">10:30 PM</option>
                                                <option value="10:45 PM">10:45 PM</option>
                                                <option value="11:00 PM">11:00 PM</option>
                                                <option value="11:15 PM">11:15 PM</option>
                                                <option value="11:30 PM">11:30 PM</option>
                                                <option value="11:45 PM">11:45 PM</option>


                                            </select>
                                        </div>
                                    </div>}
                                    {obj?.isTime === false && <div>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }}>Break</h3>
                                        <input type='number' className='form-control mb-3' placeholder='hours e.g 1-24' min={0} max={24} value={obj?.break} onChange={(e) => setObj({ ...obj, break: e.target.value })} />
                                    </div>}
                                    <div>
                                        <h3 style={{ fontWeight: "bold", fontSize: "15px" }}>Description</h3>
                                        <textarea className='form-control mb-3' placeholder='Description' value={obj?.description} onChange={(e) => setObj({ ...obj, description: e.target.value })} />
                                    </div>
                                    <p style={{ fontWeight: "bold", fontSize: "15px" }}>Summary</p>
                                    {obj.isTime && (
                                        <p>
                                            {obj.total_time} at PRs{obj.amount}/hour = PRs
                                            {(parseFloat(obj.total_time) * parseFloat(obj.amount)).toFixed(2)}
                                        </p>
                                    )}
                                    {!obj.isTime && (
                                        <p>
                                            {obj.break} hours at PRs{obj.amount}/hour = PRs
                                            {(parseFloat(obj.break) * parseFloat(obj.amount)).toFixed(2)}
                                        </p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SupplierTimeActivity
