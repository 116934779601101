import React, { useEffect, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { Link, useParams } from 'react-router-dom';


function CompanyTable2() {
    const params = useParams()
    const { accountCom, getAccountCom, } = useData()
    useEffect(() => {
        getAccountCom()
    }, [])
    const filteredCompanies = accountCom?.company?.filter(item => item.deleteStatus === 0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentCompanies = filteredCompanies?.slice(indexOfFirstItem, indexOfLastItem) || [];

    const totalPages = Math.ceil((filteredCompanies?.length || 0) / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const paginationControls = [];
    for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
            <button className='btn btn-primary p-2' style={{ margin: "0px 5px" }} key={i} onClick={() => handlePageChange(i)}>
                {i}
            </button>
        );
    }
    return (
        <>
            <div className='expenseCategory'>
                <div className='expense-header d-flex justify-content-between align-items-baseline'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={"/admin/dashboard"}>Admin</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Trial Balance</li>
                        </ol>
                    </nav>
                </div>
                <hr />
                <div className='card'>
                    <div className='card-body table-responsive recruitment'>
                        <table class="table mx-auto w-100">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {params?.id ? currentCompanies?.map((items, idx) => {
                                    return (
                                        <>
                                            {items?.id == params?.id && <tr key={idx}>
                                                <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                                <td>
                                                    <Link style={{ textDecoration: "none", color: "black" }} to={`/admin/accounts/banking/TrialBalance/${items.id}`} className='link_hover'>
                                                        {items.name}
                                                    </Link>
                                                </td>
                                                <td>{items.email}</td>
                                            </tr>}
                                        </>
                                    );
                                }) : currentCompanies?.map((items, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <th scope="row">{indexOfFirstItem + idx + 1}</th>
                                            <td>
                                                <Link style={{ textDecoration: "none", color: "black" }} to={`/admin/accounts/banking/TrialBalance/${items.id}`}>
                                                    {items.name}
                                                </Link>
                                            </td>
                                            <td>{items.email}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div>
                            {paginationControls.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CompanyTable2
