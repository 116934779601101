import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { useParams } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { FaPlus } from "react-icons/fa6";
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'

function SupplierCreditCard() {
    const params = useParams()
    const { creditCardData, getCreditCardData, locationData, getLocationData, classesData, getClassesData, settingDat, getDat, getClientDataActive, getAccountsDataC, clientDataActive, supplierDataActive, getSupplierDataActive, accountsDataC, storePaydownCreditCard, salesPurchase, getsalesPurchase, getServicesData, servicesData, getInvoiceNumberData, invoiceNumberData, getSupplierIndiData, supplierIndiData, } = useData()
    useEffect(() => {
        getInvoiceNumberData()
        getSupplierIndiData(params?.id)
        getServicesData()
        getAccountsDataC()
        getsalesPurchase()
        getClientDataActive()
        getDat()
        getSupplierDataActive()
        getClassesData()
        getLocationData()
        getCreditCardData()
    }, [])
    const [obj, setObj] = useState({
        supplier_id: supplierIndiData?.id,
        company_id: supplierIndiData?.company?.id,
        payment_date: new Date().toISOString().split('T')[0],
        credit_id: '',
        message: '',
        account_id: '',
        amount: 0,
        attachment: '',
    });
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Data = reader.result;

                setObj((prevObj) => ({
                    ...prevObj,
                    attachment: base64Data,
                }));
            };

            reader.readAsDataURL(file);
        }
    };

    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const handleSubmit = () => {
        storePaydownCreditCard(obj)
    }
    console.log(obj);
    return (
        <div>
            <div className="card" ref={contentRef}>
                <div className="card-body">
                    <div className="container mb-5 mt-3">
                        <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: '#7e8d9f', fontSize: '20px' }}>Paydown Credit</p>
                            </div>
                            <div>
                                <div className='card p-3 text-center'>
                                    <h4 style={{ fontSize: "25px", fontwe: "bold" }}>PRs, {Math.round(obj.amount)}</h4>
                                    <p className='p-0 m-0' style={{ fontSize: "13px" }}>Balance Paid</p>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary text-capitalize mx-3"
                                    style={{ backgroundColor: '#60bdf3' }}
                                    onClick={handlePrint}
                                >
                                    Download PDF
                                </button>
                                <button className='btn btn-success' onClick={handleSubmit}>Save Paydown Credit</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="row my-2 mx-1 justify-content-center">
                            <div>
                                <h3>Record Payment made to your balance</h3>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "16px" }}>Which credit card did you pay?</h4>
                                    <select className='form-control' value={obj?.credit_id} onChange={(e) => setObj({ ...obj, credit_id: e.target.value })}>
                                        <option hidden>Select</option>
                                        {creditCardData?.data?.map((item, i) => {
                                            return (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "16px" }}>Payee</h4>
                                    <input type="text" className='form-control' value={supplierIndiData?.name} />
                                </div>
                                <div className='row mb-3'>
                                    <div className='col-6'>
                                        <h4 style={{ fontWeight: "bold", fontSize: "16px" }}>How much did you pay?</h4>
                                        <input type='number' className='form-control' value={obj?.amount} onChange={(e) => setObj({ ...obj, amount: e.target.value })} placeholder='Enter the amount' />
                                    </div>
                                    <div className='col-6'>
                                        <h4 style={{ fontWeight: "bold", fontSize: "16px" }}>Date of Payment</h4>
                                        <input type='date' className='form-control' value={obj?.payment_date} onChange={(e) => setObj({ ...obj, payment_date: e.target.value })} />
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <h4 style={{ fontWeight: "bold", fontSize: "16px" }}>What did you use to make this Payment?</h4>
                                    <select className='form-control' value={obj?.account_id} onChange={(e) => setObj({ ...obj, account_id: e.target.value })}>
                                        <option hidden>Select</option>
                                        {accountsDataC?.data?.map((item, i) => {
                                            return (
                                                <option key={i} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <p className="text-black float-start"><span className="text-black me-3" style={{ fontSize: "20px" }}> Total Paid</span><span style={{ fontSize: '20px' }}>{Math.round(obj.amount)}</span></p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Memo:</h4>
                                <textarea placeholder='Message' className='form-control' onChange={(e) => setObj({ ...obj, message: e.target.value })}></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Attachment:</h4>
                                <input className='form-control' type='file' onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SupplierCreditCard
