import React, { useEffect, useRef, useState } from 'react'
import { useData } from '../../../ContextProvider/Context';
import { useParams } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { FaPlus } from "react-icons/fa6";
import html2pdf from 'html2pdf.js';
import { useReactToPrint } from 'react-to-print'

function SupplierCheque() {
    const params = useParams()
    const { locationData, getLocationData, classesData, getClassesData, settingDat, getDat, getClientDataActive, getAccountsDataC, clientDataActive, supplierDataActive, getSupplierDataActive, accountsDataC, storeSupplierCheque, salesPurchase, getsalesPurchase, getServicesData, servicesData, getInvoiceNumberData, invoiceNumberData, getSupplierIndiData, supplierIndiData, } = useData()
    useEffect(() => {
        getInvoiceNumberData()
        getSupplierIndiData(params?.id)
        getServicesData()
        getAccountsDataC()
        getsalesPurchase()
        getClientDataActive()
        getDat()
        getSupplierDataActive()
        getClassesData()
        getLocationData()
    }, [])
    const [taxType, setTaxType] = useState(settingDat?.data?.tax_form || '');
    const [obj, setObj] = useState({
        supplier_id: supplierIndiData?.id,
        company_id: supplierIndiData?.company?.id,
        payment_date: new Date().toISOString().split('T')[0],
        due_date: '',
        whichtax: taxType,
        cheque_no: invoiceNumberData?.cheque_no,
        message: '',
        location_id: '',
        account_id: '',
        total_amount: 0,
        total_examount: 0,
        total_serviceamount: 0,
        attachment: '',
        expense_category: [
            {
                client_id: '',
                account_id: '',
                description: '',
                extax: '',
                tax_id: '',
                amount: 0,
                class_id: '',
                total_price: '',
                billable: false,
            },
        ],
        supplier_service: [
            {
                service_date: new Date().toISOString().split('T')[0],
                service_id: '',
                class_id: '',
                client_id: '',
                quantity: '',
                extax: '',
                tax_id: '',
                amount: 0,
                billable: false,
                total_price: ''
            },
        ]
    });

    useEffect(() => {
        const totalAmountSum = obj?.expense_category?.reduce((sum, item) => {
            const itemTotalPrice = parseFloat(item?.total_price);
            if (!isNaN(itemTotalPrice)) {
                return sum + itemTotalPrice;
            }

            return sum;
        }, 0);

        setObj(prevObj => ({
            ...prevObj,
            total_examount: parseInt(Math.round(totalAmountSum)),
        }));
    }, [obj.expense_category]);
    useEffect(() => {
        const totalAmountSum = obj?.supplier_service?.reduce((sum, item) => {
            const itemTotalPrice = parseFloat(item?.total_price);
            if (!isNaN(itemTotalPrice)) {
                return sum + itemTotalPrice;
            }

            return sum;
        }, 0);

        setObj(prevObj => ({
            ...prevObj,
            total_serviceamount: parseInt(Math.round(totalAmountSum)),
        }));
    }, [obj.supplier_service]);

    useEffect(() => {
        const totalAmountSum1 = obj?.expense_category?.reduce((sum, item) => {
            const itemTotalPrice = parseFloat(item?.total_price);
            if (!isNaN(itemTotalPrice)) {
                return sum + itemTotalPrice;
            }

            return sum;
        }, 0);

        const totalAmountSum = obj?.supplier_service?.reduce((sum, item) => {
            const itemTotalPrice = parseFloat(item?.total_price);
            if (!isNaN(itemTotalPrice)) {
                return sum + itemTotalPrice;
            }

            return sum;
        }, 0);
        const totalAmountForBoth = totalAmountSum1 + totalAmountSum
        setObj(prevObj => ({
            ...prevObj,
            total_amount: parseInt(Math.round(totalAmountForBoth)),
        }));
    }, [obj?.expense_category, obj?.supplier_service])
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64Data = reader.result;

                setObj((prevObj) => ({
                    ...prevObj,
                    attachment: base64Data,
                }));
            };

            reader.readAsDataURL(file);
        }
    };
    const handleAddRow = () => {
        setObj((prevObj) => ({
            ...prevObj,
            expense_category: [
                ...prevObj.expense_category,
                {
                    client_id: '',
                    description: '',
                    class_id: '',
                    extax: '',
                    tax_id: '',
                    amount: 0,
                    total_price: '',
                    billable: false,
                },
            ],
        }));
    };
    const handleAddRowService = () => {
        setObj((prevObj) => ({
            ...prevObj,
            supplier_service: [
                ...prevObj.supplier_service,
                {
                    service_id: new Date().toISOString().split('T')[0],
                    service_date: '',
                    client_id: '',
                    quantity: '',
                    extax: '',
                    class_id: '',
                    tax_id: '',
                    amount: 0,
                    billable: false,
                    total_price: ''
                },
            ],
        }));
    };

    const handleDeleteRow = (index) => {
        setObj((prevObj) => {
            const updatedExpense = [...prevObj.expense_category];
            updatedExpense.splice(index, 1);
            return { ...prevObj, expense_category: updatedExpense };
        });
    };
    const handleDeleteServicesRow = (index) => {
        setObj((prevObj) => {
            const updatedExpense = [...prevObj.supplier_service];
            updatedExpense.splice(index, 1);
            return { ...prevObj, supplier_service: updatedExpense };
        });
    };
    const handleChangeAccount = (value, index, key) => {
        console.log('Handling change:', value, index, key);
        setObj((prevObj) => {
            const updatedExpense = [...prevObj.expense_category];
            updatedExpense[index][key] = value;

            if (key === 'client_id') {
                updatedExpense[index].client_id = value;
            } else if (key === 'account_id') {
                updatedExpense[index].account_id = value;
            } else if (key === 'description') {
                updatedExpense[index].description = value;
            } else if (key === 'extax') {
                updatedExpense[index].extax = parseFloat(value) || 0;
            } else if (key === 'tax_id') {
                updatedExpense[index].tax_id = value;
            } else if (key === 'amount') {
                updatedExpense[index].amount = parseFloat(value);
            } else if (key === 'billable') {
                updatedExpense[index].billable = value;
            } else if (key === 'class_id') {
                updatedExpense[index].class_id = value;
            }

            const selectedTax = salesPurchase?.purchase_tax?.find((i) => i.id === Number(updatedExpense[index].tax_id));

            const subTotal = taxType === 'Inclusive Tax'
                ? updatedExpense[index].amount * (selectedTax?.rate / 100 || 0)
                : (updatedExpense[index].amount * updatedExpense[index].extax / 100 || 0);

            const totalAmount = subTotal + updatedExpense[index].amount;
            updatedExpense[index].total_price = parseInt(totalAmount).toFixed(2);

            const newTotalAmount = updatedExpense.reduce((acc, expense) => acc + parseInt(expense.total_price), 0);

            // Log relevant values for debugging
            console.log('Amount:', updatedExpense[index].amount);
            console.log('Tax ID:', updatedExpense[index].tax_id);
            console.log('Subtotal:', subTotal);
            console.log('Total Amount:', totalAmount);

            return { ...prevObj, expense_category: updatedExpense, total_amount: parseInt(newTotalAmount.toFixed(2)) };
        });

        if (key === 'tax_id') {
            const selectedTaxType = value === 'Exclusive Tax' ? 'Exclusive Tax' : 'Inclusive Tax';
            setTaxType(selectedTaxType);
        }
    };

    const handleChangeService = (value, index, key) => {
        setObj((prevObj) => {
            const updatedInvoice = [...prevObj.supplier_service];
            updatedInvoice[index][key] = value;

            // Define selectedService here
            const selectedService = servicesData?.services?.find((i) => i.id === Number(updatedInvoice[index].service_id));
            console.log(selectedService);

            const subTotal = updatedInvoice[index].quantity * (selectedService?.sale_price || 0);

            let taxRate = 0;
            if (key === 'tax_id') {
                taxRate = salesPurchase?.purchase_tax?.find((i) => i.id === Number(value))?.rate || 0;
            } else if (key === 'extax') {
                taxRate = parseFloat(value) || 0;
            }
            const totalAmount = taxType === 'Inclusive Tax'
                ? subTotal + (subTotal * (taxRate / 100))
                : subTotal + (subTotal * (taxRate / 100));
            updatedInvoice[index].total_price = Math.round(totalAmount);

            return { ...prevObj, supplier_service: updatedInvoice, amountsare: taxType };
        });

        if (key === 'tax_id') {
            const selectedTaxType = value === 'Exclusive Tax' ? 'Exclusive Tax' : 'Inclusive Tax';
            setTaxType(selectedTaxType);
        }
    };



    const handleInitialTerm = (selectedTerm) => {
        const currentDate = new Date(obj.payment_date);

        if (selectedTerm === 'Due on Recipt') {
            setObj((prevObj) => ({
                ...prevObj,
                term: selectedTerm,
                due_date: currentDate?.toISOString().substr(0, 10),
            }));
        } else if (selectedTerm.startsWith('Net')) {
            const daysToAdd = parseInt(selectedTerm.split(' ')[1]);
            if (!isNaN(daysToAdd)) {
                currentDate.setDate(currentDate?.getDate() + daysToAdd);
                const formattedDueDate = currentDate?.toISOString().substr(0, 10);
                setObj((prevObj) => ({
                    ...prevObj,
                    term: selectedTerm,
                    due_date: formattedDueDate,
                }));
            }
        }
    };
    const cardRef = useRef(null);
    useEffect(() => {
        if (cardRef.current) {
            handleDownloadPDF();
        }
    }, [cardRef]);
    const [pdfGenerating, setPdfGenerating] = useState(false);
    const handleDownloadPDF = () => {
        if (pdfGenerating) {
            return;
        }

        setPdfGenerating(true);

        setTimeout(() => {
            const card = cardRef.current;

            const pdfOptions = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            };

            if (card) {
                html2pdf()?.from(card)?.set(pdfOptions)?.outputPdf((pdf) => {
                    const blob = new Blob([pdf], { type: 'application/pdf' });

                    // Create a download link
                    const url = URL.createObjectURL(blob);
                    const a = document?.createElement('a');
                    a.href = url;
                    a.download = 'invoice.pdf';
                    a.style.display = 'none';

                    // Append the link to the body and trigger the click
                    document?.body?.appendChild(a);
                    a.click();

                    // Remove the link after the download
                    document?.body?.removeChild(a);

                    setPdfGenerating(false);
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                    setPdfGenerating(false);
                });
            } else {
                console.error('Card element not found.');
                setPdfGenerating(false);
            }
        }, 0);
    };

    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef?.current,
    });
    const billingAddress = supplierIndiData?.bill_address + ',' + supplierIndiData?.bill_city + ',' + supplierIndiData?.bill_province + ',' + supplierIndiData?.bill_country + ',' + supplierIndiData?.bill_postal
    const handleSubmit = () => {
        storeSupplierCheque(obj)
    }
    const conbinedBoth = [...supplierDataActive, ...clientDataActive]
    console.log(obj)
    return (
        <div>
            <div className="card" ref={contentRef}>
                <div className="card-body">
                    <div className="container mb-5 mt-3">
                        <div className="d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                            <div>
                                <p style={{ color: '#7e8d9f', fontSize: '20px' }}>Cheque#: {invoiceNumberData?.cheque_no}</p>
                            </div>
                            <div>
                                <div className='card p-3 text-center'>
                                    <h4 style={{ fontSize: "25px", fontwe: "bold" }}>PRs, {Math.round(obj.total_amount)}</h4>
                                    <p className='p-0 m-0' style={{ fontSize: "13px" }}>Balance Due</p>
                                </div>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-primary text-capitalize mx-3"
                                    style={{ backgroundColor: '#60bdf3' }}
                                    onClick={handlePrint}
                                >
                                    Download PDF
                                </button>
                                <button className='btn btn-success' onClick={handleSubmit}>Save Cheque</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="row">
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "start" }}>
                                <div>
                                    <div style={{ height: "65px", }}>
                                        <label>Payee</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{supplierIndiData?.name ? supplierIndiData?.name : '-'}</p>
                                    </div>
                                    <div style={{ height: "65px", }}>
                                        <label>Email</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{supplierIndiData?.email ? supplierIndiData?.email : '-'}</p>
                                    </div>
                                    <div>
                                        <label>Billing address</label>
                                        <p className='bbClass' style={{ fontSize: "15px", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{billingAddress ? billingAddress : '-'}</p>
                                    </div>
                                    <div style={{ height: "65px" }}>
                                        <label>Amounts are:</label>
                                        <select
                                            className='form-select'
                                            style={{ width: '300px', fontSize: "12px" }}
                                            onChange={(e) => {
                                                setObj({ ...obj, whichtax: e.target.value })
                                                setTaxType(e.target.value)
                                            }}
                                            value={obj?.whichtax}

                                        >
                                            <option hidden>Select</option>
                                            <option value={'Inclusive Tax'}>Inclusive Tax</option>
                                            <option value={'Exclusive Tax'}>Exclusive Tax</option>
                                            <option value={'Out of Scope of Tax'}>Out of Scope of Tax</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                                <div style={{ width: "50%" }}>
                                    <div style={{ height: "65px", dth: "300px" }}>
                                        <label>Payment Date</label>
                                        <input type="date" style={{ fontSize: "13px" }} value={obj?.payment_date} onChange={(e) => setObj({ ...obj, payment_date: e.target.value })} placeholder='Payment Date' className='form-control' />
                                    </div>
                                    <div style={{ height: "65px" }}>
                                        <label>Payment Account</label>
                                        <select
                                            className='form-select'
                                            onChange={(e) => {
                                                setObj({ ...obj, account_id: e.target.value })
                                            }}
                                            value={obj?.account_id}

                                        >
                                            <option hidden>Select</option>
                                            {accountsDataC?.data?.map((items) => (
                                                <option key={items.id} value={items.id}>
                                                    {items.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                   {settingDat?.data?.track_location === 1 &&  <div style={{ height: "65px" }}>
                                        <label>Locations</label>
                                        <select
                                            className='form-select'
                                            onChange={(e) => {
                                                setObj({ ...obj, location_id: e.target.value })
                                            }}
                                            value={obj?.location_id}

                                        >
                                            <option hidden>Select</option>
                                            {locationData?.data?.map((items) => (
                                                <option key={items.id} value={items.id}>
                                                    {items.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="row my-2 mx-1 justify-content-center">
                            <h3 style={{ fontSize: "15px", fontWeight: "bold" }}>Category</h3>
                            <table className="table table-striped table-borderless">
                                <thead style={{ backgroundColor: '#84B0CA' }} className="text-white">
                                    <tr>
                                        <th scope="col" style={{ fontSize: "12px" }}>#</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Account</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Description</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Amount</th>
                                        {obj?.whichtax == 'Out of Scope of Tax' ? "" : <th scope="col" style={{ fontSize: "12px" }}>Tax</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Billable</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Customer</th>
                                      {settingDat?.data?.track_classes === 1 &&   <th scope="col" style={{ fontSize: "12px" }}>Class</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {obj?.expense_category?.map((item, index) => {
                                        const selectedAccount = servicesData?.services?.find((i) => i.id === Number(item.service_id));
                                        const subTotal = item.quantity * (selectedAccount?.sale_price || 0);
                                        const taxRate = salesPurchase?.purchase_tax?.find((i) => i.id === Number(item.tax_id))?.rate || 0;
                                        const TotalAmount = subTotal + (subTotal * (taxRate / 100));
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        className='form-select'
                                                        value={item.account_id}
                                                        onChange={(e) => handleChangeAccount(e.target.value, index, 'account_id')}
                                                    >
                                                        <option hidden>Select</option>
                                                        {accountsDataC?.data?.map((items) => (
                                                            <option key={items.id} value={items.id}>
                                                                {items.name}
                                                            </option>
                                                        ))}
                                                    </select>

                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <input
                                                        type='text'
                                                        placeholder='description'
                                                        className='form-control'
                                                        value={item.description}  // Make sure to set the value attribute to the corresponding field in obj?.expense_category
                                                        onChange={(e) => handleChangeAccount(e.target.value, index, 'description')}
                                                    />
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <input
                                                        type='number'
                                                        placeholder='amount'
                                                        className='form-control'
                                                        value={item.amount}  // Make sure to set the value attribute to the corresponding field in obj?.expense_category
                                                        onChange={(e) => handleChangeAccount(e.target.value, index, 'amount')}
                                                    />
                                                </td>

                                                {obj?.whichtax == 'Out of Scope of Tax' ? "" : <td style={{ fontSize: "13px" }}>
                                                    {taxType === 'Inclusive Tax' && (
                                                        <select
                                                            value={item.tax_id}
                                                            onChange={(e) =>
                                                                handleChangeAccount(e.target.value, index, 'tax_id')
                                                            }
                                                            className='form-select'
                                                        >
                                                            <option hidden>Select</option>
                                                            {salesPurchase?.purchase_tax?.map((i) => {
                                                                return (
                                                                    <option key={i.id} value={i.id}>{i.name} ({i.rate}%)</option>
                                                                )
                                                            })}
                                                        </select>
                                                    )}
                                                    {taxType === 'Exclusive Tax' && (
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            value={item.extax}
                                                            onChange={(e) =>
                                                                handleChangeAccount(e.target.value, index, 'extax')
                                                            }
                                                            placeholder="Exclusive Tax"
                                                        />
                                                    )}
                                                </td>}
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item.billable}  // Make sure to set the checked attribute to the corresponding field in obj?.expense_category
                                                        onChange={(e) => handleChangeAccount(e.target.checked, index, 'billable')}
                                                    />
                                                </td>

                                                <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        value={item.client_id}
                                                        onChange={(e) => handleChangeAccount(e.target.value, index, 'client_id')}
                                                        className='form-select'
                                                    >
                                                        <option hidden>Select</option>
                                                        {clientDataActive?.map((i) => (
                                                            <option key={i.id} value={i.id}>
                                                                {i.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                               {settingDat?.data?.track_classes === 1 &&  <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        className='form-select'
                                                        value={item.class_id}
                                                        onChange={(e) => handleChangeAccount(e.target.value, index, 'class_id')}

                                                    >
                                                        <option hidden>Select</option>
                                                        {classesData?.data?.map((items) => (
                                                            <option key={items.id} value={items.id}>
                                                                {items.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>}

                                                <td style={{ fontSize: "13px" }}>
                                                    <button className='p-1 m-2 btn btn-danger' onClick={() => handleDeleteRow(index)}><RxCross2 /></button>
                                                </td>
                                            </tr>
                                        );
                                    })}


                                </tbody>
                            </table>
                            <div className="text-end">
                                <button className="btn btn-primary" onClick={handleAddRow}>
                                    <FaPlus />
                                </button>
                            </div>
                        </div>
                        <div className="row my-2 mx-1 justify-content-center">
                            <table className="table table-striped table-borderless">
                                <thead style={{ backgroundColor: '#84B0CA' }} className="text-white">
                                    <tr>
                                        <th scope="col" style={{ fontSize: "12px" }}>#</th>
                                        {settingDat?.data?.service_date === 1 && <th scope="col" style={{ fontSize: "12px" }}>Service Date</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Service Name</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Description</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Billable</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Customer</th>
                                       {settingDat?.data?.track_classes === 1 &&  <th scope="col" style={{ fontSize: "12px" }}>Class</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Quantity</th>
                                        {obj?.whichtax == 'Out of Scope of Tax' ? "" : <th scope="col" style={{ fontSize: "12px" }}>Tax</th>}
                                        <th scope="col" style={{ fontSize: "12px" }}>Sales Price</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Total</th>
                                        <th scope="col" style={{ fontSize: "12px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {obj?.supplier_service?.map((item, index) => {
                                        const selectedService = servicesData?.services?.find((i) => i.id === Number(item.service_id));
                                        const subTotal = item.quantity * (selectedService?.sale_price || 0);
                                        const taxRate = salesPurchase?.sale_tax?.find((i) => i.id === Number(item.tax_id))?.rate || 0;
                                        const TotalAmount = subTotal + (subTotal * (taxRate / 100));
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                {settingDat?.data?.service_date === 1 && <td style={{ fontSize: "13px" }}>
                                                    <input className='form-control'
                                                        value={item.service_date}
                                                        onChange={(e) =>
                                                            handleChangeService(e.target.value, index, 'service_date')
                                                        }
                                                        type='date' />
                                                </td>}
                                                <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        value={item.service_id}
                                                        onChange={(e) =>
                                                            handleChangeService(e.target.value, index, 'service_id')
                                                        }
                                                        className='form-select'
                                                    >
                                                        <option hidden>Select</option>
                                                        {servicesData?.services?.map((items) => {
                                                            return (
                                                                <option key={items.id} value={items.id}>{items.name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <p className='bbClass' style={{ fontSize: "15px", background: "white", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{selectedService?.description ? selectedService?.description : '-'}</p>
                                                </td>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={item.billable}
                                                        onChange={(e) => handleChangeService(e.target.checked, index, 'billable')}
                                                    />
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        value={item.client_id}
                                                        onChange={(e) => handleChangeService(e.target.value, index, 'client_id')}
                                                        className='form-select'
                                                    >
                                                        <option hidden>Select</option>
                                                        {clientDataActive?.map((i) => (
                                                            <option key={i.id} value={i.id}>
                                                                {i.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                               {settingDat?.data?.track_classes === 1 &&  <td style={{ fontSize: "13px" }}>
                                                    <select
                                                        className='form-select'
                                                        value={item.class_id}
                                                        onChange={(e) => handleChangeService(e.target.value, index, 'class_id')}

                                                    >
                                                        <option hidden>Select</option>
                                                        {classesData?.data?.map((items) => (
                                                            <option key={items.id} value={items.id}>
                                                                {items.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>}
                                                <td style={{ fontSize: "13px" }}>
                                                    <input
                                                        type="number"
                                                        className="form-control w-75"
                                                        placeholder="Qty"
                                                        value={item.quantity}
                                                        style={{ fontSize: "13px" }}
                                                        onChange={(e) =>
                                                            handleChangeService(e.target.value, index, 'quantity')
                                                        }
                                                    />
                                                </td>
                                                {obj?.whichtax == 'Out of Scope of Tax' ? "" : <td style={{ fontSize: "13px" }}>
                                                    {taxType === 'Inclusive Tax' && (
                                                        <select
                                                            value={item.tax_id}
                                                            disabled={obj?.supplier_service?.length && obj?.supplier_service[index].quantity ? false : true}

                                                            onChange={(e) =>
                                                                handleChangeService(e.target.value, index, 'tax_id')
                                                            }
                                                            className='form-select'
                                                        >
                                                            <option hidden>Select</option>
                                                            {salesPurchase?.purchase_tax?.map((i) => {
                                                                return (
                                                                    <option key={i.id} value={i.id}>{i.name} ({i.rate}%)</option>
                                                                )
                                                            })}
                                                        </select>
                                                    )}
                                                    {taxType === 'Exclusive Tax' && (
                                                        <input
                                                            type="number"
                                                            className='form-control'
                                                            value={item.extax}
                                                            disabled={obj?.supplier_service?.length && obj?.supplier_service[index].quantity ? false : true}
                                                            onChange={(e) =>
                                                                handleChangeService(e.target.value, index, 'extax')
                                                            }
                                                            placeholder="Exclusive Tax"
                                                        />
                                                    )}
                                                </td>}
                                                <td style={{ fontSize: "13px" }}>
                                                    <p className='bbClass' style={{ fontSize: "13px", background: "white", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}>{selectedService?.sale_price ? selectedService?.sale_price : '-'}</p>
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <p className='bbClass' style={{ fontSize: "13px", background: "white", padding: "0.375rem 2.25rem 0.375rem 0.75rem" }}> {Math.round(item.total_price)}</p>
                                                </td>
                                                <td style={{ fontSize: "13px" }}>
                                                    <button className='p-1 m-2 btn btn-danger' onClick={() => handleDeleteServicesRow(index)}><RxCross2 /></button>
                                                </td>
                                            </tr>
                                        );
                                    })}


                                </tbody>
                            </table>
                            <div className="text-end">
                                <button className="btn btn-primary" onClick={handleAddRowService}>
                                    <FaPlus />
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12" style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <p className="text-black float-start"><span className="text-black me-3" style={{ fontSize: "16px" }}> Total Category</span><span style={{ fontSize: '16px' }}>{Math.round(obj.total_examount)}</span></p>
                                <p className="text-black float-start"><span className="text-black me-3" style={{ fontSize: "16px" }}> Total Expense</span><span style={{ fontSize: '16px' }}>{Math.round(obj.total_serviceamount)}</span></p>
                                <p className="text-black float-start"><span className="text-black me-3" style={{ fontSize: "20px" }}> Total Amount</span><span style={{ fontSize: '20px' }}>{Math.round(obj.total_amount)}</span></p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Memo:</h4>
                                <textarea placeholder='Message' className='form-control' onChange={(e) => setObj({ ...obj, message: e.target.value })}></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <h4 style={{ fontWeight: "bold", fontSize: "14px" }}>Attachment:</h4>
                                <input className='form-control' type='file' onChange={handleFileChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SupplierCheque
